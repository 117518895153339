[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.jHXqJxCR {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}
.jHXqJxCR .vLv5oq0_ {
  margin-bottom: 20px;
}
.jHXqJxCR span {
  color: var(--text-color);
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.mwofpswM {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.HkUaSfH8 {
  animation: KYZHAhiZ 1.4s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
}
.UU5etnfK {
  transform: scale(0.6);
  transform-origin: 50% 50%;
  animation: pGbP_Cf6 1.4s ease-in-out infinite;
  /* 圆内填充 */
  fill: none;
  /* 圆周颜色 */
  stroke: var(--loading-circle-color);
  /* 圆周宽度 */
  stroke-width: 2;
}
@keyframes pGbP_Cf6 {
  0% {
    stroke-dasharray: 1px 200px;
    stroke-dashoffset: 0;
  }
  30% {
    stroke-dasharray: 100px 200px;
    stroke-dashoffset: -15px;
    stroke: var(--main-color);
  }
  100% {
    stroke-dasharray: 100px 200px;
    stroke-dashoffset: -120px;
  }
}
@keyframes KYZHAhiZ {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.AIKbHYR_ {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.nIxmmeXc {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.qPfDV_a3 {
  text-align: center;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.__4um_eaQG {
  margin-bottom: 12px;
  width: 32px;
}
.__6G_hyIwo {
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 30px;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.ULpzdvYR {
  padding: 20px 17px 20px 21px;
  height: var(--height);
  background: var(--module-bg);
  min-width: 350px;
}
.__4yoRCyRd {
  margin-top: 16px;
  background: var(--module-bg);
  border-radius: 2px;
  padding: 21px 24px 24px;
  margin-bottom: 16px;
}
.cGLowLnf {
  position: relative;
}
.cGLowLnf .__7Z48J18G {
  font-size: 16px;
  font-family: var(--CommonMedium);
  color: var(--white);
  line-height: 24px;
  margin-bottom: 10px;
}
.cGLowLnf .vyqVnc6y {
  display: flex;
  justify-content: center;
}
.cGLowLnf .UDM91at8 {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.cGLowLnf .UDM91at8 .JGa9NdS9 {
  overflow: hidden;
  width: 110px;
  height: 44px;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.cGLowLnf .UDM91at8 .JGa9NdS9:not(:nth-child(3n)) {
  margin-right: 10px;
}
.cGLowLnf .UDM91at8 .JGa9NdS9 .aCmmizAU {
  width: 2px;
  height: 33px;
  margin-right: 11px;
}
.cGLowLnf .UDM91at8 .JGa9NdS9 .K_TxoaeR {
  color: var(--text-color);
}
.cGLowLnf .UDM91at8 .JGa9NdS9 .ECEG_tu0 {
  color: var(--white);
}
.cGLowLnf .LHcI75RN {
  margin-top: 10px;
  display: flex;
}
.cGLowLnf .LHcI75RN .Xbo_artl {
  overflow: hidden;
  width: 170px;
  height: 45px;
  background: var(--main-bg-color);
  margin-bottom: 10px;
  font-family: var(--CommonMedium);
  font-size: 12px;
  text-align: center;
}
.cGLowLnf .LHcI75RN .Xbo_artl:first-child {
  margin-right: 12px;
}
.cGLowLnf .LHcI75RN .Xbo_artl .jQb_C0H8 {
  color: var(--text-color);
  margin-right: 10px;
}
.cGLowLnf .__7HUnJbcW {
  margin-top: 10px;
  text-align: center;
}
.cGLowLnf .__7HUnJbcW span {
  color: var(--main-color);
  cursor: pointer;
}
.cGLowLnf .__7HUnJbcW span:hover {
  color: var(--main-text-hover-color);
}
._EKDQ698 {
  height: 330px;
}
._EKDQ698 .ant-spin-nested-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
.__8oaa3jqv {
  width: 100% !important;
}
.__8oaa3jqv .mQ5_6rTy {
  margin-top: 19px;
  text-align: center;
  margin-bottom: 12px;
  font-size: 16px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
}
.__8oaa3jqv .h4efYz5U {
  margin: auto auto 12px;
  text-align: center;
}
.__8oaa3jqv .h4efYz5U .__2CJJdFaa:hover {
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
.__8oaa3jqv .h4efYz5U .__2CJJdFaa:focus {
  color: var(--text-color);
  border: 1px solid var(--main-color);
  background-color: transparent;
}
.__8oaa3jqv .__6_27aFE_ {
  text-align: center;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 20px;
  margin-bottom: 12px;
}
.__7Z48J18G {
  font-size: 16px;
  font-family: var(--CommonBold);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
}
@media (max-width: 768px) {
  .cGLowLnf,
  .vJJGXADG ._EKDQ698 {
    width: 10vw;
  }
  .fJYzap01 {
    max-width: 50%;
    width: 100%;
    min-width: 250px;
  }
}
@media (min-width: 1441px) {
  .fJYzap01 {
    max-width: 70%;
    width: 100%;
    min-width: 250px;
  }
}
.jtl9oRh9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
/*vip card */
.t7L00UXC {
  width: 390px;
  height: 124px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: var(--module-bg);
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}
.t7L00UXC .ant-spin-nested-loading .ant-spin-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.t7L00UXC .Bspp0oTE {
  font-size: 22px;
  font-family: var(--CommonBold);
}
.t7L00UXC .HDOk8_cx {
  font-size: 12px;
  font-family: var(--CommonMedium);
  color: var(--remark-text-color);
}
.t7L00UXC .__8WiXXu8c {
  font-size: 12px;
  font-family: var(--CommonMedium);
  color: var(--text-color);
  margin-left: 6px;
  margin-bottom: 6px;
  flex-shrink: 0;
}
.t7L00UXC .aBLQf2n9 {
  color: var(--main-color);
  font-size: 12px;
  position: absolute;
  right: 10%;
  bottom: 10%;
}
.t7L00UXC .EMRzcIyy {
  width: 224px;
  background: var(--btn-grey1-bg);
  border-radius: 7.5px;
  height: 7.5px;
  overflow: hidden;
  margin-top: 14px;
  margin-bottom: 18px;
  margin-left: 12px;
}
.t7L00UXC .EMRzcIyy .SMiNpgh9 {
  height: 100%;
  width: 100%;
  transform: translateX(-100%);
  background: var(--vip-progress);
  animation-timing-function: ease-in-out;
  transition: ease 0.3s;
  border-radius: 7.5px;
}
.t7L00UXC .QeEVsPfE {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.t7L00UXC .v7_OSj8j {
  display: flex;
  flex-direction: column;
}
.t7L00UXC .R29OmWP7 {
  height: 100%;
  flex-direction: column;
  align-items: stretch;
  display: flex;
  padding: 12px 20px;
}
.t7L00UXC .bnA6nsbi {
  position: absolute;
  top: 65%;
  right: -6px;
  background: var(--module-bg);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.t7L00UXC .hZ8fOQJ_ {
  font-size: 22px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 150px;
  line-height: 33px;
  white-space: nowrap;
}
.t7L00UXC .XbQEIitS {
  font-size: 12px;
  color: var(--remark-text-color);
  margin-left: 7px;
}
/*markets Card*/
.qkAfIykM {
  width: calc(100% - 390px - 16px);
  height: 124px;
  background: var(--module-bg);
  padding: 10px 16px 10px 16px;
  border-radius: 2px;
  display: grid;
  grid-template-columns: repeat(5, 20%);
  justify-content: space-between;
  align-items: center;
}
.qkAfIykM .__2dTdF5Gh {
  border-right: 1px solid var(--border-color);
  border-radius: 2px;
  padding-left: 16px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  box-sizing: border-box;
}
.qkAfIykM .__2dTdF5Gh:first-child {
  padding-left: 0;
}
.qkAfIykM .__2dTdF5Gh:last-child {
  padding-right: 0;
  border-right: none;
}
.qkAfIykM .__2dTdF5Gh .qyn3Haae {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4px;
}
.qkAfIykM .__2dTdF5Gh .qyn3Haae .X5RAxXGa {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  margin-right: 10px;
}
.qkAfIykM .__2dTdF5Gh .ECEG_tu0 {
  font-size: 18px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 30px;
  margin-bottom: 8px;
}
.qkAfIykM .__2dTdF5Gh .__96khAJau {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
}
.qkAfIykM .__2dTdF5Gh .NwoFJyE_ {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--red-text-color);
  line-height: 22px;
}
.qkAfIykM .__2dTdF5Gh .c_DXQMsL {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--green-text-color);
  line-height: 22px;
}
.PoLKXhY6 {
  height: 48px;
  background: var(--module-bg);
  border-radius: 2px;
  margin-bottom: 16px;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  padding: 0 10px;
  width: 100%;
  display: flex;
  align-items: center;
}
.PoLKXhY6 .XGkSZ3xw {
  width: 16px;
  height: 21px;
}
.PoLKXhY6 .XpZgoV8y {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
.PoLKXhY6 .GW2iWyP7 {
  display: grid;
  grid-template-columns: 40px 90px 440px 130px;
  grid-gap: 20px;
  cursor: pointer;
  align-items: center;
}
.PoLKXhY6 .__4v_n4zfE {
  display: grid;
  grid-template-columns: 130px 440px 130px;
  grid-gap: 20px;
  cursor: pointer;
  align-items: center;
}
.PoLKXhY6 .__7Z48J18G,
.PoLKXhY6 .v_5tDGO6,
.PoLKXhY6 .Mr2fpInm {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.PoLKXhY6 .mB71a8WI {
  position: relative;
  width: 40px;
  height: 20px;
  line-height: 20px;
  font-size: 10px;
  text-align: center;
  -webkit-transform: scale(0.8);
  color: var(--white);
  display: inline-block;
  background: var(--main-color);
  border-radius: 9px;
}
.PoLKXhY6 .x1,
.PoLKXhY6 .x4,
.PoLKXhY6 .x5,
.PoLKXhY6 .x6 {
  width: 100%;
}
.dS_eqe4_ {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  width: 100%;
}
.dS_eqe4_ .__09p2IIkA {
  color: var(--white-op4) !important;
}
.dS_eqe4_ .YPLiIVFb {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 24%;
  height: 64px;
  background: var(--module-bg);
  border-radius: 2px;
  line-height: 64px;
  padding: 0 23px;
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
}
.dS_eqe4_ .UClZyS7U {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}
.QKezPF1N {
  font-size: 16px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
  margin-bottom: 13px;
}
.BHX4_hOv {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  padding-left: 10px;
}
.Ie1xTc9O {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  text-align: right;
  cursor: pointer;
}
.Ie1xTc9O:hover {
  color: var(--white);
}
.IliLdyiN {
  position: relative;
  min-height: 558px;
}
.IliLdyiN .c0PaANSP {
  min-height: 454px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.IliLdyiN .c0PaANSP .BCnIN9QO:nth-child(3n) {
  margin-right: 0px !important;
}
.IliLdyiN .c0PaANSP .BCnIN9QO {
  width: 32%;
  margin-right: 22px;
}
.TNRx4wrb {
  position: relative;
  min-height: 558px;
}
.TNRx4wrb .eQTkaLO6 {
  min-height: 454px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.TNRx4wrb .eQTkaLO6 .ze1yaT1V:nth-child(3n) {
  margin-right: 0px !important;
}
.TNRx4wrb .eQTkaLO6 .ze1yaT1V {
  width: 32%;
  margin-right: 22px;
}
.awt4pGDD {
  position: relative;
}
.eSjB6frV {
  display: flex;
  justify-content: space-between;
}
.eSjB6frV .Imt_MWpr {
  padding: 20px;
  border-radius: 4px;
  background: var(--main-bg-color);
  width: 100%;
  margin-right: 17px;
}
.eSjB6frV .Imt_MWpr:last-child {
  margin-right: 0;
}
.eSjB6frV .Imt_MWpr:hover {
  background: var(--card-hover-bg);
  box-shadow: 3px 3px 8px var(--box-shadow);
}
.eSjB6frV .Imt_MWpr .Ntd8DjTk {
  display: inline-block;
  font-size: 16px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 20px;
}
.eSjB6frV .Imt_MWpr .XpZgoV8y {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}
.eSjB6frV .Imt_MWpr .yHA1oROK {
  height: 18px;
  background: var(--btn-grey-bg);
  border-radius: 9px;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  padding: 1px 8px;
  margin-right: 5px;
}
.eSjB6frV .Imt_MWpr .__9PgeM3FA {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
}
.eSjB6frV .Imt_MWpr .hypstXoR {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
}
.eSjB6frV .Imt_MWpr .z_ztGuHb {
  width: 120px;
  height: 30px;
  background: var(--main-color);
  border-radius: 18px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  margin-top: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.__6GCpg0Rq {
  padding-left: 100px;
  padding-top: 36px;
  height: 230px;
  margin: 16px 0;
  background: url("/images/autoInvestment_home.webp") no-repeat;
  background-size: 100%;
}
.__6GCpg0Rq .__7Z48J18G {
  font-family: var(--CommonBold);
  color: var(--white);
  font-size: 32px;
}
.__6GCpg0Rq ._MmLSC_C {
  margin: 10px 0 39px;
  width: 395px;
  font-family: var(--CommonRegular);
  color: var(--white) 70%;
}
.__6GCpg0Rq .JHOw_hvM {
  width: 140px;
  height: 40px;
  color: var(--white);
  border-radius: 20px;
  font-family: var(--CommonMedium);
}
.ETjPh20j {
  margin: 16px 0;
  padding: 32px 100px;
  height: 230px;
  background: url("/images/advertisement.webp") no-repeat;
  background-size: 100%;
  border-radius: 2px;
}
.ETjPh20j .CnTOD45E {
  font-family: var(--CommonBold);
  font-size: 32px;
  color: var(--white);
  line-height: 41px;
}
.ETjPh20j .__0n9znWTc {
  font-family: var(--CommonRegular);
  font-size: 14px;
  color: var(--remark-text-color);
  line-height: 22px;
}
.ETjPh20j .dQ3NXFle {
  margin-top: 18px;
  width: 97px;
  height: 40px;
  border-radius: 20px;
  font-family: var(--CommonMedium);
}
.KQ4CrUUf {
  background: var(--main-bg-color);
  border-radius: 4px;
}
.KQ4CrUUf .__7Z48J18G {
  font-family: var(--CommonBold);
  font-size: 18px;
  color: var(--text-color);
}
.KQ4CrUUf .__2hLUyzjp {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
}
.KQ4CrUUf .__2hLUyzjp button {
  border-radius: 20px;
  padding: 0 29px;
}
.KQ4CrUUf .IFO9BGQr {
  flex: 1 1;
  margin-top: 4px;
  font-size: 12px;
  color: var(--remark-text-color);
}
.KQ4CrUUf .e9EcCidy {
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: var(--main-color);
  cursor: pointer;
}
.KQ4CrUUf .e9EcCidy:hover {
  color: var(--main-text-hover-color);
}
.KQ4CrUUf .w8DAjetF {
  flex: 1 1;
  padding: 30px;
  border-top: 1px solid var(--border-color1);
  border-left: 1px solid var(--border-color1);
}
.KQ4CrUUf .w8DAjetF:first-child {
  border-left: 0px solid var(--border-color1);
}
.KQ4CrUUf .lS9bOQ9E {
  font-family: var(--CommonBold);
  font-size: 16px;
  color: var(--text-color);
}
.KQ4CrUUf .iAitT96z {
  margin-top: 8px;
  margin-bottom: 30px;
  font-size: 12px;
  color: var(--remark-text-color);
}
.KQ4CrUUf .fya2I5RP {
  width: 112px;
  height: 28px;
  padding: 0;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
.KQ4CrUUf .fya2I5RP:hover {
  color: var(--white);
  background: var(--main-color);
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
._CzAanQy {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: var(--CommonMedium);
}
._CzAanQy ._zYvSwkz {
  display: flex;
  margin-bottom: 5px;
}
._CzAanQy ._zYvSwkz .d0lzGtlP {
  width: 100px;
  display: flex;
  align-items: center;
}
._CzAanQy ._zYvSwkz .d0lzGtlP .__8O83z8PJ {
  margin-right: 8px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid;
}
._CzAanQy ._zYvSwkz .d0lzGtlP span {
  color: var(--text-color);
  font-size: 14px;
}
._CzAanQy ._zYvSwkz .xJbJ9FBI {
  width: 120px;
}
._CzAanQy ._zYvSwkz ._q80Rub2 {
  text-align: right;
  color: var(--white);
  flex: 1 1;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.nkSKvoHw {
  max-width: 1200px;
  margin: auto;
}
.nkSKvoHw .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.nkSKvoHw .ant-btn-primary {
  background: var(--main-color);
  color: var(--white);
  margin-right: 30px;
}
.nkSKvoHw .ant-btn:hover {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.nkSKvoHw .ant-table-tbody > tr > td,
.nkSKvoHw .ant-table tfoot > tr > th,
.nkSKvoHw .ant-table tfoot > tr > td {
  padding: 0;
}
.nkSKvoHw .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent;
  pointer-events: none;
}
.nkSKvoHw .ant-table-cell-fix-left,
.nkSKvoHw .ant-table-cell-fix-right {
  background-color: #303343;
}
.nkSKvoHw .ant-table-container table > thead > tr:first-child th:first-child {
  padding: 0 !important;
}
.nkSKvoHw .ant-table-thead > tr > th,
.nkSKvoHw .ant-table-sticky-holder {
  background-color: var(--table-heading-bg);
}
.nkSKvoHw .weLvexty {
  font-size: 18px;
}
.nkSKvoHw .BaZn5tVC {
  padding: 0 24px 24px;
  background: var(--tab-active-bg);
  min-height: 600px;
}
.nkSKvoHw .BaZn5tVC .__9eFufmxN {
  width: 95px;
  height: 28px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  padding-top: 0;
  padding-bottom: 0;
}
.nkSKvoHw .BaZn5tVC .mbFzA_i8 {
  margin-right: 8px;
}
.nkSKvoHw .OHJJ5_ZU {
  height: 64px;
  background-color: var(--module-bg);
  color: var(--white);
}
.nkSKvoHw .OHJJ5_ZU .ant-tabs-tab {
  margin: 0;
  width: 200px;
}
.nkSKvoHw .OHJJ5_ZU .ant-tabs-tab-btn {
  padding-top: 0;
  width: 100%;
  height: 42px;
  font-size: 18px;
  font-family: var(--CommonRegular);
  text-align: center;
  color: var(--text-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
.nkSKvoHw .OHJJ5_ZU .ant-tabs-tab-btn:hover {
  color: var(--white);
}
.nkSKvoHw .OHJJ5_ZU .ant-tabs-tab-active {
  background-color: var(--tab-active-bg);
}
.nkSKvoHw .OHJJ5_ZU .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--white);
}
.EomWHA08 {
  max-width: 110px;
  height: 60px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to top right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) calc(50% - 0.4px), rgba(255, 255, 255, 0.2) 50%, rgba(0, 0, 0, 0) calc(50% + 0.4px), rgba(0, 0, 0, 0) 100%);
}
.EomWHA08 span {
  display: inline-block;
  height: 30px;
  line-height: 30px;
}
.EomWHA08 .__5nU6Nc8S {
  text-align: right;
  padding-right: 8px;
}
.EomWHA08 .__9lweqeUD {
  padding-left: 8px;
}
.v1dHKO1M {
  color: var(--white-op2);
}
.a1Hx4cJW .ant-input-suffix:after {
  content: "";
  width: 1px;
  height: 14px;
  margin-left: 12px;
  background: var(--white);
  opacity: 0.1;
}
.a1Hx4cJW .ant-modal-content .ant-input-affix-wrapper {
  padding-right: 0;
}
.a1Hx4cJW .ant-input-group-addon {
  padding-left: 0;
  padding-right: 0;
}
.a1Hx4cJW .ant-modal-title {
  font-size: 16px;
  color: var(--white);
}
.a1Hx4cJW .qZdY4imz {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  margin-right: 10px;
}
.a1Hx4cJW .LhRw98hk {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
}
.a1Hx4cJW .kExov3F6 {
  display: flex;
  justify-content: space-between;
  flex: 0 1 auto;
  margin-bottom: 26px;
  margin-top: 10px;
}
.a1Hx4cJW .B_vqsstc {
  height: 24px;
  border-radius: 4px;
  border: 1px solid var(--border-color1);
  margin-right: 8px;
  padding: 0 10px;
  flex-grow: 1;
}
.a1Hx4cJW .d5Cmdgiy {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.a1Hx4cJW .vz7vM_PO {
  font-size: 18px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 21px;
  margin-top: 18px;
  margin-bottom: 86px;
}
.ro46ZJE7 {
  padding-top: 24px;
}
.ro46ZJE7 .__5izzLykw {
  width: 95px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
.ro46ZJE7 .__5izzLykw:focus {
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
.ro46ZJE7 .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.ro46ZJE7 .ant-btn-primary {
  background: var(--main-color);
  color: var(--white);
  margin-right: 30px;
}
.ro46ZJE7 .ant-btn:hover,
.ro46ZJE7 .ant-btn:active {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.ro46ZJE7 .ant-switch {
  background-color: var(--btn-grey1-bg);
  background-image: none;
}
.ro46ZJE7 .ant-switch-checked {
  background-color: var(--main-color);
}
.ro46ZJE7 .ant-switch-handle::before {
  background-color: var(--tab-active-bg);
}
.ro46ZJE7 .mbFzA_i8 {
  margin-right: 16px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.ro46ZJE7 .BzVIPtpC {
  display: flex;
  align-items: center;
}
.ro46ZJE7 .z9uTpt6u {
  padding: 3px 16px;
  height: 28px;
  text-align: center;
  display: inline-block;
  background: var(--btn-grey-bg);
  border-radius: 14px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
}
.ant-popover .ant-popover-inner {
  background-color: var(--table-border-color);
  padding-top: 5px;
}
.ant-popover-content {
  width: 300px;
  background-color: var(--table-border-color) !important;
}
.ant-table-cell-fix-left, .ant-table-cell-fix-right {
  padding: 16px !important;
}
.ant-spin-nested-loading .ant-spin-container .ant-table-content {
  min-height: 400px;
}
.K8MFgs0K {
  width: 268px;
}
.K8MFgs0K p {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.K8MFgs0K .Uxx22GQ1 {
  font-size: 14px;
  font-weight: bold;
  color: var(--white);
}
.J1_pY9v0 {
  padding: 25px 0px 32px;
  min-height: 600px;
  background: var(--tab-active-bg);
}
.J1_pY9v0 .C_Z8f2_H {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.J1_pY9v0 .C_Z8f2_H:hover {
  color: var(--main-text-hover-color);
}
.J1_pY9v0 .Noetx6Dr .ant-radio-group {
  background: var(--table-heading-bg);
  border-radius: 15px;
}
.J1_pY9v0 .Noetx6Dr .ant-radio-group .ant-radio-button-wrapper {
  width: 142px;
  text-align: center;
  border: none;
  transition: unset;
}
.J1_pY9v0 .Noetx6Dr .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.J1_pY9v0 .Noetx6Dr .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--main-color);
  border-radius: 15px;
  color: var(--white);
}
.J1_pY9v0 .Noetx6Dr .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--white);
}
.J1_pY9v0 .Noetx6Dr .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: unset;
}
.J1_pY9v0 .Noetx6Dr .pFT5sVUy {
  padding: 0px 39px;
  margin-bottom: 24px;
}
.J1_pY9v0 .Noetx6Dr .pFT5sVUy span {
  display: inline-block;
  color: var(--grey-text-color);
  font-size: 14px;
  margin-right: 80px;
  cursor: pointer;
  padding-bottom: 10px;
  box-sizing: border-box;
}
.J1_pY9v0 .Noetx6Dr .pFT5sVUy span:hover {
  color: var(--main-color);
}
.J1_pY9v0 .Noetx6Dr .pFT5sVUy .n1viY_y2 {
  color: var(--main-color);
  border-bottom: 2px solid var(--main-color);
}
.pzqdUQu5 {
  font-family: var(--CommonMedium);
  color: var(--white);
  min-height: 530px;
}
.pzqdUQu5 .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.pzqdUQu5 .ant-btn-primary {
  color: var(--white);
  margin-right: 30px;
}
.pzqdUQu5 .ant-btn:hover,
.pzqdUQu5 .ant-btn:active {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.pzqdUQu5 .ant-table-container .ant-table-content {
  min-height: 235px !important;
}
.pzqdUQu5 .GDyg0Fi8 {
  margin-top: 19px;
  min-height: 530px;
}
.pzqdUQu5 .GDyg0Fi8 .sLCvOVOx {
  position: absolute;
  top: -5px;
  left: -5px;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u {
  padding: 41px 90px;
  background-color: var(--module-bg);
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .xxPs0iDM {
  font-size: 22px;
  color: var(--white);
  margin-bottom: 36px;
  display: flex;
  align-items: center;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .xxPs0iDM .mbFzA_i8 {
  margin-right: 16px;
  width: 40px;
  height: 40px;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX {
  display: flex;
  flex-direction: column;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .S1wrplKa {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .S1wrplKa .g3XCGvcY {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .OFA7OVa2 {
  display: flex;
  justify-content: space-between;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .zP0weF4X {
  font-size: 18px;
  color: var(--white);
  margin-bottom: 20px;
  font-weight: bold;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .t8iECnPM {
  font-size: 16px;
  color: var(--white);
  margin-bottom: 20px;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .qWHvtlaf {
  font-size: 14px;
  color: var(--white-op1);
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .__4khQJd7A {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .__4khQJd7A .tedk_rVk {
  color: var(--text-color);
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .__4khQJd7A .oem7Y2C2 {
  color: var(--white);
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .JksQ8K1j {
  width: 42%;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .JksQ8K1j .CUZMZITN {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 35px;
  border: 1px solid var(--list-border-color1);
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .JksQ8K1j .CUZMZITN .iCpzwTo3 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  border-right: 1px solid var(--list-border-color1);
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .JksQ8K1j .CUZMZITN .iCpzwTo3 span {
  display: block;
  width: 100%;
  padding: 12px 0;
  box-sizing: border-box;
  text-align: center;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .JksQ8K1j .CUZMZITN .iCpzwTo3 .H4TvzKFC {
  background-color: var(--main-bg-color);
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .JksQ8K1j .__4EeSMko3 {
  font-size: 16px;
  color: var(--white);
  margin-bottom: 8px;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .JksQ8K1j .__2Rz06jnh {
  font-size: 24px;
  color: var(--white);
  font-family: var(--CommonBold);
  margin-bottom: 24px;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .JksQ8K1j .__8CgFn59i {
  display: flex;
  position: relative;
  background-color: var(--main-bg-color);
  padding-right: 19px;
  border-radius: 2px;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .JksQ8K1j .__8CgFn59i .wXFQWufV {
  width: 100%;
  background-color: var(--main-bg-color);
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .JksQ8K1j .__8CgFn59i .wXFQWufV input::-webkit-outer-spin-button,
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .JksQ8K1j .__8CgFn59i .wXFQWufV input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .JksQ8K1j .__8CgFn59i .QGa5E1O6 {
  position: absolute;
  right: 20px;
  height: 40px;
  line-height: 40px;
  color: var(--main-color);
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .JksQ8K1j .xze8Z2_O {
  color: var(--tip-text-color);
  margin-bottom: 14px;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .JksQ8K1j .ysFGsKU2 .bLvKLqmg {
  margin: 0 9px 0 16px;
  color: var(--white);
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .JksQ8K1j .lsuqT12h {
  margin-top: 40px;
  display: flex;
}
.pzqdUQu5 .GDyg0Fi8 .pr3Err4u .AbgP0HwX .__9EVyxmnF {
  width: 42%;
}
.hn9VVEcU {
  display: flex;
  flex-direction: column;
  min-height: 600px;
  background: var(--tab-active-bg);
}
.hn9VVEcU .ant-table-content {
  min-height: 0 !important;
}
.hn9VVEcU .ant-table-content .ant-table-thead > tr > th {
  text-align: center;
}
.hn9VVEcU .ant-table-content .ant-table-tbody > .ant-table-row > td {
  padding: 15px 0 !important;
  text-align: center;
}
.hn9VVEcU .ant-spin-nested-loading {
  flex: 1 1;
}
.hn9VVEcU ._ABTTpyE {
  margin-bottom: 30px;
}
.hn9VVEcU .cC1_f_MO {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 21px 0;
}
.hn9VVEcU .__7KA2JACs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 22px;
  background: var(--table-heading-bg);
  box-shadow: 0 1px 0 var(--table-heading-bg);
}
.hn9VVEcU .__7KA2JACs .OI8S2A7t {
  color: var(--text-color-second);
}
.hn9VVEcU .hDbK8Q_Z {
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
}
.hn9VVEcU .hDbK8Q_Z .Jh7QvhlP {
  font-family: var(--CommonBold);
  font-size: 18px;
  line-height: 24px;
  color: var(--white);
  margin: 24px 0 20px 0;
}
.hn9VVEcU .hDbK8Q_Z .A3dCzSQH {
  font-size: 12px;
  line-height: 22px;
  color: var(--white);
  margin-top: 24px;
}
.hn9VVEcU .hDbK8Q_Z .HFpIEhkw {
  font-family: var(--CommonMedium);
  font-size: 16px;
  line-height: 14px;
  color: var(--white);
}
.hn9VVEcU .hDbK8Q_Z .__1Mrst927 {
  font-size: 14px;
  line-height: 18px;
  color: var(--white-op2);
  margin-top: 9px;
}
.hn9VVEcU .hDbK8Q_Z .bOLvQZ5K {
  width: 100%;
  font-size: 14px;
  display: flex;
  overflow: hidden;
}
.hn9VVEcU .hDbK8Q_Z .bOLvQZ5K .d5Cmdgiy {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.hn9VVEcU .hDbK8Q_Z .bOLvQZ5K .d5Cmdgiy .UNYIurNn {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.hn9VVEcU .hDbK8Q_Z .bOLvQZ5K .d5Cmdgiy .UNYIurNn .nus3xfxk {
  height: 2px;
  width: 100%;
}
.hn9VVEcU .hDbK8Q_Z .bOLvQZ5K .d5Cmdgiy .ngzi5sq2 {
  background: var(--btn-green-bg);
}
.hn9VVEcU .hDbK8Q_Z .bOLvQZ5K .d5Cmdgiy .__3hofhek1 {
  background: var(--btn-red-bg);
}
.hn9VVEcU .hDbK8Q_Z .bOLvQZ5K .d5Cmdgiy .nqVagzHJ {
  background: var(--blue-level3);
}
.hn9VVEcU .hDbK8Q_Z .bOLvQZ5K .d5Cmdgiy .__0C71s1z7 {
  background: var(--main-color);
}
.hn9VVEcU .hDbK8Q_Z .bOLvQZ5K .mhifYVH6 {
  width: 0;
  height: 16px;
}
.hn9VVEcU .hDbK8Q_Z .bOLvQZ5K .__087oUU_o {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.hn9VVEcU .hDbK8Q_Z .bOLvQZ5K .G0W284DT {
  border: 2px solid var(--timeline-color1);
  background-color: var(--timeline-color1-bg);
}
.hn9VVEcU .hDbK8Q_Z .bOLvQZ5K .JE7OYMPD {
  border: 2px solid var(--timeline-color2);
  background-color: var(--timeline-color2-bg);
}
.hn9VVEcU .hDbK8Q_Z .bOLvQZ5K .Bid9p3Za {
  border: 2px solid var(--timeline-color3);
  background-color: var(--timeline-color3-bg);
}
.hn9VVEcU .hDbK8Q_Z .bOLvQZ5K .nkPK1TG1 {
  border: 2px solid var(--timeline-color4);
  background-color: var(--timeline-color4-bg);
}
.hn9VVEcU .hDbK8Q_Z .CJNH9vGq {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.hn9VVEcU .hDbK8Q_Z .CJNH9vGq .AbgP0HwX {
  width: 90px;
  display: flex;
  flex-wrap: wrap;
}
.hn9VVEcU .hDbK8Q_Z .CJNH9vGq .AbgP0HwX span {
  font-size: 12px;
  line-height: 16px;
}
.hn9VVEcU .hDbK8Q_Z .AbgP0HwX {
  margin-top: 16px;
}
.hn9VVEcU .hDbK8Q_Z .__6aO24lJV {
  margin-Left: -70px;
  width: 140px;
  text-align: center;
}
.hn9VVEcU .hDbK8Q_Z .vF9_RFfY {
  position: absolute;
  right: 24px;
  text-align: right;
}
.hn9VVEcU .dGXjLtWx {
  padding: 24px 24px 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hn9VVEcU .dGXjLtWx .ant-radio-group {
  background: var(--table-heading-bg);
  border-radius: 15px;
}
.hn9VVEcU .dGXjLtWx .ant-radio-group .ant-radio-button-wrapper {
  width: 142px;
  height: 32px;
  text-align: center;
  border: none;
  transition: unset;
}
.hn9VVEcU .dGXjLtWx .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.hn9VVEcU .dGXjLtWx .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--main-color);
  border-radius: 15px;
  color: var(--white);
}
.hn9VVEcU .dGXjLtWx .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--white);
}
.hn9VVEcU .dGXjLtWx .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: unset;
}
.hn9VVEcU .dGXjLtWx .w5unzDgc {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.hn9VVEcU .dGXjLtWx .w5unzDgc .A47eCqzP {
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.hn9VVEcU .dGXjLtWx .w5unzDgc .oWTkx_J7 {
  font-family: var(--CommonMedium);
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--white-op1);
  margin-left: 8px;
}
.hn9VVEcU .dGXjLtWx .w5unzDgc .AF1cJ60h {
  font-family: var(--CommonBold);
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.5px;
  color: var(--white-op3);
}
.hn9VVEcU .dGXjLtWx .w5unzDgc .VvnQWSqq {
  display: flex;
  align-items: center;
}
.hn9VVEcU .dGXjLtWx .w5unzDgc .XtFZCNkR {
  margin: 0 6px 0 8px;
}
.hn9VVEcU .dGXjLtWx .w5unzDgc .__9T964pQ2 {
  font-family: var(--CommonBold);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
}
.hn9VVEcU .dGXjLtWx .w5unzDgc .__28pEM5mJ {
  color: var(--green-text-color) !important;
}
.hn9VVEcU .dGXjLtWx .w5unzDgc .rDpiEQjE {
  color: var(--red-text-color) !important;
}
.hn9VVEcU .dGXjLtWx .w5unzDgc .pgM_enG6 {
  color: var(--white) !important;
}
.__4uDBuHHF .r_klYIeA {
  margin: 32px 0 12px;
}
.__4uDBuHHF .wHgqLQwj {
  font-size: 14px;
  line-height: 22px;
  color: var(--white-op2);
}
.__4uDBuHHF .EOoe4j1E {
  margin: 13px 0 12px;
}
.__4uDBuHHF .rWjz5_dX {
  font-size: 14px;
  line-height: 16px;
  color: var(--white-op2);
}
.__4uDBuHHF .AUauTRzw {
  font-size: 14px;
  line-height: 16px;
  color: var(--main-color);
  margin: 10px 0 28px;
}
.__4uDBuHHF .DZrcWhoe {
  color: var(--main-color);
  padding: 8px 11px;
}
.__4uDBuHHF button {
  width: 100% !important;
  border-radius: 4px !important;
}
.HkPeJQxW {
  font-family: var(--CommonRegular);
  margin-top: 17px;
  min-height: 600px;
  background: var(--tab-active-bg);
  padding: 45px 79px;
}
.HkPeJQxW .tYgNdCjY {
  display: flex;
  justify-content: space-between;
  margin-top: 34px;
}
.HkPeJQxW .ant-table-container .ant-table-content {
  min-height: 0 !important;
}
.HkPeJQxW .ant-table table {
  border-collapse: collapse !important;
}
.HkPeJQxW .ant-input-group-addon {
  border: none;
  background-color: var(--input-white-bg1);
}
.HkPeJQxW .ant-table-thead > tr > th {
  color: var(--white) !important;
  font-family: var(--CommonRegular) !important;
  font-size: 16px;
}
.HkPeJQxW td,
.HkPeJQxW th {
  padding: 16px 10px !important;
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: 1px solid var(--list-border-color1);
}
.HkPeJQxW td:first-child,
.HkPeJQxW th:first-child {
  border-left: 1px solid var(--list-border-color1);
}
.HkPeJQxW .L9FTcmqr {
  margin-left: 110px;
  flex: 1 1 auto;
}
.HkPeJQxW .HFpIEhkw {
  font-family: var(--CommonMedium);
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
}
.HkPeJQxW .rWjz5_dX {
  font-size: 12px;
  line-height: 22px;
  color: var(--white-op4);
}
.HkPeJQxW .uj_r9I3Q {
  margin: 29px 0;
}
.HkPeJQxW .eMJBw238 {
  display: flex;
  align-items: center;
}
.HkPeJQxW .eMJBw238 .__4a9c93X4 {
  margin-left: 16px;
}
.HkPeJQxW .eMJBw238 .HFpIEhkw {
  font-family: var(--CommonMedium);
  font-size: 22px;
  line-height: 22px;
  color: var(--white);
}
.HkPeJQxW .eMJBw238 .rWjz5_dX {
  font-family: var(--CommonMedium);
  font-size: 14px;
  line-height: 16px;
  color: var(--white);
  margin-top: 8px;
}
.HkPeJQxW .U217IrPN {
  font-family: var(--CommonBold);
  font-size: 18px;
  line-height: 24px;
  color: var(--white);
}
.HkPeJQxW .EHB1XCBk {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.HkPeJQxW .YPEa0cb9 {
  font-size: 16px;
  line-height: 24px;
  color: var(--white-op3);
  margin: 33px 0 16px 0;
}
.HkPeJQxW .__9Xla5GYi {
  font-family: var(--CommonMedium);
  font-size: 24px;
  line-height: 24px;
  color: var(--white);
  margin-bottom: 21px;
}
.HkPeJQxW .rHWYUwlg {
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
  margin-bottom: 8px;
}
.HkPeJQxW .__0Hkjca1f {
  cursor: pointer;
  font-family: var(--CommonMedium);
  font-size: 14px;
  line-height: 22px;
  color: var(--main-color);
}
.HkPeJQxW .WRXEM3IS {
  margin-top: 16px;
  font-size: 14px;
  line-height: 14px;
  text-align: justify;
  color: var(--input-red-bg);
}
.HkPeJQxW .VvnQWSqq {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.HkPeJQxW .__3fJOJfmG {
  font-size: 14px;
  line-height: 22px;
  color: var(--white-op1);
}
.HkPeJQxW .fkiTjFDt {
  font-size: 12px;
  line-height: 22px;
  color: var(--white-op4);
  margin-bottom: 32px;
}
.HkPeJQxW .__28gMfFSc {
  width: 128px;
  height: 40px;
}
.HkPeJQxW .aCvIXTD5 {
  width: 128px;
  height: 40px;
  margin-left: 16px;
}
.ZOYjUkHK {
  max-width: 400px !important;
}
.oDEjcfue {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 8px;
}
.oDEjcfue :first-child {
  width: 60%;
}
.oDEjcfue .Wn_Z5uYS {
  font-size: 14px;
  line-height: 22px;
  color: var(--white-op3);
}
.__59LmjRzg {
  font-size: 14px;
  line-height: 22px;
  color: var(--white-op4);
}
.N3tfn6UY {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin-top: 24px;
  background-color: var(--main-color);
  color: var(--white);
}
.N3tfn6UY:hover {
  color: var(--white);
  background-color: var(--main-btn-hover-color);
}
.VYDagmbt {
  cursor: pointer;
}
.JayLtfWv {
  display: inline-block;
  padding: 16px 32px 16px 16px;
}
.Lcj8Th0q {
  text-align: center;
  padding-left: 2px;
}
.VYDagmbt:hover {
  background-color: var(--main-content-hover-color);
  color: var(--main-color);
}
._ABTTpyE {
  min-height: 400px;
}
.Zi7MBF3L {
  flex: 0 0 483px;
  margin-left: 44px;
}
.Zi7MBF3L .__1fGS7vr3 {
  color: var(--white);
  font-family: var(--CommonMedium);
  font-size: 16px;
  line-height: 24px;
}
.Zi7MBF3L .IdMcl4RT {
  display: flex;
}
.Zi7MBF3L .IdMcl4RT .PIS_1jJt {
  margin-top: 24px;
  color: var(--white-op1);
  font-size: 14px;
  line-height: 21px;
}
.Zi7MBF3L .IdMcl4RT .__5Pmz5z3a {
  margin-top: 16px;
  color: var(--white-op1);
  font-family: var(--CommonMedium);
  font-size: 14px;
  line-height: 21px;
}
.Zi7MBF3L .IdMcl4RT .G_zbvq08 {
  cursor: pointer;
  flex: 0 0 240px;
  margin-left: 24px;
}
.Zi7MBF3L .IdMcl4RT .G_zbvq08:hover {
  transform: scale(3) translateX(-54px) translateY(48px);
  background-color: var(--module-bg);
  padding: 16px;
  z-index: 2;
}
.Zi7MBF3L .p7EPFfWd {
  display: flex;
}
.Zi7MBF3L .p7EPFfWd .eI6IzbUH {
  flex: 0 0 4px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--main-color);
  margin-top: 8px;
  margin-right: 11px;
}
.Zi7MBF3L .p7EPFfWd .__0n7RXouT {
  color: var(--white-op1);
  font-family: var(--CommonMedium);
  font-size: 12px;
  line-height: 21px;
}
.Zi7MBF3L .QNk0155v {
  margin-top: 32px;
}
.Zi7MBF3L .QNk0155v .__8E3y6sxg {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Zi7MBF3L .QNk0155v .wHdfAxYG {
  color: var(--white-op1);
  font-size: 14px;
  line-height: 21px;
}
.Zi7MBF3L .QNk0155v .vR3mpxfo {
  margin-top: 36px;
}
.Zi7MBF3L .QNk0155v .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.Zi7MBF3L .QNk0155v .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid var(--border-color3);
}
.Zi7MBF3L .QNk0155v .ant-tabs-tab {
  color: var(--white-op5);
  font-size: 14px;
  line-height: 22px;
}
.Zi7MBF3L .QNk0155v .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 1px solid var(--main-color);
  bottom: -1px;
}
.__6_ejxW__ {
  padding-left: 8px;
  cursor: pointer;
  color: var(--white-op1);
}
.hmmziJQ0 {
  color: var(--main-color);
  font-family: var(--CommonMedium);
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.WEZNNssH {
  width: 262px !important;
  background-color: var(--input-bg) !important;
}
.Mu2DjZM8 {
  width: 110px !important;
  background-color: var(--input-bg) !important;
  height: 60px !important;
  font-size: 14px !important;
  font-family: var(--CommonRegular) !important;
  color: var(--main-color) !important;
  line-height: 22px !important;
  border: none !important;
}
.__9Ujxf92m {
  flex: 1 1;
  width: 100%;
}
.__9Ujxf92m.xkZILSq0 .ant-btn[disabled] {
  background-color: var(--input-bg) !important;
}
.__9Ujxf92m .ant-form-item {
  margin-bottom: 0 !important;
}
.__9Ujxf92m .__7d4hpxXE {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 32px;
}
.__9Ujxf92m .TYdxG7wt {
  font-family: var(--CommonMedium);
  font-size: 22px;
  line-height: 36px;
  color: var(--white);
}
.__9Ujxf92m .__8EAHquvs {
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
}
.__9Ujxf92m .Q0qWlpCw {
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
  color: var(--white-op2);
}
.xD5vrBHw {
  font-size: 14px;
  line-height: 16px;
  color: var(--main-color);
  cursor: pointer;
}
.Unm3DIFF {
  width: 100%;
  height: 56px;
  border-radius: 2px;
  font-size: 18px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
}
._F4TrfMK {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  font-size: 2rem;
}
.bWklj_db {
  width: 58px !important;
  height: 60px;
  background-color: var(--input-bg);
  border: none;
  outline: none;
  /*清除input点击之后的黑色边框*/
  border-radius: 1px;
}
.__4p4ewEmg {
  cursor: pointer;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 0;
  height: 48px;
  background: var(--module-bg);
  border-radius: 1px;
}
.__4p4ewEmg .tbK3BT_I {
  display: flex;
}
.__4p4ewEmg .sGqSmOo0 {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.__4p4ewEmg .__9ASlvoP6 {
  font-size: 14px;
  line-height: 20px;
  color: var(--white);
}
.__4p4ewEmg .kvLahFoQ {
  color: var(--main-color);
  font-size: 12px;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.cmn0EtP8 .ant-modal-body {
  margin-bottom: 48px;
}
.cmn0EtP8 .ant-modal-body input::-webkit-outer-spin-button,
.cmn0EtP8 .ant-modal-body input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.cmn0EtP8 .ant-input {
  height: 40px;
  width: 100%;
  background: var(--module-bg);
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color-second);
  line-height: 22px;
}
.cmn0EtP8 .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}
.cmn0EtP8 .ant-modal-footer button:first-child {
  display: inline-block;
  margin-left: 16px;
  order: 1;
}
.cmn0EtP8 .ant-modal-footer button:first-child:hover {
  border-color: var(--main-color);
  color: var(--main-color);
}
.cmn0EtP8 .ant-modal-footer .ant-btn-primary {
  background: var(--main-color);
}
.cmn0EtP8 .ant-modal-footer .ant-btn {
  cursor: pointer;
  width: 108px;
  background: transparent;
  border: 1px solid var(--btn-border);
  height: 40px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
  border-radius: 4px;
}
.cmn0EtP8 .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  width: 108px;
}
.cmn0EtP8 .M2_HcxhH {
  background: var(--module-bg);
  color: var(--main-color);
  margin-left: 12px;
  height: 40px;
}
.cmn0EtP8 ._XnYFZzU {
  width: 100%;
  height: 40px;
  background: var(--btn-grey1-bg);
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
  margin-bottom: 24px;
}
.cmn0EtP8 .joiIQaml {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
  margin-bottom: 4px;
}
.cmn0EtP8 .XvRQIU5T {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color-second);
  line-height: 22px;
  margin-bottom: 24px;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.v3EJMPdT {
  cursor: pointer;
  color: var(--main-color);
  font-size: 14px;
  margin-top: 12px;
  display: inline-block;
}
.qQ4FP8hO {
  color: var(--white-op5);
  font-size: 12px;
  display: inline-block;
  margin-top: 6px;
  margin-bottom: 8px;
}
.SlDuXRFE {
  background-color: var(--module-bg);
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.__9NaJAAHk {
  display: inline-block;
  position: absolute;
  left: 0;
  z-index: 0;
  padding-left: 11px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
}
.ksfd2QzW {
  top: -15px !important;
  color: var(--remark-text-color) !important;
}
.BvZ2DDUI {
  position: relative;
  width: 415px;
  height: 60px;
  background-color: var(--module-bg);
}
.BRgzitUB {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.BRgzitUB .IMbP_DKo {
  position: relative;
  width: 285px;
  height: 60px;
  background-color: var(--module-bg);
}
.BRgzitUB .IMbP_DKo .ant-input {
  height: 60px !important;
  border-radius: 2px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  padding-top: 35px;
  padding-bottom: 13px;
  background: transparent !important;
}
.BRgzitUB .ant-btn[disabled] {
  background-color: var(--module-bg) !important;
}
.BRgzitUB .ant-modal-footer .ant-btn {
  height: 48px !important;
}
.BRgzitUB .ant-form {
  display: table;
  width: 100%;
}
.BRgzitUB .ant-form-vertical .ant-form-item {
  margin-bottom: 32px;
  position: relative;
}
.BRgzitUB .ant-form-item-control {
  height: 60px;
  background: var(--main-bg-color);
  position: relative;
}
.BRgzitUB .ant-form-item-control-input {
  min-height: 60px;
}
.BRgzitUB .ant-form-item {
  line-height: 60px;
  margin-bottom: 0 !important;
}
.BRgzitUB .ant-form-item-control-input-content {
  height: 60px;
}
.BRgzitUB .ant-input[disabled] {
  color: var(--white);
}
.BRgzitUB .ant-form-item-explain-error {
  font-size: 12px;
  padding-top: 3px;
}
.BRgzitUB .ant-form-item-margin-offset {
  margin-bottom: 0 !important;
}
.WT5M7j_N {
  width: 120px;
  background-color: var(--module-bg);
  height: 60px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border: none;
}
.WT5M7j_N:hover {
  background-color: var(--module-bg);
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.__0zNoXP9t .m6ZFwX1i {
  font-size: 18px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 21px;
  margin-top: 18px;
}
.__0zNoXP9t .xHrduxen {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color-second);
  line-height: 22px;
  margin: 24px 0;
}
.__0zNoXP9t .VNOqBM07 .UejgtH42 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.__0zNoXP9t .VNOqBM07 .UejgtH42 .F4Hxqzbi {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color-second);
  line-height: 22px;
  margin-right: 10px;
}
.__0zNoXP9t .VNOqBM07 .UejgtH42 .g0PbbhWp {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
}
.__0zNoXP9t .L4QtwwD5 {
  margin-bottom: 0;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.KaT2fOfa .ant-modal-body {
  margin-bottom: 48px;
}
.KaT2fOfa .ant-input {
  height: 40px;
  background: var(--module-bg);
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color-second);
  line-height: 22px;
}
.KaT2fOfa .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
}
.KaT2fOfa .ant-modal-footer button:first-child {
  display: inline-block;
  margin-left: 16px;
  order: 1;
}
.KaT2fOfa .ant-modal-footer button:first-child:hover {
  border-color: var(--main-color);
  color: var(--main-color);
}
.KaT2fOfa .ant-modal-footer .ant-btn-primary {
  background: var(--main-color);
}
.KaT2fOfa .ant-modal-footer .ant-btn {
  cursor: pointer;
  width: 108px;
  background: transparent;
  border: 1px solid var(--btn-border);
  height: 40px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
  border-radius: 4px;
  margin-bottom: 24px;
}
.KaT2fOfa .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  width: 108px;
}
.KaT2fOfa .yWa16Pqd {
  background: var(--module-bg);
  color: var(--main-color);
  margin-left: 12px;
  height: 40px;
}
.KaT2fOfa .w7CbRexr {
  width: 100%;
  height: 40px;
  background: var(--btn-grey1-bg);
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
  margin-bottom: 24px;
}
.KaT2fOfa .Z0fKqSan {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
  margin-bottom: 4px;
}
.KaT2fOfa .qD_t03Oz {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color-second);
  line-height: 22px;
  margin-bottom: 24px;
}
.KaT2fOfa .fDCAaWBp {
  color: var(--tip-text-color);
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.cOv6ndU2 {
  width: 200px !important;
}
.cOv6ndU2 .ant-select-selection-item,
.cOv6ndU2 .ant-select-arrow,
.cOv6ndU2 .ant-select-selection-item {
  color: var(--white) !important;
}
.cOv6ndU2 .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.cOv6ndU2 .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  text-align: center !important;
}
.t3_j5xvp {
  display: flex;
  height: 60px;
  margin-bottom: 16px;
  align-items: center;
}
.t3_j5xvp .ant-form-item {
  margin-bottom: 32px !important;
}
.t3_j5xvp .__6DBsT_n4 {
  margin-right: 8px;
  height: 60px;
  flex: 0 0 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--input-bg);
  border-radius: 4px;
}
.t3_j5xvp .__8kMVhYrt {
  height: 60px;
  padding-left: 24px;
  flex: 1 1;
  display: flex;
  align-items: center;
  background-color: var(--input-bg);
  border-radius: 4px;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.Gy0_gSgK {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.Gy0_gSgK .vAwENAby {
  width: 110px;
  margin-right: 8px;
  height: 60px;
  flex: 0 0 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--input-bg);
  border-radius: 4px;
}
.Gy0_gSgK .pyIp9wGi {
  position: relative;
  height: 60px;
  flex: 1 1;
  display: flex;
  background-color: var(--input-bg);
  border-radius: 4px;
}
.Gy0_gSgK .pyIp9wGi .__8JOvQpK1 {
  background-color: var(--input-bg);
}
.Gy0_gSgK .pyIp9wGi .WMtolzCB {
  position: relative;
  padding-left: 11px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  display: flex;
  align-items: center;
}
.Gy0_gSgK .pyIp9wGi {
  border-radius: 0 !important;
}
.Gy0_gSgK .pyIp9wGi .ant-input {
  height: 60px;
  position: absolute;
  top: 0;
}
.Gy0_gSgK .pyIp9wGi .ant-input:hover {
  opacity: 1;
}
.__9TTlr7L0 {
  top: -15px !important;
  z-index: 1 !important;
  color: var(--remark-text-color) !important;
}
.I6tGpE4_ {
  opacity: 1;
  position: absolute;
  top: 20px;
}
.tfa_y7nt {
  position: absolute;
  top: 60px;
  color: var(--input-red-bg);
  font-size: 12px;
  height: 20px;
  line-height: 20px;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.__75EMqKBV {
  width: 100% !important;
  background: var(--main-bg-color);
  border-radius: 4px;
  margin-bottom: 24px;
  min-height: 430px;
  position: relative;
}
.__75EMqKBV .__2o4TpyBH {
  width: 47px;
  height: 30px;
}
.__75EMqKBV:hover {
  background: var(--card-hover-bg);
  box-shadow: 3px 3px 8px var(--box-shadow);
}
.__75EMqKBV:hover .Et0TBVZa > ._2bVWbvP {
  background: var(--small-card-hover-bg);
}
.__75EMqKBV ._5GEorX2 {
  position: absolute;
  left: 11px;
  top: -2px;
  z-index: 10;
  filter: drop-shadow(0px 1px 1px var(--closeTag-box-shadow));
}
.__75EMqKBV .pi3pig29 {
  border-radius: 4px 4px 0 0;
  overflow: hidden;
}
.__75EMqKBV .Et0TBVZa {
  padding: 16px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.__75EMqKBV .Et0TBVZa .xczegeDo {
  display: flex;
  justify-content: center;
  margin-top: -65px;
}
.__75EMqKBV .Et0TBVZa .fYIMSTqR {
  margin-top: 6px;
  text-align: center;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  width: 260px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0px 2px 4px var(--box-shadow);
  position: relative;
  z-index: 99;
}
.__75EMqKBV .Et0TBVZa .ksl9Actm {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
}
.__75EMqKBV .Et0TBVZa .z_9YO16R {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 18px;
  max-height: 52px;
  overflow: hidden;
  margin-bottom: 16px;
  min-height: 52px;
  word-break: break-all;
}
.__75EMqKBV .Et0TBVZa .z_9YO16R .kVZkblDL {
  display: inline-block;
  font-size: 12px;
  border-radius: 2px;
  height: 18px;
  background-color: var(--main-content-hover-color);
  color: var(--highlight-color1);
  padding: 0px 8px 3px 8px;
  box-sizing: border-box;
  margin-right: 5px;
}
.__75EMqKBV .Et0TBVZa ._2bVWbvP {
  width: 100%;
  background-color: var(--small-card-bg);
  border-radius: 4px;
  height: 68px;
  display: flex;
  padding-top: 8px;
  padding-bottom: 12px;
  margin-bottom: 18px;
  justify-content: space-between;
  text-align: center;
}
.__75EMqKBV .Et0TBVZa ._2bVWbvP .__3yiobQW3 {
  flex: 1 1;
}
.__75EMqKBV .Et0TBVZa ._2bVWbvP .AVRWV4lb {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
}
.__75EMqKBV .Et0TBVZa ._2bVWbvP .s_9ZQ4eP {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  min-height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.__75EMqKBV .Et0TBVZa .DDQ0moA_ {
  text-align: center;
  font-family: var(--CommonRegular);
  color: var(--main-color);
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.__75EMqKBV .ant-image {
  width: 100%;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.ant-modal.baseModal {
  padding-bottom: 0;
}
.ant-modal.baseModal .ant-modal-content {
  background: var(--grey-level1) !important;
}
.XTVy7JsD {
  padding: 48px 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.XTVy7JsD span.YlolNFYm {
  margin-bottom: 24px;
}
.XTVy7JsD .__1S4r_EHK {
  font-size: 18px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color-second);
  line-height: 21px;
  margin-bottom: 41px;
}
.XTVy7JsD .nc-container {
  position: static !important;
  font-size: 14px !important;
}
.XTVy7JsD .nc-container .nc_wrapper {
  width: 100% !important;
  height: 56px !important;
  font-size: 14px !important;
  line-height: 56px !important;
}
.XTVy7JsD .nc-container .nc_wrapper .nc_scale {
  height: 100%;
  background-color: var(--alicloud-grey-bg);
}
.XTVy7JsD .nc-container .nc_wrapper .nc_scale .btn_slide {
  color: var(--white);
}
.XTVy7JsD .nc-container .nc_wrapper .nc_scale .nc_bg {
  background-color: var(--btn-green-bg);
}
.XTVy7JsD .nc-container .nc_wrapper .nc_scale .nc_iconfont.btn_ok {
  color: var(--btn-green-bg);
  border: 1px solid var(--btn-green-bg);
}
.XTVy7JsD .nc-container .nc_wrapper .nc_scale .btn_slide,
.XTVy7JsD .nc-container .nc_wrapper .nc_scale .nc_iconfont.btn_ok {
  width: 90px;
  height: 100%;
  line-height: 56px;
  border: none;
  background: var(--alicloud-black-bg);
  border: 1px solid var(--alicloud-grey-bg);
}
.XTVy7JsD .nc-container .nc_wrapper .nc_scale .scale_text.slidetounlock {
  color: var(--white);
  line-height: 56px;
}
.XTVy7JsD .nc-container .nc_wrapper .nc_scale .scale_text.slidetounlock span[data-nc-lang="SLIDE"] {
  background: none;
  background-clip: unset;
  -webkit-text-fill-color: unset;
}
.XTVy7JsD .nc-container .nc_wrapper .nc_scale .scale_text2.slidetounlock {
  color: var(--white);
  text-indent: 0;
}
.XTVy7JsD .nc-container .nc_wrapper .nc_scale .scale_text2.slidetounlock .nc-lang-cnt b {
  margin-left: -42px;
}
.XTVy7JsD .nc-container .nc_wrapper .errloading {
  border: none !important;
  background: var(--btn-red-bg) !important;
  line-height: 36px !important;
  cursor: pointer;
  color: var(--white) !important;
}
.XTVy7JsD .nc-container .nc_wrapper .errloading .icon_warn {
  display: none;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.JnPeIkMR .Z0rY_M7i {
  max-width: var(--max-width) !important;
  display: flex;
  justify-content: space-between;
}
.JnPeIkMR .Dw7fPUE0 {
  background: var(--main-color);
  width: 134px;
  height: 40px;
  line-height: 40px;
  font-family: var(--CommonRegular);
  color: var(--white);
  border-radius: 20px;
  padding: 0;
  box-shadow: none;
  border: none;
}
.JnPeIkMR .vQ_q65OV {
  margin: auto;
  background: var(--module-bg);
  padding: 24px;
  width: 100%;
  width: var(--max-width);
  /* 防止屏幕过宽显示不好看*/
  margin-bottom: 16px;
}
.JnPeIkMR .vQ_q65OV .OZ5M5tOD {
  background-color: var(--btn-profile-bg);
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border: none;
  text-align: left;
}
.JnPeIkMR .vQ_q65OV .CFxWtYG0 {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--btn-green-bg);
  line-height: 22px;
  margin-left: 5px;
}
.JnPeIkMR .vQ_q65OV .K360Qozz {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
  overflow: hidden;
}
.JnPeIkMR .vQ_q65OV ._JxlzDL6 {
  padding-top: 44px;
  padding-bottom: 47px;
  cursor: pointer;
}
.JnPeIkMR .vQ_q65OV ._JxlzDL6 ._GXXWtF4 {
  font-size: 22px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
  margin-bottom: 27px;
}
.JnPeIkMR .vQ_q65OV ._JxlzDL6 .dJepYVR_ {
  border: 1px solid var(--main-color) !important;
  color: var(--main-color) !important;
}
.JnPeIkMR .vQ_q65OV ._JxlzDL6 .dJepYVR_:hover {
  color: var(--white) !important;
  background-color: var(--main-btn-hover-color) !important;
  transition: none;
}
.JnPeIkMR .vQ_q65OV ._JxlzDL6 .ant-btn {
  width: 209px;
  padding: 0 4px;
  margin-right: 40px;
  height: 38px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
}
.JnPeIkMR .vQ_q65OV ._JxlzDL6 .ant-btn-primary {
  color: var(--white);
}
.JnPeIkMR .vQ_q65OV ._jvXMlO8 {
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 30px;
  margin-bottom: 24px;
}
.JnPeIkMR .vQ_q65OV .UBrHzDau {
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--main-color);
  line-height: 30px;
  margin-bottom: 24px;
}
.JnPeIkMR .vQ_q65OV .__9eILhhlw {
  font-size: 14px;
  color: var(--main-color);
  cursor: pointer;
  margin-bottom: 24px;
}
.JnPeIkMR .vQ_q65OV .__2yMeIeVB {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
}
.JnPeIkMR .vQ_q65OV .PfjEv_qN {
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--border-color);
}
.JnPeIkMR .vQ_q65OV .__8vQg0vsB {
  position: relative;
}
.JnPeIkMR .vQ_q65OV .__8vQg0vsB .ant-row {
  width: 100%;
  margin-top: 26px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}
.JnPeIkMR .vQ_q65OV .__8vQg0vsB .ant-input[disabled] {
  cursor: pointer;
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 400;
  color: var(--white);
  line-height: 40px;
  display: inline-block;
}
.JnPeIkMR .vQ_q65OV .__8vQg0vsB .c5FseaP_ {
  font-size: 16px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 300px;
  white-space: nowrap;
}
.JnPeIkMR .vQ_q65OV .__8vQg0vsB .KY2AGJbn {
  font-size: 16px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 24px;
}
.JnPeIkMR .vQ_q65OV .__8vQg0vsB ._lgpnlbS {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white-op2);
}
.JnPeIkMR .vQ_q65OV .__8vQg0vsB .Kvu_71bu {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
}
.JnPeIkMR .vQ_q65OV .__8vQg0vsB .dvz2BX9Q {
  font-size: 14px;
  font-family: var(--CommonMedium);
  color: var(--white);
  margin-left: 5px;
}
.JnPeIkMR .vQ_q65OV .__8vQg0vsB .FQ_Van3N {
  font-size: 14px;
  color: var(--text-color);
}
.JnPeIkMR .vQ_q65OV .oTFRyvkq .LnBnRJBs {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.JnPeIkMR .vQ_q65OV .oTFRyvkq .LnBnRJBs .A8CNmdk_ .Y4uz2UTx {
  color: var(--main-color) !important;
}
.JnPeIkMR .vQ_q65OV .oTFRyvkq .LnBnRJBs .A8CNmdk_:not(.__4ZmljeBK) .JTf9zQKr {
  border: 1px solid var(--main-color);
}
.JnPeIkMR .vQ_q65OV .oTFRyvkq .LnBnRJBs .BGvuiFLB {
  color: var(--main-color);
}
.JnPeIkMR .vQ_q65OV .oTFRyvkq .LnBnRJBs .NQNp_BHS {
  display: flex;
  align-items: center;
}
.JnPeIkMR .vQ_q65OV .oTFRyvkq .LnBnRJBs .NQNp_BHS .Rr7SBIa_ {
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
}
.JnPeIkMR .vQ_q65OV .oTFRyvkq .LnBnRJBs .NQNp_BHS .k6E1cyga {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 24px;
  background: var(--blue);
  border-radius: 12px;
  margin-left: 24px;
}
.JnPeIkMR .vQ_q65OV .oTFRyvkq .LnBnRJBs .NQNp_BHS .qr24hf_e {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 24px;
  background: var(--main-color);
  border-radius: 12px;
  margin-left: 24px;
}
.JnPeIkMR .vQ_q65OV .oTFRyvkq .LnBnRJBs .f60qOcZq {
  padding: 7px 16px;
  font-family: var(--CommonMedium);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
}
.JnPeIkMR .vQ_q65OV .oTFRyvkq .HufoPDMh {
  padding: 24px 0 0 0;
}
.JnPeIkMR .vQ_q65OV .oTFRyvkq .HufoPDMh .pMFRx2Aj {
  color: var(--white);
  text-align: center;
}
.JnPeIkMR .vQ_q65OV .oTFRyvkq .HufoPDMh .__03tydTQQ .yuJnRfEL {
  margin-bottom: 24px;
  color: var(--white);
}
.JnPeIkMR .vQ_q65OV .oTFRyvkq .HufoPDMh .__03tydTQQ .yuJnRfEL .Ai6_xWnr {
  display: inline-block;
  width: 120px;
  margin-right: 90px;
}
.JnPeIkMR .vQ_q65OV .oTFRyvkq .HufoPDMh .__03tydTQQ .yuJnRfEL .lwwTol0W {
  color: var(--main-color);
  cursor: pointer;
}
.JnPeIkMR .vQ_q65OV .zGX37keV {
  padding-top: 24px;
}
.JnPeIkMR .vQ_q65OV .zGX37keV .nRnJ0ZLP {
  text-align: center;
}
.JnPeIkMR .__0MdUSASc {
  width: 66px;
  height: 36px;
  background: var(--main-color);
  border-radius: 18px;
  font-size: 16px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 36px;
  text-align: center;
}
.F0EFa6f6 .ant-modal-footer {
  display: block;
  justify-content: start;
}
.F0EFa6f6 .ant-modal-footer button:first-child {
  display: none;
}
.F0EFa6f6 .ant-modal-content {
  min-height: 320px;
}
.F0EFa6f6 .u2k7oYqo {
  position: absolute;
  bottom: 20px;
  left: 32px;
  right: 32px;
  width: calc(100% - 32px - 32px);
}
.Ip0aC0im {
  color: var(--white);
  font-family: var(--CommonBold);
  font-size: 16px;
  line-height: 32px;
}
.__14q0OXJj {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 52px;
}
.Q9nY_TVz .ant-form-item-control-input .ant-input {
  background: var(--module-bg);
}
.Q9nY_TVz .ant-form-item-control-input-content {
  background: transparent;
}
.Q9nY_TVz .ant-modal-header .ant-modal-title {
  color: var(--white);
}
.Q9nY_TVz .ant-modal-body {
  margin-bottom: 48px;
}
.Q9nY_TVz .ant-modal-footer {
  display: flex;
  border-top: none;
  justify-content: flex-end;
  padding-bottom: 4px;
}
.Q9nY_TVz .ant-modal-footer button:first-child {
  display: inline-block;
  margin-left: 16px;
  order: 1;
}
.Q9nY_TVz .ant-modal-footer button:first-child:hover {
  border-color: var(--main-color);
  color: var(--main-color);
}
.Q9nY_TVz .ant-modal-footer .ant-btn-primary {
  background: var(--main-color);
}
.Q9nY_TVz .ant-modal-footer .ant-btn {
  cursor: pointer;
  width: 108px;
  background: transparent;
  border: 1px solid var(--btn-border);
  height: 40px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
  border-radius: 4px;
}
.Q9nY_TVz .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  width: 108px;
}
.Q9nY_TVz .lKPPw7v_ {
  background: var(--module-bg);
  color: var(--main-color);
  margin-left: 12px;
  height: 40px;
}
.Q9nY_TVz .clQqYPST {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 52px;
  margin-bottom: 25px;
}
.Q9nY_TVz .clQqYPST .libLqi5p {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/images/google_qr_bg_dash.webp");
  padding: 30px;
}
.Q9nY_TVz .iiFyomUf {
  filter: blur(8px);
}
.Q9nY_TVz .F2ycuurZ {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  font-size: 2rem;
}
.Q9nY_TVz .rOQa06as {
  width: 58px !important;
  height: 60px;
  background-color: var(--module-bg);
  border: none;
  outline: none;
  /*清除input点击之后的黑色边框*/
  border-radius: 1px;
}
.Q9nY_TVz .mEy76Pbl {
  width: 100%;
  height: 40px;
  background: var(--btn-grey1-bg);
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
  margin-bottom: 24px;
}
.Q9nY_TVz .CuuqQhpP {
  width: 100%;
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: 116px auto;
}
.Q9nY_TVz .BYZq77jV {
  background: var(--btn-grey-bg);
  text-align: center;
  width: 283px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Q9nY_TVz .KonIXvTJ {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
  margin-bottom: 4px;
}
.Q9nY_TVz .Cal3fvsw {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  margin-bottom: 24px;
}
.Q9nY_TVz .SN2SRGiM .__4216m4zM {
  margin-top: 30px;
}
.Q9nY_TVz .SN2SRGiM .__4216m4zM .npdRyoT9 {
  margin-left: 22px;
  color: var(--main-color);
  cursor: pointer;
}
.Q9nY_TVz .SN2SRGiM .__0n64sVQ9 {
  margin-bottom: 30px;
  color: var(--tip-text-color);
}
.Q9nY_TVz .SN2SRGiM .EJE7V_uC {
  margin-top: 24px;
}
.Q9nY_TVz .SN2SRGiM .EJE7V_uC ._jvXMlO8 {
  font-weight: 600;
  font-size: 14px;
  color: var(--white);
  margin-bottom: 20px;
}
.Q9nY_TVz ._wrhb1Iu :not(.__7pnjchqM).S56lPF3x,
.Q9nY_TVz ._wrhb1Iu :not(.zoP0NS_K).Zkk_ZszZ,
.Q9nY_TVz ._wrhb1Iu :not(.__7pnjchqM).S56lPF3x:hover,
.Q9nY_TVz ._wrhb1Iu :not(.zoP0NS_K).Zkk_ZszZ:hover {
  background: var(--module-bg);
}
.FUz4BikJ .ant-modal-body {
  margin-bottom: 0 !important;
}
.FUz4BikJ .ant-form-item {
  margin-bottom: 32px !important;
}
.hwjmaEIQ {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--tip-text-color);
  line-height: 22px;
}
.Yuu2bGaJ {
  width: 100%;
  background: var(--module-bg);
  padding: 24px;
}
.Yuu2bGaJ ._jvXMlO8 {
  font-size: 18px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 26px;
  margin-bottom: 26px;
  text-align: left;
}
.tJpA3gmt p {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
}
.tJpA3gmt .cSpKve4g {
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 30px;
  margin-top: 8px;
  margin-bottom: 24px;
}
.tJpA3gmt .WO6sjds_ {
  font-size: 16px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--text-color);
  line-height: 24px;
  margin-bottom: 10px;
}
.tJpA3gmt.m3wymCgf .ant-modal-footer button:first-child {
  display: none;
}
.tJpA3gmt .EJE7V_uC {
  margin-top: 24px;
}
.tJpA3gmt .EJE7V_uC ._jvXMlO8 {
  font-weight: 600;
  font-size: 14px;
  color: var(--white);
  margin-bottom: 20px;
}
.TCU1bHx_ .cOd3kJXP {
  background: url("/images/image_banner@2x.webp");
  height: auto;
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  height: 240px;
  background-size: cover;
  padding: 24px 122px;
  min-width: 1200px;
}
.TCU1bHx_ .cOd3kJXP .JQxhkk21 {
  width: 1200px;
  margin: 0 auto;
}
.TCU1bHx_ .cOd3kJXP .JQxhkk21 .LnBnRJBs {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
}
.TCU1bHx_ .cOd3kJXP .JQxhkk21 .LnBnRJBs .Fbzxvt_l {
  font-family: var(--CommonBold);
  font-size: 32px;
  color: var(--white);
}
.TCU1bHx_ .cOd3kJXP .JQxhkk21 .__9k2PbqFU {
  margin-top: 16px;
  color: var(--white-op1);
}
.TCU1bHx_ .aMrU6A3W {
  margin-top: 260px;
  padding: 60px;
  background-color: var(--module-bg);
}
.TCU1bHx_ .aMrU6A3W .m3zTo50w {
  height: 40px;
  display: flex;
  align-items: center;
  background-color: var(--error-text-bg);
  border: 1px solid var(--error-text-border);
  margin-bottom: 40px;
  padding: 12px;
  border-radius: 2px;
}
.TCU1bHx_ .aMrU6A3W .m3zTo50w .__6b4QkFtX {
  margin-left: 5px;
  color: var(--error-text);
}
.TCU1bHx_ .aMrU6A3W .__2lCmsYwo {
  margin-bottom: 40px;
}
.TCU1bHx_ .aMrU6A3W .__2lCmsYwo .ant-steps-item-container {
  font-family: var(--CommonMedium);
  white-space: nowrap;
}
.TCU1bHx_ .aMrU6A3W .ycpivSX2 {
  margin-bottom: 30px;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR {
  margin-bottom: 20px;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .ant-select-selection-placeholder,
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .ant-select-selection-item {
  font-family: var(--CommonRegular);
  font-size: 14px;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .ant-input-group-wrapper {
  background-color: var(--module-bg);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .ant-select-selector {
  background-color: var(--main-bg-color) !important;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .ant-select-status-error.ant-select:not(.ant-select-disabled):not(
            .ant-select-customize-input
          ):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: var(--main-color) !important;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .ant-picker {
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 0px;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .ant-picker-clear {
  background-color: var(--main-bg-color);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .ant-form-item-explain-connected {
  display: flex;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .ant-form-item-explain-error {
  margin-right: 54px;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .dKPQ7DN6 {
  display: grid;
  justify-content: space-between;
  align-items: flex-end;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-auto-flow: row;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .__2yMeIeVB {
  margin: 10px 0 25px;
  font-size: 20px;
  font-family: var(--CommonBold);
  color: var(--white);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .MpqZ_DxU {
  margin: 10px 0 25px;
  font-size: 18px;
  font-family: var(--CommonMedium);
  color: var(--white);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .__9RLHdah0 {
  display: flex;
  justify-content: space-between;
  background-color: var(--module-bg);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .__9RLHdah0 .UPHYbENr {
  display: flex;
  align-items: center;
  margin: 0 10px;
  color: var(--white-op2);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .pgbVQuVm {
  margin-bottom: 26px;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .pgbVQuVm .__4Zs9Xpki {
  margin-right: 5px;
  color: var(--main-color);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .pgbVQuVm .wUAOsiRz {
  font-size: 16px;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .pgbVQuVm .wUAOsiRz:hover {
  color: var(--white);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .Fu4aFDpX {
  height: 60px;
  line-height: 60px;
  background-color: var(--module-bg);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .CT_OO1Z0 .ant-form-item-control-input-content {
  background: var(--module-bg);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .CT_OO1Z0 .Dqm1cN6i {
  margin-right: 5px;
  font-size: 16px;
  color: var(--main-color);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .CT_OO1Z0 .ehlF20zF {
  font-size: 16px;
  color: var(--white);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .CT_OO1Z0 .gupchQXt {
  margin-left: 27px;
  color: var(--white);
  font-size: 14px;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .hGocgmMh {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .hGocgmMh .vjHEh4Pb {
  width: 60%;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .hGocgmMh .vjHEh4Pb .GX7fjivU {
  margin-bottom: 30px;
  font-family: var(--CommonBold);
  font-size: 30px;
  line-height: 40px;
  color: var(--white);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .hGocgmMh .vjHEh4Pb .kh7_M_KM .C6t6ZMed {
  margin-bottom: 24px;
  color: var(--white);
  font-size: 16px;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .hGocgmMh .vjHEh4Pb .kh7_M_KM .C6t6ZMed .LOcbFUAn {
  color: var(--main-color);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .hGocgmMh ._Z_8tQGA {
  border-left: 1px solid var(--border-color);
  padding: 18px 0px 0px 50px;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .hGocgmMh ._Z_8tQGA .hKoaChVI {
  margin-bottom: 14px;
  color: var(--white);
  font-family: var(--CommonMedium);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .hGocgmMh ._Z_8tQGA .KA_wQhEw {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .hGocgmMh ._Z_8tQGA .KA_wQhEw .PfjEv_qN {
  margin: 0 22px;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .zVSUB4OT .eYdGYpak {
  color: var(--input-red-bg);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .zVSUB4OT .pY_4k_BL {
  margin-bottom: 30px;
  font-family: var(--CommonMedium);
  font-size: 18px;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .lK8_NzDT .__2l1wfGYd {
  margin-bottom: 10px;
  color: var(--white-op3);
  font-family: var(--CommonMedium);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .lK8_NzDT .NZ8D8fn8 {
  font-size: 12px;
  color: var(--white-op1);
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .UEHUNqF1 {
  margin: 10px 0 40px;
  width: 300px;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .V4ryq_Cg {
  width: 80%;
  background-color: var(--tab-active-bg);
  padding: 24px;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .V4ryq_Cg .dfhCD2JR {
  font-size: 16px;
  margin-bottom: 16px;
}
.TCU1bHx_ .aMrU6A3W ._sMrmYnR .IPoX1ORJ .V4ryq_Cg .ibiyb1kl {
  margin-bottom: 5px;
  color: var(--white-op1);
}
.TCU1bHx_ .aMrU6A3W .ptzdhpjm {
  width: 140px;
  font-family: var(--CommonBold);
  margin-top: 20px;
}
.__4YDMHYUK {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  line-height: 22px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  text-transform: capitalize;
  margin-bottom: 24px;
  cursor: pointer;
}
.__4YDMHYUK .c7Khld0l {
  color: var(--btn-green-bg);
}
.__4YDMHYUK .RICedbiz {
  color: var(--blue);
}
.__4YDMHYUK .Ce_YJMV0 {
  color: var(--tip-text-color);
}
.hj5Ajc8y {
  margin-top: 24px;
  background-color: var(--module-bg);
  padding: 48px 58px;
}
.hj5Ajc8y ._jvXMlO8 {
  margin-bottom: 50px;
  font-size: 32px;
  font-family: var(--CommonMedium);
}
.hj5Ajc8y .Q5nqjHaZ {
  border: 1px solid var(--list-border-color);
  width: 100%;
  overflow: auto;
}
.hj5Ajc8y .Q5nqjHaZ ::-webkit-scrollbar {
  display: none;
}
.hj5Ajc8y .Q5nqjHaZ .uQy_nEmh {
  border-bottom: 1px solid var(--list-border-color);
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}
.hj5Ajc8y .Q5nqjHaZ .uQy_nEmh:last-child {
  border-bottom: none;
}
.hj5Ajc8y .Q5nqjHaZ .uQy_nEmh .uXUpgv4L {
  display: grid;
  grid-template-columns: 450px auto;
  width: 100%;
}
.hj5Ajc8y .Q5nqjHaZ .KY2AGJbn,
.hj5Ajc8y .Q5nqjHaZ .Kvu_71bu {
  display: flex;
  align-items: center;
  padding: 17px 13px 16px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  min-height: 57px;
  word-wrap: break-word;
}
.hj5Ajc8y .Q5nqjHaZ .KY2AGJbn {
  background: var(--main-bg-color);
}
.a2GrhpMQ {
  margin: 0 5px;
}
.omFNNMLT {
  width: 100%;
  height: 1px;
  margin: 24px 0;
  border-bottom: 1px solid var(--border-color);
}
.xs_6lhJB {
  margin: auto;
  background: var(--module-bg);
  padding: 24px;
}
.xs_6lhJB ._jvXMlO8 {
  font-size: 22px;
  color: var(--white);
}
.xs_6lhJB ._eTB_bA2 {
  margin-bottom: 24px;
}
.xs_6lhJB .qD_rYl9d {
  display: flex;
}
.xs_6lhJB .qD_rYl9d .MOPq1d2E {
  width: 150px;
  margin-right: 100px;
}
.xs_6lhJB .GvOJ3n7i {
  height: 48px;
  background-color: var(--table-heading-bg);
  display: flex;
  align-items: center;
  margin: 24px 0;
}
.xs_6lhJB .GvOJ3n7i .l0KwTQVU {
  width: 86px;
  margin: 0 105px 0 16px;
}
.xs_6lhJB .__5gSEC3LZ {
  display: flex;
}
.xs_6lhJB .__5gSEC3LZ .FXToJh1P {
  width: 86px;
  margin: 0 105px 0 16px;
  display: flex;
  align-items: center;
}
.xs_6lhJB .__5gSEC3LZ .ZrU49fej {
  width: 80%;
}
.xs_6lhJB .bERnKrVf {
  margin: 17px 0 9px;
}
.lNJQloQl {
  margin: auto;
  margin-top: 15px;
  background: var(--module-bg);
  padding: 24px;
}
.lNJQloQl ._jvXMlO8 {
  font-size: 22px;
  color: var(--white);
}
.lNJQloQl .aOlavRyS {
  padding: 24px;
  height: 600px;
  overflow: auto;
  background-color: var(--main-bg-color);
}
.BDCYUDYU .__1sWGAmvu {
  width: 130px;
  margin-right: 16px;
}
.gsbQr0QX {
  max-width: 1200px;
  margin: auto;
}
.gsbQr0QX .bqx0fP3a {
  display: flex;
  margin-top: 184px;
  position: relative;
}
.gsbQr0QX .bqx0fP3a .FZqGdeL2 {
  max-width: 1200px;
  background: var(--module-bg);
  border-radius: 2px;
  margin: auto;
  margin-top: 34px;
}
.gsbQr0QX .bqx0fP3a .FZqGdeL2 .__7uQZbpuV {
  padding: 25px 24px 32px;
  min-height: 600px;
  font-family: var(--CommonMedium);
  color: var(--white);
}
.gsbQr0QX .bqx0fP3a .FZqGdeL2 .__7uQZbpuV .CS4z83ts {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.gsbQr0QX .bqx0fP3a .FZqGdeL2 .__7uQZbpuV .CS4z83ts:hover {
  color: var(--main-text-hover-color);
}
.gsbQr0QX .bqx0fP3a .FZqGdeL2 .__7uQZbpuV .BecyvsoM {
  min-height: 600px;
  margin-bottom: 30px;
}
._pU4I_CK .ant-modal .ant-modal-content {
  padding-right: 28px;
}
._pU4I_CK .ant-modal .ant-modal-content .ant-modal-body {
  margin-bottom: 40px;
}
._pU4I_CK .ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid var(--list-border-color);
}
._pU4I_CK .ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid var(--list-border-color);
}
._pU4I_CK .ant-descriptions-bordered .ant-descriptions-item-label {
  border-right: 1px solid var(--list-border-color);
}
._pU4I_CK .ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 6px 9px 10px 12px;
  font-size: 12px;
}
._pU4I_CK .ant-descriptions-bordered .ant-descriptions-item-label {
  padding: 7px 0 12px 22px;
}
._pU4I_CK .__5_tIMVF2 ._ON8DIk2 {
  color: var(--white);
}
.EJE7V_uC {
  margin-top: 40px;
}
.scT_kkHu {
  height: 75px;
  background-color: var(--black);
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  padding-left: 24px;
}
.__7G0zo6e6 {
  background-color: var(--black);
  padding: 10px 24px 40px;
}
.__7G0zo6e6 .ant-picker {
  width: 100%;
  height: 40px;
  background: var(--app-bg);
  border: none;
  border-radius: 0px;
}
.__7G0zo6e6 .ant-picker-focused {
  background: var(--app-bg);
}
.__7G0zo6e6 .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.__7G0zo6e6 .ant-form-item-has-error :not(.ant-input-disabled).ant-input,
.__7G0zo6e6 .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper,
.__7G0zo6e6 .ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover,
.__7G0zo6e6 .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
  background: var(--app-bg);
}
.__7G0zo6e6 .ant-select-single .ant-select-selector .ant-select-selection-item,
.__7G0zo6e6 .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px;
  font-size: 14px;
}
.__7G0zo6e6 .ant-select {
  background: var(--app-bg);
}
.__7G0zo6e6 .ant-input {
  border-radius: 0px;
  background: var(--app-bg);
}
.__7G0zo6e6 .ant-alert-info {
  background: var(--alert-blue-bg);
  border-radius: 2px;
  border: 1px solid var(--alert-blue-border);
}
.__7G0zo6e6 .ant-alert-info .ant-alert-icon {
  color: var(--alert-blue-border);
}
.__7G0zo6e6 .__1hYaC33X {
  font-size: 16px;
  font-family: var(--CommonRegular);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
  margin: 20px 0;
}
.__7G0zo6e6 .sh3xLPqw {
  border-left: 1px solid var(--border-color3);
  padding-left: 16px;
}
.__7G0zo6e6 .UXpfgqMj,
.__7G0zo6e6 .__6o_McC74 {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.__7G0zo6e6 .Pb_zJ_OW,
.__7G0zo6e6 .LNHGKSva {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid var(--text-color);
  font-size: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: 5px;
}
.__7G0zo6e6 .__6o_McC74 {
  margin-bottom: 0px;
}
.__7G0zo6e6 .LNHGKSva {
  background-color: var(--main-color);
  border: none;
}
.__7G0zo6e6 .yLRRmZt5 {
  width: 100%;
  height: 40px;
}
._qeU9dHT {
  padding-bottom: 30px;
}
._qeU9dHT .xLtvdOc4 {
  border-left: 1px solid var(--border-color3);
  padding-left: 16px;
}
._qeU9dHT .Q5nqjHaZ {
  border: 1px solid var(--list-border-color);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
._qeU9dHT .Q5nqjHaZ ::-webkit-scrollbar {
  display: none;
}
._qeU9dHT .Q5nqjHaZ .uQy_nEmh {
  border-bottom: 1px solid var(--list-border-color);
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
}
._qeU9dHT .Q5nqjHaZ .uQy_nEmh:last-child {
  border-bottom: none;
}
._qeU9dHT .Q5nqjHaZ .uQy_nEmh .uXUpgv4L {
  display: grid;
  grid-template-columns: 120px auto;
  width: 100%;
}
._qeU9dHT .Q5nqjHaZ .KY2AGJbn,
._qeU9dHT .Q5nqjHaZ .Kvu_71bu {
  display: flex;
  align-items: center;
  padding: 17px 13px 16px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  min-height: 57px;
  word-wrap: break-word;
}
._qeU9dHT .Q5nqjHaZ .KY2AGJbn {
  background: var(--app-kyc-label);
  width: 120px;
  border-right: 1px solid var(--list-border-color);
}
._qeU9dHT .Q5nqjHaZ .Kvu_71bu {
  background-color: var(--app-bg);
}
._qeU9dHT .VeRT01I7 {
  margin-top: 40px;
}
._qeU9dHT .IhcSJUHZ {
  width: 128px;
  margin-right: 10px;
}
._qeU9dHT ._1di9D4t {
  display: block;
  flex: 1 1;
}
._qeU9dHT .ant-result-success .ant-result-icon > .anticon {
  color: var(--btn-green-bg);
}
._qeU9dHT .__4YDMHYUK {
  margin-top: 20px;
}
.Ivz_MJce {
  display: inline-block;
  width: 1px;
  height: 12px;
  background: #363846;
  margin: 0 8px;
}
.npd3TTRr {
  width: 80px;
  height: 4px;
  background-color: var(--main-content-hover-color1);
}
.Kjx_tYH7 {
  width: 80px;
  height: 4px;
  background-color: var(--main-color);
}
.AJwaiOWd {
  width: 100% !important;
  background: #f17e00 !important;
  margin-left: 0 !important;
}
.c5igBPec {
  width: 50% !important;
  color: var(--main-color) !important;
  border-color: var(--main-color) !important;
}
.kQOtBPz5 {
  margin-bottom: 16px;
  color: var(--white);
  font-size: 16px;
  line-height: 24px;
}
.KupyiZSP {
  background: #000000;
  padding: 1px;
}
.KupyiZSP .upWNPTh1 {
  border: 0.8px dashed rgba(255, 255, 255, 0.1);
  background: #181818;
}
.KupyiZSP .upWNPTh1 .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: transparent;
}
.KupyiZSP .upWNPTh1 .ant-upload.ant-upload-drag {
  background: transparent;
}
.obE0udQ9 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}
.RtkraRzm {
  background-color: var(--black);
  margin-top: 16px;
  padding: 8px 16px;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.__3OlcRshg {
  width: 100%;
  min-height: 670px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/images/image_bg@2x.webp");
}
.__3OlcRshg label {
  z-index: 0;
  position: relative;
  padding-left: 11px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
}
.__3OlcRshg label span {
  color: var(--input-red-bg);
}
.__3OlcRshg .ant-form {
  display: table;
  width: 100%;
}
.__3OlcRshg .ant-form-vertical .ant-form-item {
  margin-bottom: 24px;
  position: relative;
}
.__3OlcRshg .ant-form-item-control {
  height: 60px;
  background: var(--main-bg-color);
  position: relative;
}
.__3OlcRshg .ant-form-item-control-input {
  min-height: 60px;
}
.__3OlcRshg .ant-form-item {
  line-height: 60px;
}
.__3OlcRshg .ant-input {
  position: absolute;
  height: 60px;
  left: 0;
  top: 0;
  border-radius: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  padding-top: 35px;
  padding-bottom: 13px;
  background: transparent;
}
.__3OlcRshg .ant-input:hover {
  opacity: 0.35;
}
.__3OlcRshg .ant-input[disabled] {
  color: var(--white);
}
.__3OlcRshg .ant-form-item-explain-error {
  font-size: 12px;
  padding-top: 3px;
}
.__3OlcRshg .ant-form-item-margin-offset {
  margin-bottom: 0 !important;
}
.__3OlcRshg .dtsuXg_3 {
  height: calc(100vh - 52px);
  min-height: 670px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 {
  position: relative;
  max-width: 960px;
  width: 100%;
  height: 670px;
  margin: auto;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 50% 50%;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .wc6PUqjc {
  height: 670px;
  background: var(--white);
  border-radius: 4px 0px 0px 4px;
  padding: 48px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .wc6PUqjc .sO0AMgjm {
  width: 158px;
  height: 73px;
  margin-bottom: 24px;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .wc6PUqjc .DH5Mf938 {
  font-size: 18px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--black-text-color);
  line-height: 21px;
  margin-bottom: 41px;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .kwxGTGsD {
  height: 670px;
  background: var(--black);
  border-radius: 4px 0px 0px 4px;
  padding: 48px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .kwxGTGsD .sO0AMgjm {
  width: 158px;
  height: 73px;
  margin-bottom: 24px;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .kwxGTGsD .DH5Mf938 {
  font-size: 18px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 21px;
  margin-bottom: 41px;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .__75CwdUCC {
  height: 670px;
  background: var(--blue-level1);
  border-radius: 4px 0px 0px 4px;
  padding: 48px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .__75CwdUCC .sO0AMgjm {
  width: 158px;
  height: 73px;
  margin-bottom: 24px;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .__75CwdUCC .DH5Mf938 {
  font-size: 18px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 21px;
  margin-bottom: 41px;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .ugqs4wEI {
  position: absolute;
  right: 9.7%;
  /*136px*/
  position: relative;
  right: 0;
  border-radius: 0 4px 4px 0;
  background: var(--grey-level1);
  height: 670px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .ugqs4wEI .T8q_2QP2 {
  width: 80%;
  margin: 0 auto;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .ugqs4wEI .T8q_2QP2 .MQSQZR6X {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .ugqs4wEI .T8q_2QP2 .MQSQZR6X .__8eTRMsdB {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  cursor: pointer;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .ugqs4wEI .T8q_2QP2 .MQSQZR6X .__8eTRMsdB:hover {
  color: var(--main-color);
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .ugqs4wEI .T8q_2QP2 .PopRf3NN {
  color: var(--main-color);
  padding: 0 5px;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .ugqs4wEI .T8q_2QP2 .w7vvqRxf {
  opacity: 1;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .mW2HQ_JD {
  position: absolute;
  right: 9.7%;
  /*136px*/
  position: relative;
  right: 0;
  border-radius: 0 4px 4px 0;
  color: var(--black);
  background: var(--white);
  height: 670px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .mW2HQ_JD .T8q_2QP2 {
  width: 80%;
  background: var(--white);
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .mW2HQ_JD .T8q_2QP2 .DH5Mf938 {
  color: var(--black);
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .mW2HQ_JD .T8q_2QP2 label {
  position: relative;
  padding-left: 11px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--black-text-color) !important;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .mW2HQ_JD .T8q_2QP2 .w7vvqRxf {
  opacity: 1;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .mW2HQ_JD .T8q_2QP2 .ant-form {
  display: table;
  width: 100%;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .mW2HQ_JD .T8q_2QP2 .ant-btn-primary {
  border: none !important;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .mW2HQ_JD .T8q_2QP2 .ant-form-vertical .ant-form-item {
  margin-bottom: 32px;
  position: relative;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .mW2HQ_JD .T8q_2QP2 .ant-btn-primary[disabled] {
  color: var(--white) !important;
  background-color: var(--input-white-disabled-bg) !important;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .mW2HQ_JD .T8q_2QP2 .ant-btn-primary[disabled]:hover {
  color: var(--white) !important;
  background-color: var(--input-white-disabled-bg) !important;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .mW2HQ_JD .T8q_2QP2 .ant-form-item-control {
  height: 60px;
  background: var(--input-white-bg);
  position: relative;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .mW2HQ_JD .T8q_2QP2 .ant-form-item-control-input {
  min-height: 60px;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .mW2HQ_JD .T8q_2QP2 .ant-form-item-control-input-content {
  background: var(--input-white-bg);
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .mW2HQ_JD .T8q_2QP2 .ant-form-item {
  line-height: 60px;
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .mW2HQ_JD .T8q_2QP2 .ant-input {
  position: absolute;
  height: 60px;
  left: 0;
  top: 0;
  border-radius: 2px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--black) !important;
  padding-top: 35px;
  padding-bottom: 13px;
  background: transparent;
  -webkit-text-fill-color: var(--black);
  caret-color: var(--black);
}
.__3OlcRshg .dtsuXg_3 .__9vs6vUJ2 .mW2HQ_JD .T8q_2QP2 .ant-form-item-explain-error {
  font-size: 12px;
  padding-top: 3px;
}
.__3OlcRshg .i8hlGOgI {
  height: 64px;
  background: var(--module-bg);
  display: flex;
  align-items: center;
  padding: 0 24px 0;
}
.__3OlcRshg .QxrlEX1q {
  position: absolute;
  height: 60px;
  left: 12px;
  top: 10px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  background: transparent;
}
.__3OlcRshg .tL2Qkva1 .vjyNqMbi {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  margin-bottom: 32px;
}
.__3OlcRshg .tL2Qkva1 .MQSQZR6X {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.__3OlcRshg .tL2Qkva1 .MQSQZR6X .__8eTRMsdB {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  margin-top: 40px;
  margin-bottom: 16px;
  cursor: pointer;
}
.__3OlcRshg .tL2Qkva1 .MQSQZR6X .__8eTRMsdB:hover {
  color: var(--main-color);
}
.__3OlcRshg .DH5Mf938 {
  font-family: var(--CommonBold);
  color: var(--white);
  margin-bottom: 24px;
  font-size: 22px;
  font-weight: 500;
  line-height: 36px;
}
.__3OlcRshg .gCpCo9h7 {
  width: 100%;
  height: 56px;
  border-radius: 2px;
  font-size: 18px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
}
.__3OlcRshg .ant-btn[disabled] {
  background: var(--login-btn-disabled-bg) !important;
}
.__3OlcRshg .ant-btn[disabled]:hover {
  background: var(--login-btn-disabled-bg) !important;
}
.__3OlcRshg .ant-btn {
  width: 100%;
  height: 56px;
  border-radius: 2px;
  font-size: 18px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
}
.__3OlcRshg .__3_WekfcY {
  width: 100%;
  background-color: var(--input-bg);
  height: 60px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border: none;
}
.__3OlcRshg ._Nw2kMmj {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
}
.__3OlcRshg .__6_uiYYGG {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  cursor: pointer;
}
.__3OlcRshg .__6_uiYYGG:hover {
  color: var(--main-color);
}
.__3OlcRshg .rs_JaKkg,
.__3OlcRshg ._uqfaCBH {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  cursor: pointer;
}
.__3OlcRshg .rs_JaKkg:hover,
.__3OlcRshg ._uqfaCBH:hover {
  color: var(--main-color);
}
.__3OlcRshg ._uqfaCBH {
  color: var(--text-color);
}
.__3OlcRshg ._uqfaCBH:hover {
  color: var(--main-color);
}
.__3OlcRshg .e3_kOcQ4 {
  display: grid;
  grid-template-columns: auto 116px;
  grid-gap: 16px;
}
.__3OlcRshg .__6y_Cq4jw {
  display: flex;
  font-family: var(--CommonRegular);
  color: var(--text-color);
  font-size: 12px;
  margin-top: 16px;
}
.__3OlcRshg .mNZp4tmV {
  display: flex;
  align-items: center;
  background-color: var(--error-text-bg);
  padding: 7px 16px;
  margin-bottom: 20px;
  border: 1px solid var(--error-text-border);
  border-radius: 2px;
}
.__3OlcRshg .qQTxD5EH {
  font-family: var(--CommonRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--error-text);
  margin-left: 10px;
}
.__3OlcRshg .ZcDlXC8E {
  border: 1px solid var(--error-border);
}
.bUVzI3sg {
  margin-bottom: 0 !important;
}
.YesGpKMX .ant-form-item {
  margin-bottom: 24px;
}
.YesGpKMX .ant-form-item-explain-error {
  margin-top: -2px;
  font-size: 12px;
}
.__6OgnWVBq {
  padding: 60px 100px;
  background: var(--main-bg-color);
}
.__6OgnWVBq .DH5Mf938 {
  font-family: var(--CommonBold);
  color: var(--white);
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}
.__6OgnWVBq .ZchR0cH_ {
  font-family: var(--CommonRegular);
  color: var(--white);
  font-size: 14px;
}
.__6OgnWVBq .ZchR0cH_ .MnGbr_UF {
  font-family: var(--CommonBold);
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  text-decoration: underline;
}
.__6OgnWVBq .ZchR0cH_ .fcCsmDk2 {
  text-indent: 22px;
}
.__6OgnWVBq .ZchR0cH_ .__7GsYopIZ {
  text-indent: 42px;
}
@media (max-width: 768px) {
  .__6OgnWVBq {
    padding: 30px;
    background: var(--black);
  }
}
.tw64fINV ul.ant-dropdown-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}
.tw64fINV .ant-dropdown-menu-item {
  min-width: 112px;
  height: 46px;
  display: flex;
  align-items: center;
  font-family: var(--CommonMedium);
  color: var(--white);
}
.tw64fINV .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
}
.Lmu0P8pV .DydTEoyD {
  width: 24px;
  height: 2px;
  margin-top: 4px;
  background-color: transparent;
}
.Lmu0P8pV .DydTEoyD.wFVkFVBZ {
  background-color: var(--main-color);
}
.Lmu0P8pV .ant-tabs-nav {
  height: 58px;
  margin: 0;
}
.Lmu0P8pV .ant-tabs-nav .ant-tabs-tab {
  font-family: var(--CommonMedium);
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.JdCz8_wi {
  width: 262px !important;
  background-color: var(--input-bg) !important;
}
.PkBmoXsH {
  width: 110px !important;
  background-color: var(--input-bg) !important;
  height: 60px !important;
  font-size: 14px !important;
  font-family: var(--CommonRegular) !important;
  color: var(--main-color) !important;
  line-height: 22px !important;
  border: none !important;
}
.clrdZExw {
  top: -15px !important;
  z-index: 1 !important;
  color: var(--black-text-color) !important;
}
.ssY_3Ciq input:-webkit-autofill,
.ssY_3Ciq input:-webkit-autofill:hover,
.ssY_3Ciq input:-webkit-autofill:focus,
.ssY_3Ciq input:-webkit-autofill:active,
.ssY_3Ciq select:-webkit-autofill,
.ssY_3Ciq textarea:-webkit-autofill,
.ssY_3Ciq input:-internal-autofill-previewed,
.ssY_3Ciq input:-internal-autofill-selected {
  box-shadow: inset 0 0 0 1000px var(--input-bg) !important;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.__5mCEE3Rz {
  left: 16px;
  right: 16px;
  width: calc(100vw - 32px);
  margin-left: calc((1200px - 100vw + 36px) / 2);
}
.__5mCEE3Rz .__48Z9KFQa {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: minmax(592px, auto) 340px;
}
.__5mCEE3Rz .ant-tabs-nav-operations {
  display: none !important;
}
.__5mCEE3Rz .ant-radio-button-wrapper {
  border: none;
}
.__5mCEE3Rz .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  width: 0;
}
.__5mCEE3Rz .ant-input-disabled {
  background: transparent;
}
.__5mCEE3Rz .ant-tabs-tab-btn {
  font-size: 16px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 24px;
}
.__5mCEE3Rz .ant-tabs-tab {
  padding-bottom: 5px;
  border-bottom: 1px solid transparent;
}
.__5mCEE3Rz .ant-tabs-tab-active {
  border-bottom-color: var(--main-color);
}
.__5mCEE3Rz .ant-radio-group {
  width: 102%;
  padding: 0;
}
.__5mCEE3Rz .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.__5mCEE3Rz .ant-radio-button-wrapper {
  width: 49%;
  text-align: center;
}
.__5mCEE3Rz .ant-radio-button-wrapper:first-child {
  border-left: none !important;
}
.__5mCEE3Rz .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--main-color);
  border-radius: 4px;
  color: var(--white);
}
.__5mCEE3Rz .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--white);
}
.__5mCEE3Rz .ant-form-item-control-input {
  height: 40px;
  border-radius: 4px;
}
.__5mCEE3Rz .ant-input-group .ant-input {
  font-size: 16px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
  text-align: right;
}
.__5mCEE3Rz .ant-input-group-addon {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
}
.__5mCEE3Rz .ant-input-group-addon:first-child {
  padding-right: 0;
}
.__5mCEE3Rz .ant-input-group-addon:last-child {
  padding-left: 0;
}
.__5mCEE3Rz .ant-form-item {
  margin-bottom: 21px;
}
.__5mCEE3Rz .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
  background: var(--main-bg-color);
  border-radius: 4px;
  font-size: 16px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
  padding: 0 5px;
}
.__5mCEE3Rz .ant-select-single .ant-select-selector .ant-select-selection-item,
.__5mCEE3Rz .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px;
}
.K4snNRWb {
  background: var(--module-bg);
  min-height: 550px;
  max-height: 85vh;
  padding: 24px 24px 20px;
}
.K4snNRWb .lAMoeE34 {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.K4snNRWb .lAMoeE34 .ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 5px;
  top: 3px;
}
.K4snNRWb .lAMoeE34 .NKVwujBt {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.K4snNRWb .lAMoeE34 .n6sEvwOy {
  display: flex;
  align-items: center;
}
.K4snNRWb .lAMoeE34 .n6sEvwOy .u2Tmj4xd {
  margin: 0 10px;
  font-size: 16px;
  font-family: var(--CommonMedium);
  cursor: pointer;
}
.K4snNRWb .lAMoeE34 .n6sEvwOy .OeRuu4MT {
  color: var(--white-op3);
  cursor: pointer;
  font-size: 12px;
}
.K4snNRWb .lAMoeE34 .n6sEvwOy .__2hRpC3IZ {
  font-size: 16px;
  color: var(--btn-type1-bg);
}
.K4snNRWb .lAMoeE34 .n6sEvwOy .A_1v1Eoa {
  font-size: 16px;
  color: var(--main-color);
}
.K4snNRWb .TYeBkIne {
  width: 100%;
  min-height: 390px;
  overflow: hidden;
  height: 80%;
  position: relative;
}
.K4snNRWb .zzmwmHm1 {
  min-height: 390px;
  overflow: hidden;
  max-height: 80vh;
  height: 80%;
}
.K4snNRWb .WhMStD5q {
  color: var(--white);
}
.K4snNRWb .pH2SMw_A {
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 30px;
}
.K4snNRWb ._iVQIvFP,
.K4snNRWb .oxMUarOP {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  flex-shrink: 0;
}
.K4snNRWb .oxMUarOP {
  cursor: pointer;
  margin-bottom: 16px;
  font-size: 14px;
}
.K4snNRWb .oxMUarOP .ant-btn {
  min-width: 40px;
  height: 24px;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  border: none;
  margin-right: 6px;
  border-radius: 4px;
  padding: 0;
}
.K4snNRWb .oxMUarOP .ant-btn:last-child {
  margin-right: 0;
}
.K4snNRWb .oxMUarOP ._shifiAb {
  padding: 0 5px;
  background: var(--main-bg-color);
  color: var(--main-color) !important;
}
.K4snNRWb .boUAV4UO {
  cursor: pointer;
  color: var(--main-color);
}
.EI_YoR1Z {
  min-height: 550px;
  max-height: 85vh;
  background: var(--module-bg);
  padding: 21px 15px;
  overflow-y: auto;
}
.v44tVgN9 {
  height: 50px;
  background: var(--module-bg);
  padding: 21px 15px;
}
.nTIR0UDp {
  color: var(--main-color);
  cursor: pointer;
}
.__55T1PVOk {
  border: 1px solid var(--main-color) !important;
  color: var(--main-color) !important;
}
.__55T1PVOk:hover {
  color: var(--white) !important;
  transition: none;
}
.SVfhDHkj {
  background: var(--module-bg);
  padding: 24px;
  min-height: 550px;
  max-height: 85vh;
  overflow-y: auto;
}
.SVfhDHkj .bzLvE_lp {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 48px;
}
.SVfhDHkj .D14LRIMN {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
}
.SVfhDHkj ._BDMmsdD {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
}
.SVfhDHkj .KXBoJW1Q {
  width: 54px;
  height: 24px;
  border-radius: 4px;
  line-height: 24px;
  padding: 0;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  line-height: 22px;
  border: 1px solid var(--main-color);
  color: var(--main-color);
}
.SVfhDHkj .KXBoJW1Q:hover {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.SVfhDHkj .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.SVfhDHkj .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.SVfhDHkj .ant-form-item-control-input-content {
  background: transparent;
}
.SVfhDHkj .ant-input-group-wrapper {
  background: var(--main-bg-color);
}
.SVfhDHkj .ant-divider-horizontal {
  margin: 16px 0;
}
.SVfhDHkj .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border: none;
  box-shadow: none;
}
.SVfhDHkj .ant-form-item-explain.ant-form-item-explain-error {
  margin-top: -21px;
  margin-bottom: 5px;
}
.Jby7xT9U {
  background: var(--module-bg);
  padding: 16px 24px 24px;
  margin-top: 16px;
  grid-column-start: 1;
  grid-column-end: 3;
}
.Jby7xT9U .ant-table-thead > tr > th {
  padding: 6px;
  height: 57px;
  white-space: nowrap;
}
.Jby7xT9U .ant-table-tbody > tr > td {
  padding: 10px 6px;
  height: 57px;
}
.Jby7xT9U .ant-table-container table > thead > tr:first-child th:last-child.ant-table-cell-scrollbar {
  width: 0;
  padding: 0;
}
.Jby7xT9U .ant-table-cell {
  /* white-space:nowrap; 防止IE等浏览器不支持'max-content'属性 导致内容换行 */
}
.Jby7xT9U .ant-btn-primary {
  background: var(--main-color);
  color: var(--white);
  margin-right: 30px;
}
.Jby7xT9U .ant-btn:hover {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.__99N7ZpQn {
  font-size: 16px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
  margin-bottom: 20px;
}
.PCyj62p_ {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  display: flex;
  align-items: center;
}
.PCyj62p_ .pH2SMw_A {
  width: 20%;
}
.PCyj62p_ .__2m6czwUf {
  width: 35%;
  text-align: right;
  margin-right: 20px;
}
.PCyj62p_ .rkwr3f4C {
  text-align: right;
  flex: 1 1;
}
.kPG8yi4E {
  z-index: 1000;
  width: 100%;
  display: flex;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 4px;
}
.kPG8yi4E .pH2SMw_A {
  width: 20%;
}
.kPG8yi4E .__2m6czwUf {
  width: 35%;
  text-align: right;
  margin-right: 20px;
}
.kPG8yi4E .rkwr3f4C {
  flex: 1 1;
  text-align: right;
}
.kMv0_N_f {
  background: var(--orderbook-red-bg);
}
.QnVJBTOM {
  background: var(--orderbook-green-bg);
}
.QnVJBTOM,
.kMv0_N_f {
  height: 22px;
  margin-bottom: 4px;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
}
.iEYkGSB9 {
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--red-text-color);
  line-height: 30px;
  margin: 16px 0;
}
.d3JQuQ7k {
  color: var(--red-text-color);
}
.eDC7COyv {
  color: var(--green-text-color);
}
.vdzxP00n {
  color: var(--green-text-color) !important;
}
._LV50jaU {
  color: var(--red-text-color) !important;
}
.b8aJrl19 {
  color: var(--white) !important;
}
.ef8KTXdz {
  height: 32px;
  background: var(--main-bg-color);
  border-radius: 4px;
  margin-bottom: 8px;
}
.D4Whlfi_ {
  width: 50%;
  height: 24px;
  background: var(--main-color);
  border-radius: 4px;
}
.QY9cVWbL {
  display: flex;
  justify-content: space-between;
  flex: 0 1 auto;
  margin-bottom: 32px;
}
.CQBQ_YFV {
  border-radius: 4px;
  border: 1px solid var(--main-bg-color);
  margin-right: 8px;
  color: var(--text-color);
  padding: 0 10px;
  flex-grow: 1;
}
@media (max-width: 1200px) {
  .__5mCEE3Rz {
    width: 100% !important;
    margin-left: 0;
  }
  .__48Z9KFQa {
    grid-template-columns: 2fr 1fr 1fr !important;
  }
}
.__4zVYYa41 .ant-form-item-control-input .ant-input {
  background: var(--module-bg);
}
.__4zVYYa41 .ant-form-item-control-input-content {
  background: transparent;
}
.__4zVYYa41 .ant-modal-body {
  margin-bottom: 48px;
}
.__4zVYYa41 .ant-modal-footer {
  display: flex;
  border-top: none;
  justify-content: flex-end;
  padding-bottom: 4px;
}
.__4zVYYa41 .ant-modal-footer button:first-child {
  display: inline-block;
  margin-left: 16px;
  order: 1;
}
.__4zVYYa41 .ant-modal-footer button:first-child:hover {
  border-color: var(--main-color);
  color: var(--main-color);
}
.__4zVYYa41 .ant-modal-footer .ant-btn-primary {
  background: var(--main-color);
}
.__4zVYYa41 .ant-modal-footer .ant-btn {
  cursor: pointer;
  width: 108px;
  background: transparent;
  border: 1px solid var(--btn-border);
  height: 40px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
  border-radius: 4px;
}
.__4zVYYa41 .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  width: 108px;
}
.__4zVYYa41 .WNYsNym9 :not(.gZ8107fd).yh0cjIUe,
.__4zVYYa41 .WNYsNym9 :not(.QAycPKgi).Rtdo0uuF,
.__4zVYYa41 .WNYsNym9 :not(.gZ8107fd).yh0cjIUe:hover,
.__4zVYYa41 .WNYsNym9 :not(.QAycPKgi).Rtdo0uuF:hover {
  background: var(--module-bg);
}
.__1i0b45PG .ant-drawer-body {
  background-color: var(--module-bg) !important;
  overflow: hidden;
}
.__1i0b45PG .ant-tabs-tab-btn {
  font-size: 14px;
  font-family: var(--CommonBold);
  color: var(--white);
}
.__1i0b45PG .ant-tabs-tab {
  padding-bottom: 2px;
  border-bottom: 2px solid transparent;
  padding: 0;
}
.__1i0b45PG .ant-tabs-tab-active {
  border-bottom-color: var(--main-color);
}
.__1i0b45PG .ant-tabs-nav-wrap {
  height: 32px;
}
.__1i0b45PG .ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}
.__1i0b45PG .ant-table-tbody > tr > td {
  border-bottom: none;
}
.__1i0b45PG .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--white);
}
.__1i0b45PG .H83a1KTj {
  background-color: var(--main-bg-color);
}
.__1i0b45PG .__67mZYHdF {
  margin: 20px 0;
  font-weight: 700;
  font-size: 18px;
}
.__1i0b45PG .__67mZYHdF .tQWNkzXU {
  cursor: pointer;
  margin-right: 15px;
  color: var(--white-op1);
}
.__1i0b45PG .__67mZYHdF .ph2YpMUG {
  cursor: pointer;
  margin-right: 15px;
}
.__1i0b45PG .AL6XEKKT {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -24px;
  padding: 0 24px;
  border-top: 1px solid var(--border-color4);
  border-bottom: 1px solid var(--border-color4);
}
.__1i0b45PG .AL6XEKKT ._6qYjgeC {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: var(--CommonBold);
}
.__1i0b45PG .AL6XEKKT ._6qYjgeC .I2OQeQI7 {
  width: 3px;
  height: 3px;
  background: var(--main-color);
  border-radius: 50%;
}
.__1i0b45PG .AL6XEKKT ._6qYjgeC .Z0gc1clc {
  margin: 0 4px;
}
.__1i0b45PG .AL6XEKKT ._6qYjgeC .aT3Ez5TM {
  cursor: pointer;
  color: var(--main-color);
}
.__1i0b45PG .__1HCDuvtC {
  margin-top: 20px;
}
.__1i0b45PG .__1HCDuvtC .ant-table-thead > tr > th {
  background-color: var(--module-bg);
  border-bottom: none;
  box-shadow: none;
}
.__1i0b45PG .__1HCDuvtC .ant-table-tbody > tr > td {
  border-bottom: none;
}
.__1i0b45PG .__1HCDuvtC .ant-table-cell {
  font-size: 12px;
  padding: 10px 0;
}
.__1i0b45PG .__1HCDuvtC .ant-table-column-sorter-up,
.__1i0b45PG .__1HCDuvtC .ant-table-column-sorter-down {
  font-size: 7px;
}
.__1i0b45PG .__1HCDuvtC .jz_Qgr4h {
  display: flex;
  align-items: center;
}
.__1i0b45PG .__1HCDuvtC .jz_Qgr4h .tbEuUZGF {
  margin-left: 10px;
}
.__1i0b45PG .__1HCDuvtC .jz_Qgr4h .tbEuUZGF .UMvKsDMu {
  white-space: nowrap;
  font-family: var(--CommonMedium);
}
.__1i0b45PG .__1HCDuvtC .jz_Qgr4h .tbEuUZGF .UMvKsDMu .lTtyXuQx {
  font-size: 14px;
  font-family: var(--CommonBold);
}
.__1i0b45PG .__1HCDuvtC .pH2SMw_A {
  font-size: 14px;
  font-family: var(--CommonBold);
}
.__1i0b45PG .__1HCDuvtC .A0WeJSto {
  background-color: var(--btn-green-bg);
  color: var(--white);
  font-family: var(--CommonMedium);
  border-radius: 2px;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  min-width: 60px;
  text-align: center;
}
.__1i0b45PG .__1HCDuvtC .p8vw3pLv {
  background-color: var(--btn-red-bg);
  font-family: var(--CommonMedium);
  color: var(--white);
  border-radius: 2px;
  line-height: 28px;
  height: 28px;
  display: inline-block;
  min-width: 60px;
  text-align: center;
}
.__1i0b45PG .ubTkJvVY {
  margin-top: 20px;
}
.__1i0b45PG .ubTkJvVY .pOVvvjEg {
  margin-bottom: 20px;
  font-size: 12px;
  font-family: var(--CommonBold);
}
.__1i0b45PG .ubTkJvVY .npATjmrd {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px 7px;
  margin-bottom: 16px;
  width: 156px;
  height: 96px;
  border: 1px solid;
  border-image: linear-gradient(135deg, var(--border-color1), var(--module-bg)) 1;
  color: var(--white-op3);
  cursor: pointer;
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde .UMvKsDMu {
  white-space: nowrap;
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde .UMvKsDMu .__13SLjrbl {
  font-size: 16px;
  font-family: var(--CommonBold);
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde .UMvKsDMu .CtyYG8SI {
  font-size: 12px;
  font-family: var(--CommonMedium);
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde .bwuJMMqV {
  font-family: var(--CommonBold);
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde .l3i606TR {
  font-size: 12px;
  font-family: var(--CommonMedium);
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde .l3i606TR .A0WeJSto {
  color: var(--green-text-color);
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde .l3i606TR .p8vw3pLv {
  color: var(--red-text-color);
}
.__1i0b45PG .ubTkJvVY .npATjmrd .J3zSJjde .N46tFKA7 {
  color: var(--main-color);
}
.__1i0b45PG .ubTkJvVY .__2dcouDno {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.__1i0b45PG .ubTkJvVY .__2dcouDno .sB98l3W4 {
  margin-top: 25px;
  cursor: pointer;
  font-family: var(--CommonMedium);
  color: var(--main-color);
}
.__1HCDuvtC {
  margin-top: 20px;
}
.__1HCDuvtC table:hover,
.__1HCDuvtC tr:hover,
.__1HCDuvtC thead:hover {
  background: var(--module-bg) !important;
}
.__1HCDuvtC .ant-table-tbody > tr.ant-table-row:hover > td {
  background: none !important;
}
.__1HCDuvtC .ant-table-thead > tr > th {
  background-color: var(--module-bg);
  border-bottom: none;
  box-shadow: none;
}
.__1HCDuvtC .ant-table-tbody > tr > td {
  border-bottom: none;
}
.__1HCDuvtC .ant-table-cell {
  font-size: 12px;
  padding: 10px 0;
}
.__1HCDuvtC .ant-table-column-sorter-up,
.__1HCDuvtC .ant-table-column-sorter-down {
  font-size: 7px;
}
.__1HCDuvtC .jz_Qgr4h {
  display: flex;
  align-items: center;
}
.__1HCDuvtC .jz_Qgr4h .tbEuUZGF {
  margin-left: 10px;
}
.__1HCDuvtC .jz_Qgr4h .tbEuUZGF .UMvKsDMu {
  white-space: nowrap;
  font-family: var(--CommonMedium);
}
.__1HCDuvtC .jz_Qgr4h .tbEuUZGF .UMvKsDMu .lTtyXuQx {
  font-size: 14px;
  font-family: var(--CommonBold);
}
.__1HCDuvtC .pH2SMw_A {
  font-size: 14px;
  font-family: var(--CommonBold);
}
.__1HCDuvtC .A0WeJSto {
  background-color: var(--btn-green-bg);
  color: var(--white);
  font-family: var(--CommonMedium);
  border-radius: 2px;
  height: 28px;
  line-height: 28px;
  display: inline-block;
  min-width: 60px;
  text-align: center;
}
.__1HCDuvtC .p8vw3pLv {
  background-color: var(--btn-red-bg);
  font-family: var(--CommonMedium);
  color: var(--white);
  border-radius: 2px;
  line-height: 28px;
  height: 28px;
  display: inline-block;
  min-width: 60px;
  text-align: center;
}
.Hpoi36cA {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60%;
}
.Pjznub4T .ant-drawer-body {
  background-color: var(--module-bg) !important;
  overflow: hidden;
}
.Pjznub4T .ant-tabs-tab-btn {
  font-size: 14px;
  font-family: var(--CommonBold);
  color: var(--white);
}
.Pjznub4T .ant-tabs-tab {
  padding-bottom: 2px;
  border-bottom: 2px solid transparent;
  padding: 0;
}
.Pjznub4T .ant-tabs-tab-active {
  border-bottom-color: var(--main-color);
}
.Pjznub4T .ant-tabs-nav-wrap {
  height: 32px;
}
.Pjznub4T .ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}
.Pjznub4T .ant-table-tbody > tr > td {
  border-bottom: none;
}
.Pjznub4T .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--white);
}
.Pjznub4T .H83a1KTj {
  background-color: var(--main-bg-color);
}
.Pjznub4T .__1HCDuvtC .ant-table-thead > tr > th {
  background-color: var(--module-bg);
  border-bottom: none;
  box-shadow: none;
}
.Pjznub4T .__1HCDuvtC .ant-table-tbody > tr > td {
  border-bottom: none;
}
.Pjznub4T .__1HCDuvtC .ant-table-cell {
  font-size: 12px;
  padding: 10px 0;
}
.Pjznub4T .__1HCDuvtC .ant-table-column-sorter-up,
.Pjznub4T .__1HCDuvtC .ant-table-column-sorter-down {
  font-size: 7px;
}
.Pjznub4T .__1HCDuvtC .__2hRpC3IZ {
  margin-right: 8px;
  font-size: 16px;
  color: var(--btn-type1-bg);
}
.Pjznub4T .__1HCDuvtC .A_1v1Eoa {
  margin-right: 8px;
  font-size: 16px;
  color: var(--main-color);
}
.Pjznub4T .__67mZYHdF {
  margin: 20px 0;
  font-weight: 700;
  font-size: 18px;
}
.Pjznub4T .__67mZYHdF .tQWNkzXU {
  cursor: pointer;
  margin-right: 15px;
  color: var(--white-op1);
}
.Pjznub4T .__67mZYHdF .ph2YpMUG {
  cursor: pointer;
  margin-right: 15px;
}
.Pjznub4T .AL6XEKKT {
  margin: 0 -24px;
  padding: 0 24px;
  border-bottom: 1px solid var(--border-color4);
}
.Pjznub4T .hdjLab5a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -24px;
  padding: 0 24px 20px;
  border-bottom: 1px solid var(--border-color4);
}
.Pjznub4T .hdjLab5a .R6oWT5_C {
  font-family: var(--CommonBold);
  font-size: 20px;
}
.Pjznub4T .hdjLab5a .__8CP1PwAN {
  font-size: 16px;
  font-family: var(--CommonMedium);
  color: var(--main-color);
  cursor: pointer;
}
.Pjznub4T .HsPYjtIN {
  overflow-y: hidden;
  text-align: center;
  height: 95%;
}
.Pjznub4T .HsPYjtIN .__3Wq1gNUB {
  height: 90%;
  overflow-y: scroll;
  padding-bottom: 30px;
  z-index: 9999;
}
.Pjznub4T .HsPYjtIN .__3Wq1gNUB .__50QGHQtf {
  margin-top: 30px;
  width: 200px;
}
.Pjznub4T .HsPYjtIN .qD0sHY3Q {
  height: 80px;
  display: flex;
  background-color: var(--module-bg);
  z-index: 99;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--border-color4);
  font-size: 16px;
}
.Pjznub4T .HsPYjtIN .qD0sHY3Q .__2hRpC3IZ {
  font-size: 16px;
  color: var(--btn-type1-bg);
}
.Pjznub4T .HsPYjtIN .qD0sHY3Q .A_1v1Eoa {
  font-size: 16px;
  color: var(--main-color);
}
.Pjznub4T .HsPYjtIN .qD0sHY3Q .nKTyZj8E {
  cursor: pointer;
}
.Pjznub4T .HsPYjtIN .qD0sHY3Q .nKTyZj8E .SwGcHkQz {
  font-family: var(--CommonBold);
  color: var(--white);
  margin-left: 10px;
}
.Pjznub4T .HsPYjtIN .qD0sHY3Q .WjU25GcV {
  cursor: pointer;
  font-family: var(--CommonMedium);
  color: var(--white-op3);
}
.Pjznub4T .HsPYjtIN .qD0sHY3Q .WjU25GcV:hover {
  color: var(--white);
}
.Pjznub4T .HsPYjtIN .qD0sHY3Q .bwrAdxlV {
  cursor: not-allowed;
  font-family: var(--CommonMedium);
  color: var(--white-op5);
}
.__7U9U7dZy {
  background: var(--main-bg-color);
  border: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.__7U9U7dZy td {
  padding: 16px;
}
.__7U9U7dZy .__2hRpC3IZ {
  font-size: 16px;
  color: var(--btn-type1-bg);
}
.__7U9U7dZy .A_1v1Eoa {
  font-size: 16px;
  color: var(--main-color);
}
.__7U9U7dZy .jz_Qgr4h {
  display: flex;
  align-items: center;
}
.__7U9U7dZy .jz_Qgr4h .tbEuUZGF {
  margin-left: 10px;
}
.__7U9U7dZy .jz_Qgr4h .tbEuUZGF .UMvKsDMu {
  white-space: nowrap;
  font-family: var(--CommonMedium);
}
.__7U9U7dZy .jz_Qgr4h .tbEuUZGF .UMvKsDMu .lTtyXuQx {
  font-size: 14px;
  font-family: var(--CommonBold);
}
.gEr__WOi {
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: center;
  align-items: center;
  cursor: -webkit-grab;
  cursor: grab;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.__5PynW4ZJ {
  max-width: 1200px;
  background: var(--module-bg);
  border-radius: 2px;
  margin: auto;
  margin-top: 34px;
}
.__5PynW4ZJ .TjKhD79o {
  display: flex;
  justify-content: space-between;
}
.__5PynW4ZJ .TjKhD79o .lSPhFOTU {
  color: var(--white);
  width: 170px;
  height: 48px;
  font-size: 14px;
  text-align: center;
  padding: 0 !important;
  border: none;
}
.__5PynW4ZJ .yHYEQg98 {
  display: flex;
  margin-top: 184px;
  position: relative;
}
.__5PynW4ZJ .yHYEQg98 .ant-table-thead > tr > th {
  padding: 6px 14px;
  height: 57px;
}
.__5PynW4ZJ .yHYEQg98 .ant-table-tbody > tr > td {
  padding: 16px 12px;
  height: 57px;
}
.__5PynW4ZJ .yHYEQg98 .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent;
  pointer-events: none;
}
.__5PynW4ZJ .yHYEQg98 .ant-table-thead > tr > th,
.__5PynW4ZJ .yHYEQg98 .ant-table-sticky-holder {
  background-color: var(--table-heading-bg);
}
.__5PynW4ZJ .__937GqTEu {
  height: 64px;
}
.__5PynW4ZJ .__937GqTEu .ant-tabs-tab {
  margin: 0;
  width: 180px;
}
.__5PynW4ZJ .__937GqTEu .ant-tabs-tab-btn {
  padding-top: 5px;
  width: 100%;
  height: 64px;
  font-size: 18px;
  font-family: var(--CommonRegular);
  text-align: center;
  color: var(--text-color);
}
.__5PynW4ZJ .__937GqTEu .ant-tabs-tab-btn:hover {
  color: var(--white);
}
.__5PynW4ZJ .__937GqTEu .ant-tabs-tab-active {
  background-color: var(--tab-active-bg);
}
.__5PynW4ZJ .__937GqTEu .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--white);
}
.__5PynW4ZJ .AMD3V2IE {
  padding: 25px 24px 32px;
  min-height: 600px;
  background: var(--tab-active-bg);
}
.__5PynW4ZJ .AMD3V2IE .TWfqAIOj {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.__5PynW4ZJ .AMD3V2IE .TWfqAIOj:hover {
  color: var(--main-text-hover-color);
}
.__5PynW4ZJ .AMD3V2IE .__2B8V2yJy {
  margin-bottom: 30px;
}
.__5PynW4ZJ .AMD3V2IE .NJxP_PW0 .ant-radio-group {
  background: var(--table-heading-bg);
  border-radius: 15px;
}
.__5PynW4ZJ .AMD3V2IE .NJxP_PW0 .ant-radio-group .ant-radio-button-wrapper {
  width: 142px;
  text-align: center;
  border: none;
  transition: unset;
}
.__5PynW4ZJ .AMD3V2IE .NJxP_PW0 .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.__5PynW4ZJ .AMD3V2IE .NJxP_PW0 .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--main-color);
  border-radius: 15px;
  color: var(--white);
}
.__5PynW4ZJ .AMD3V2IE .NJxP_PW0 .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--white);
}
.__5PynW4ZJ .AMD3V2IE .NJxP_PW0 .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: unset;
}
.__5PynW4ZJ .AMD3V2IE .smvVQFc8 {
  width: 80px;
  height: 28px;
}
.__5PynW4ZJ .AMD3V2IE .jVJ5xfL5 {
  width: 80px;
  height: 28px;
  color: var(--text-color-second) !important;
  border: 1px solid var(--btn-border) !important;
}
.__5PynW4ZJ .AMD3V2IE .jVJ5xfL5:hover {
  background-color: transparent !important;
  color: var(--main-color) !important;
  border: 1px solid var(--main-color) !important;
}
.__5PynW4ZJ .AMD3V2IE .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.__5PynW4ZJ .AMD3V2IE .ant-btn-primary {
  background: var(--main-color);
  color: var(--white);
  margin-right: 30px;
}
.__5PynW4ZJ .AMD3V2IE .ant-btn:hover {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.__5PynW4ZJ .Ag1sum2d {
  color: var(--main-color);
  cursor: pointer;
}
._weWCbHu .JrZ60ka0 .Xa3D_7gC {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
._weWCbHu .JrZ60ka0 .Xa3D_7gC .guBbAHO1 {
  color: var(--text-color-second);
}
._weWCbHu .JrZ60ka0 .Xa3D_7gC .ZO4p4Pz7 {
  color: var(--white);
}
._weWCbHu .__2B8V2yJy .ant-table-thead > tr > th {
  padding: 6px 14px;
  height: 48px;
  background-color: var(--table-heading-bg);
  border-bottom: none;
}
._weWCbHu .__2B8V2yJy .ant-table-tbody > tr > td {
  padding: 16px 12px;
  height: 57px;
  color: var(--white);
}
._weWCbHu .__2B8V2yJy .ant-table {
  background-color: var(--main-bg-color);
}
._weWCbHu .__2B8V2yJy .ant-table-content {
  min-height: 0px;
}
.__6SmQOOgN .ant-form-item-control-input .ant-input {
  background: var(--module-bg);
}
.__6SmQOOgN .ant-form-item-control-input-content {
  background: transparent;
}
.__6SmQOOgN .ant-modal-body {
  margin-bottom: 48px;
}
.__6SmQOOgN .ant-modal-footer {
  display: flex;
  border-top: none;
  justify-content: flex-end;
  padding-bottom: 4px;
}
.__6SmQOOgN .ant-modal-footer button:first-child {
  display: inline-block;
  margin-left: 16px;
  order: 1;
}
.__6SmQOOgN .ant-modal-footer button:first-child:hover {
  border-color: var(--main-color);
  color: var(--main-color);
}
.__6SmQOOgN .ant-modal-footer .ant-btn-primary {
  background: var(--main-color);
}
.__6SmQOOgN .ant-modal-footer .ant-btn {
  cursor: pointer;
  width: 108px;
  background: transparent;
  border: 1px solid var(--btn-border);
  height: 40px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
  border-radius: 4px;
  border: none;
}
.__6SmQOOgN .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  width: 108px;
}
.__6SmQOOgN .XDex7XzY :not(.xuzQyBaC).zy9EW1lx,
.__6SmQOOgN .XDex7XzY :not(.__7tzlW_1O).aby6Nuw8,
.__6SmQOOgN .XDex7XzY :not(.xuzQyBaC).zy9EW1lx:hover,
.__6SmQOOgN .XDex7XzY :not(.__7tzlW_1O).aby6Nuw8:hover {
  background: var(--module-bg);
}
._LafabGV {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}
._LafabGV .ant-radio-group {
  background: var(--table-heading-bg);
  border-radius: 15px;
}
._LafabGV .ant-radio-group .ant-radio-button-wrapper {
  width: 142px;
  height: 32px;
  text-align: center;
  border: none;
  transition: unset;
}
._LafabGV .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
._LafabGV .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--main-color);
  border-radius: 15px;
  color: var(--white);
}
._LafabGV .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--white);
}
._LafabGV .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: unset;
}
.ant-table-tbody > tr > td {
  padding: 16px 10px !important;
}
tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td {
  background: var(--table-expanded-bg);
}
.ant-table-expanded-row .ant-table-expanded-row-level-1 {
  background: transparent !important;
}
.__2v0bEp0n {
  display: flex;
  justify-content: space-between;
  margin: 0;
  line-height: 22px;
}
.__2v0bEp0n .__2IY8rET9 {
  flex: 0 0 40%;
}
.__2v0bEp0n .cf2TOH_w {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.__2v0bEp0n .cf2TOH_w .__05O8Dfee {
  justify-content: flex-end;
}
.__2v0bEp0n ._0r2K3dD {
  flex: 1 1 auto;
}
.__2v0bEp0n .__05O8Dfee {
  display: flex;
}
.__2v0bEp0n .__05O8Dfee .__4NaxxPCd {
  flex: 0 0 120px;
  margin-right: 26px;
}
.__2v0bEp0n .__05O8Dfee .h3o1htRV {
  flex: 0 0 150px;
  text-align: right;
}
.ncTOz22r {
  cursor: pointer;
  width: 70px;
  display: flex;
  justify-content: flex-end;
  color: var(--main-color);
}
.ncTOz22r .tngAqTPu {
  margin-left: 8px;
  margin-top: 9px;
  width: 6px;
  height: 4px;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.OpLt05Tw {
  background: var(--module-bg);
  padding: 32px 40px 40px;
  max-width: var(--max-width);
  margin: auto;
  border-radius: 2px;
}
.w9Q2Gh1Q {
  margin-left: 15px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
}
.w9Q2Gh1Q:hover {
  color: var(--main-color);
}
.PSRqDMG3 {
  max-width: 1200px;
  border-radius: 2px;
  margin: auto;
  margin-top: 34px;
}
.PSRqDMG3 .FNKXr65C {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PSRqDMG3 .FNKXr65C .EpvF6O3L .WK_hc_ez {
  color: var(--white);
  width: 140px;
  height: 48px;
  margin-left: 15px;
  font-size: 14px;
  text-align: center;
  padding: 0 !important;
  border: none;
}
.PSRqDMG3 .YGBJOCza {
  position: relative;
  max-width: var(--max-width);
  width: 100%;
  height: 120px;
  margin: auto auto 16px;
  display: flex;
  justify-content: space-between;
}
.PSRqDMG3 .YGBJOCza .bew54EQy {
  background: var(--module-bg);
  text-align: center;
  padding: 0 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .jeIqKYob {
  width: 50%;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .fVMnlh7X {
  width: 1px;
  height: 50px;
  background: var(--main-bg-color);
  margin-left: 10px;
  margin-right: 20px;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .DQ_7pYyj {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .C_mGvpi1 {
  margin-right: 10px;
  font-family: var(--CommonMedium);
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .C_mGvpi1 .yxyi5W8f {
  display: flex;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .C_mGvpi1 .yxyi5W8f .O5sldh0T {
  color: var(--text-color);
  text-align: start;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .C_mGvpi1 .yxyi5W8f .R4mZP2PP {
  color: var(--text-color);
  text-align: start;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .C_mGvpi1 .yxyi5W8f .yeBGuzBJ {
  max-width: 100px;
  margin-left: 10px;
  word-wrap: break-word;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .FMIcExQA {
  width: 55px;
  height: 36px;
  background-color: var(--main-bg-color);
  border-radius: 9px;
  cursor: pointer;
  font-size: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .FMIcExQA .__1r1E1eQF {
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .FMIcExQA .F98iE2zE {
  font-size: 12px;
  display: flex;
  align-items: center;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .IHCb5ysN {
  display: flex;
  align-items: center;
  padding-left: 6px;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .IHCb5ysN .Wsn9kqQx {
  flex-shrink: 0;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .IHCb5ysN .VpLJlHGv {
  width: 440px;
  margin-left: 10px;
  margin-right: 12px;
  text-align: left;
  font-size: 12px;
  color: var(--text-color);
  line-height: 18px;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .IHCb5ysN .VpLJlHGv > p {
  display: flex;
  align-items: baseline;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .IHCb5ysN .VpLJlHGv > p .jt0ttWgj {
  flex-shrink: 0;
  display: inline-block;
  width: 3px;
  height: 3px;
  background: var(--main-color);
  border-radius: 50%;
  margin-right: 4px;
}
.PSRqDMG3 .YGBJOCza .bew54EQy .IHCb5ysN .__8l6nsZQO {
  height: 40px;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--CommonMedium);
  padding: 13px 20px;
  line-height: 14px;
  margin-right: 24px;
}
.PSRqDMG3 .YGBJOCza .__9mw54Pu_,
.PSRqDMG3 .YGBJOCza .sym5H_jA {
  background: var(--module-bg);
  text-align: center;
}
.PSRqDMG3 .YGBJOCza .__9mw54Pu_ .cDy4UHPN,
.PSRqDMG3 .YGBJOCza .sym5H_jA .cDy4UHPN {
  display: flex;
  align-items: center;
  justify-content: center;
}
.PSRqDMG3 .YGBJOCza .sym5H_jA {
  cursor: pointer;
}
.PSRqDMG3 .YGBJOCza .jeIqKYob {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.PSRqDMG3 .YGBJOCza .jeIqKYob .UOdXgbkD {
  font-size: 36px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 30px;
  margin-bottom: 12px;
  word-wrap: break-word;
}
.PSRqDMG3 .YGBJOCza .jeIqKYob .KqMCRNqv {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  position: relative;
}
.PSRqDMG3 .YGBJOCza .jeIqKYob .KqMCRNqv .__2x5E8IqQ {
  position: absolute;
  display: inline-block;
  margin-left: 30px;
  font-weight: 700;
}
.PSRqDMG3 .YGBJOCza .jeIqKYob .mgwTZP4N:hover {
  text-decoration: underline;
}
.PSRqDMG3 .YGBJOCza .ILSUoAY9 {
  background: var(--module-bg);
  padding: 32px 20px 0px;
}
.PSRqDMG3 .YGBJOCza .ILSUoAY9 .XySva7Zz {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.PSRqDMG3 .YGBJOCza .ILSUoAY9 .XySva7Zz .SwdxmKpU {
  font-size: 14px;
}
.PSRqDMG3 .YGBJOCza .ILSUoAY9 .XySva7Zz .NS_CRuXy {
  font-size: 22px;
  font-weight: bold;
}
.PSRqDMG3 .YGBJOCza .ILSUoAY9 .U9ScFGmN {
  margin: 16px 0;
  width: 100%;
  height: 1px;
  border-bottom: 1px solid var(--border-color);
}
.PSRqDMG3 .YGBJOCza .ILSUoAY9 .__7oIR0Uca {
  display: flex;
  justify-content: center;
  font-family: var(--CommonBold);
}
.PSRqDMG3 .rXofSjEh {
  position: relative;
  width: 100%;
  background: var(--tab-active-bg);
  margin-bottom: 40px;
}
.PSRqDMG3 .rXofSjEh .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.PSRqDMG3 .rXofSjEh .ant-btn-primary {
  background: var(--main-color);
  color: var(--white);
  margin-right: 30px;
}
.PSRqDMG3 .rXofSjEh .ant-btn:hover,
.PSRqDMG3 .rXofSjEh .ant-btn:active {
  background: var(--main-color);
  color: var(--white);
}
.PSRqDMG3 .rXofSjEh .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent;
  pointer-events: none;
}
.PSRqDMG3 .rXofSjEh .ant-table-thead > tr > th {
  background-color: var(--table-heading-bg);
}
.PSRqDMG3 .rXofSjEh .Bm86seQ_ {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.PSRqDMG3 .rXofSjEh .J4UD4WtE {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
}
.PSRqDMG3 .rXofSjEh .J4UD4WtE .FQAmsL1H {
  position: relative;
}
.PSRqDMG3 .rXofSjEh .J4UD4WtE .FQAmsL1H .Oo_KGWeo {
  z-index: 999;
  position: absolute;
  left: 2px;
  top: 30px;
  width: 50px;
  height: 14px;
  border-radius: 100px;
}
.PSRqDMG3 .rXofSjEh .J4UD4WtE .FQAmsL1H .Oo_KGWeo.Gs1YAN3z {
  background: var(--blue-level1);
}
.PSRqDMG3 .rXofSjEh .J4UD4WtE .FQAmsL1H .Oo_KGWeo.__6f9XlQvb {
  background: var(--purple);
}
.PSRqDMG3 .rXofSjEh .J4UD4WtE .FQAmsL1H .Oo_KGWeo > span {
  color: var(--white);
  font-size: 12px;
  transform: scale(0.7);
  font-family: var(--CommonBold);
  margin-bottom: 9px;
}
.PSRqDMG3 .rXofSjEh .FZ3BLEmf {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.PSRqDMG3 .rXofSjEh .FZ3BLEmf .IqUVIh9D {
  font-family: var(--CommonRegular);
  font-size: 16px;
  font-weight: 700;
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .jcUugHlR {
  display: flex;
  justify-content: space-between;
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .jcUugHlR .ant-radio-group {
  background: var(--table-heading-bg);
  border-radius: 15px;
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .jcUugHlR .ant-radio-group .ant-radio-button-wrapper {
  width: 142px;
  text-align: center;
  border: none;
  transition: unset;
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .jcUugHlR .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .jcUugHlR .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: var(--main-color);
  border-radius: 15px;
  color: var(--white);
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .jcUugHlR .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--white);
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .jcUugHlR .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: unset;
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .pKmyJjUR .DKkZPq7z,
.PSRqDMG3 .rXofSjEh .oUF4itTW .pKmyJjUR .ECZZQn47 {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  cursor: pointer;
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .pKmyJjUR .DKkZPq7z {
  border-bottom: 6px solid var(--main-color);
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .pKmyJjUR .ECZZQn47 {
  border-top: 6px solid var(--main-color);
}
.PSRqDMG3 .rXofSjEh .oUF4itTW .pKmyJjUR .ant-table-row.ant-table-row-level-1 {
  background: var(--table-heading-bg);
}
.PSRqDMG3 .rXofSjEh .__70d2SkWw .SUGLVRjx {
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
  margin-bottom: 10px;
  font-weight: 500;
}
.PSRqDMG3 .rXofSjEh .YPI5zd5k,
.PSRqDMG3 .rXofSjEh .YPI5zd5k > button {
  margin-right: 16px;
  width: 95px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
.PSRqDMG3 .rXofSjEh .oKvYxg0F {
  margin-left: 15px;
}
.PSRqDMG3 .rXofSjEh .Oo_KGWeo {
  width: 100%;
  height: 100px;
  background: var(--module-bg);
  cursor: pointer;
  padding-top: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.PSRqDMG3 .rXofSjEh .x2yEFolO {
  background: var(--tab-active-bg);
  width: 100%;
  height: 100px;
  cursor: pointer;
  padding-top: 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.PSRqDMG3 .rXofSjEh .aR0HcXwh {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  width: 100%;
  color: var(--white);
  line-height: 22px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.PSRqDMG3 .rXofSjEh .hEjr69_1 {
  font-size: 30px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 36px;
  margin-bottom: 22px;
}
.PSRqDMG3 .rXofSjEh .zv1ZCfKe {
  font-size: 16px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
  margin-bottom: 24px;
}
.PSRqDMG3 .rXofSjEh .n4j4usRB {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
  margin-top: 30px;
}
.PSRqDMG3 .rXofSjEh .n4j4usRB:hover {
  color: var(--main-text-hover-color);
}
.PSRqDMG3 .rXofSjEh .__2yWG67Yy {
  display: none;
}
.PSRqDMG3 ._6o_Brmp {
  width: 100%;
  padding: 35px 20px;
  background: var(--tab-active-bg);
}
.PSRqDMG3 ._6o_Brmp .YPI5zd5k {
  width: 100px;
}
.PSRqDMG3 ._6o_Brmp .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent;
  pointer-events: none;
}
.PSRqDMG3 ._6o_Brmp .ant-table-thead > tr > th {
  background-color: var(--table-heading-bg);
}
.q5GFNjiQ {
  background-color: transparent;
  padding: 0;
}
.i6bYtAQH {
  margin: 24px auto 16px;
}
.i6bYtAQH .S3WxqiNW {
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 30px;
  margin-bottom: 16px;
}
.i6bYtAQH .__6ESccFUE {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  margin-bottom: 10px;
}
.i6bYtAQH .VpLJlHGv {
  color: var(--tip-text-color);
}
.i6bYtAQH .Q_uBvtR_ {
  position: relative;
  width: 180px;
  height: 180px;
  background-color: var(--white);
  text-align: center;
  padding-top: 5px;
  overflow: hidden;
}
.i6bYtAQH .C1AJZZrB {
  border-right: 1px dashed var(--border-color);
}
.i6bYtAQH .C1AJZZrB .uUAE78iR {
  margin-bottom: 24px;
  width: 80px;
  height: 32px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 20px;
}
.i6bYtAQH .C1AJZZrB .Pp879fRG {
  margin-bottom: 32px;
  display: flex;
}
.i6bYtAQH .C1AJZZrB .Pp879fRG .QfVUUN_g .ant-select-selector {
  border-color: var(--main-color) !important;
  color: var(--main-color);
}
.i6bYtAQH .C1AJZZrB .Pp879fRG .QfVUUN_g .ant-select-arrow {
  color: var(--main-color);
}
.i6bYtAQH .C1AJZZrB .Pp879fRG .gIR4Cwqc {
  margin-left: 60px;
}
.i6bYtAQH .C1AJZZrB .Pp879fRG .gIR4Cwqc .vUkZnhpL {
  border: 1px solid var(--main-color);
  color: var(--main-color);
  width: 80px;
  height: 32px;
  text-align: center;
  border-radius: 2px;
  line-height: 32px;
}
.i6bYtAQH .C1AJZZrB .FB2pEUmM {
  color: var(--white);
  font-size: 24px;
  font-weight: bold;
}
.i6bYtAQH .C1AJZZrB .Dk4o1M0_ {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 14px;
  max-width: 700px;
}
.i6bYtAQH .C1AJZZrB .Dk4o1M0_ span {
  color: var(--main-color);
  position: absolute;
  font-size: 14px;
  right: 12%;
  cursor: pointer;
}
.i6bYtAQH .r_H_ne1H {
  background: var(--main-bg-color);
  margin-top: 24px;
  padding: 16px;
  color: var(--text-color);
  line-height: 26px;
  font-family: var(--CommonRegular);
}
.i6bYtAQH .r_H_ne1H .S3WxqiNW {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
}
.i6bYtAQH .ant-input {
  width: 90%;
}
.i6bYtAQH .anticon svg {
  display: inline-block;
}
.i6bYtAQH .ant-input[disabled] {
  cursor: pointer;
  font-size: 16px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 24px;
}
.b58uZGr6 {
}
.b58uZGr6 .FZ3BLEmf {
  margin-bottom: 16px;
  font-size: 18px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 26px;
}
.b58uZGr6 .gQqB0rbf {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  cursor: pointer;
}
.b58uZGr6 .n4j4usRB {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.b58uZGr6 .n4j4usRB:hover {
  color: var(--main-text-hover-color);
}
.m7auJwMm .ant-form-item-control-input .ant-input {
  background: var(--module-bg);
}
.m7auJwMm .ant-form-item-control-input-content {
  background: transparent;
}
.m7auJwMm .ant-modal-footer {
  border-top: none;
  margin-top: 10px;
  padding-bottom: 0px;
  display: flex;
  justify-content: flex-end;
}
.m7auJwMm .ant-modal-footer button:first-child {
  display: inline-block;
  margin-left: 16px;
  order: 1;
}
.m7auJwMm .ant-modal-footer button:first-child:hover {
  border-color: var(--main-color);
  color: var(--main-color);
}
.m7auJwMm .ant-modal-footer .ant-btn-primary {
  background: var(--main-color) !important;
}
.m7auJwMm .ant-modal-footer .ant-btn-primary:hover {
  background: var(--main-btn-hover-color) !important;
}
.m7auJwMm .ant-modal-footer .ant-btn {
  cursor: pointer;
  width: 108px;
  background: transparent;
  border: 1px solid var(--btn-border);
  height: 40px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
  border-radius: 4px;
  margin-top: 20px;
}
.m7auJwMm .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  width: 108px;
}
.m7auJwMm .ant-modal-header > .ant-modal-title {
  color: var(--white);
}
.__5GL4w3km {
  background-color: transparent;
  padding: 0;
}
.__7hO4U4wc {
  margin: 24px auto 16px;
}
.__7hO4U4wc .mrjqrBJA {
  display: flex;
  align-items: center;
  max-width: 700px;
  background: var(--alert-blue-bg);
  border: 1px solid var(--alert-blue-border);
  border-radius: 2px;
  padding: 9px 16px;
  box-sizing: border-box;
}
.__7hO4U4wc .mrjqrBJA span {
  margin-left: 10px;
}
.__7hO4U4wc .__6ESccFUE {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 10px;
}
.__7hO4U4wc .r_H_ne1H {
  margin-top: 32px;
}
.__7hO4U4wc .S3WxqiNW {
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 30px;
  margin-bottom: 16px;
}
.__7hO4U4wc .rMGX8Brc {
  background: var(--alert-blue-bg);
  border: 1px solid var(--alert-blue-border);
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  margin-bottom: 32px;
}
.__7hO4U4wc .FfBRvNp6 {
  height: 40px;
  background: var(--main-bg-color);
  border-radius: 2px;
  padding: 9px 16px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
}
.__7hO4U4wc .FfBRvNp6 :first-child {
  color: var(--text-color);
  margin-right: 8px;
}
.__7hO4U4wc .FfBRvNp6 :nth-child(2) {
  color: var(--green-text-color);
  cursor: pointer;
}
.__7hO4U4wc .__0TB4Yy8h {
  margin-top: 30px;
}
.__7hO4U4wc .__0TB4Yy8h .QfVUUN_g .ant-select-selector {
  border-color: var(--main-color) !important;
  color: var(--main-color);
}
.__7hO4U4wc .__0TB4Yy8h .QfVUUN_g .ant-select-arrow {
  color: var(--main-color);
}
.__7hO4U4wc .__0TB4Yy8h .vUkZnhpL {
  border: 1px solid var(--main-color);
  color: var(--white);
  width: 80px;
  height: 32px;
  text-align: center;
  border-radius: 2px;
  line-height: 32px;
}
.__7hO4U4wc .ant-alert-info {
  background: var(--alert-blue-bg);
  border-radius: 2px;
  border: 1px solid var(--alert-blue-border);
}
.__7hO4U4wc .ant-alert-info .ant-alert-icon {
  color: var(--alert-blue-border);
}
.__7hO4U4wc .__8vtvsPvv {
  margin-top: 30px;
  height: 60px;
}
.__7hO4U4wc .__8vtvsPvv .XySva7Zz {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.__7hO4U4wc .__8vtvsPvv .XySva7Zz :nth-child(2) {
  color: var(--main-color);
  cursor: pointer;
}
.__7hO4U4wc .__8vtvsPvv .Z8UYBuRY {
  display: flex;
}
.__7hO4U4wc .__8vtvsPvv .Z8UYBuRY .ant-input {
  border-radius: 0;
}
.__7hO4U4wc .__8vtvsPvv .Z8UYBuRY .KdeRkGEc {
  flex: 1 1;
  background-color: var(--main-bg-color);
  height: 40px;
  line-height: 40px;
  padding: 0px 11px;
  color: var(--white-op2);
}
.__7hO4U4wc .__8vtvsPvv .Z8UYBuRY .kJkQW5mt {
  width: 30px;
  height: 40px;
  cursor: pointer;
  background-color: var(--main-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
}
.__7hO4U4wc .__8vtvsPvv .TzYiMx7f {
  height: 40px;
  background: var(--main-bg-color);
  border-radius: 2px;
  padding: 9px 16px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
}
.__7hO4U4wc .__8vtvsPvv .__94_aawbj {
  width: 100%;
  color: var(--text-color) !important;
  background-color: var(--main-bg-color);
}
.__7hO4U4wc .__8vtvsPvv .MZ__oXqe {
  color: var(--tip-text-color);
  background-color: var(--module-bg);
  font-size: 14px;
}
.__7hO4U4wc .DKRx69HM {
  margin: 30px 0;
}
.__7hO4U4wc .DKRx69HM p {
  margin-bottom: 5px;
}
.__7hO4U4wc .Laex6fiE {
  max-width: 700px;
  margin-top: 30px;
  height: 60px;
}
.__7hO4U4wc .Laex6fiE .XySva7Zz {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.__7hO4U4wc .Laex6fiE .XySva7Zz :nth-child(2) {
  color: var(--green-text-color);
  cursor: pointer;
  text-decoration: underline;
}
.__7hO4U4wc .Laex6fiE .__7oIR0Uca .c2bWefq8 {
  display: flex;
  align-items: center;
}
.__7hO4U4wc .Laex6fiE .__7oIR0Uca .c2bWefq8 .__7lRuLxpM {
  background-color: var(--main-bg-color);
}
.__7hO4U4wc .Laex6fiE .__7oIR0Uca .c2bWefq8 .__7lRuLxpM input::-webkit-outer-spin-button,
.__7hO4U4wc .Laex6fiE .__7oIR0Uca .c2bWefq8 .__7lRuLxpM input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.__7hO4U4wc .Laex6fiE .__7oIR0Uca .c2bWefq8 .BjaVuqc9 {
  margin-left: 20px;
  color: var(--tip-text-color);
  background-color: var(--module-bg);
  font-size: 14px;
}
.__7hO4U4wc .Laex6fiE .__7oIR0Uca .hsdyTU_w {
  font-size: 14px;
  color: var(--white);
  margin-top: 5px;
}
.__7hO4U4wc .Laex6fiE .BjaVuqc9 {
  margin-left: 20px;
  color: var(--tip-text-color);
  background-color: var(--module-bg);
  font-size: 14px;
}
.__7hO4U4wc .HqQf_8wy {
  margin-top: 50px;
  margin-bottom: 50px;
  height: 60px;
}
.__7hO4U4wc .HqQf_8wy .XySva7Zz {
  margin-bottom: 5px;
}
.__7hO4U4wc .HqQf_8wy .__7oIR0Uca {
  width: 100%;
  display: flex;
}
.__7hO4U4wc .HqQf_8wy .__7oIR0Uca .ylIv9fsz {
  width: 70%;
  margin-right: 20px;
}
.__7hO4U4wc .HqQf_8wy .__7oIR0Uca .DOImB3aG {
  height: 40px;
  color: var(--main-color);
  background-color: var(--main-bg-color);
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
}
.__7hO4U4wc .uqKEfmQv {
  background: var(--main-bg-color);
  margin-top: 24px;
  padding: 16px;
  color: var(--text-color);
  line-height: 26px;
  font-family: var(--CommonRegular);
}
.__7hO4U4wc .uqKEfmQv .S3WxqiNW {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
}
.__7hO4U4wc .Dk4o1M0_ {
  max-width: 700px;
}
.__7hO4U4wc .hv6YPGKc {
  margin-top: 60px;
  width: 120px;
  height: 36px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
}
.__7hO4U4wc .ciC_9NFs {
  margin-top: 50px;
}
.__7hO4U4wc .ciC_9NFs .S3WxqiNW {
  font-size: 20px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
}
.__7hO4U4wc .ciC_9NFs .LYUkvaWK {
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--text-color);
}
.__7hO4U4wc .ciC_9NFs .LYUkvaWK li {
  margin-bottom: 10px;
}
.__7hO4U4wc .ciC_9NFs .LYUkvaWK li span {
  font-weight: 700;
  color: var(--white);
}
.NyJcibVH {
  background-color: transparent;
  padding: 0;
}
.BkZTmjDj {
  margin: 24px auto 16px;
  padding: 38px 80px 93px;
}
.BkZTmjDj .S3WxqiNW {
  font-family: var(--CommonMedium);
  color: var(--white);
  font-size: 22px;
  margin-bottom: 32px;
}
.BkZTmjDj .KJGQrpR3 {
  width: 100%;
  margin-bottom: 50px;
}
.BkZTmjDj .KJGQrpR3 .S3WxqiNW {
  font-size: 16px;
  color: var(--white);
  margin-bottom: 21px;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD {
  width: 100%;
  height: 237px;
  padding: 32px 24px 36px;
  background: var(--grey-level2);
  display: flex;
  justify-content: space-between;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .IOWwSiXh {
  width: 45%;
  color: var(--text-color);
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .IOWwSiXh .__3f42ED3y {
  font-family: var(--CommonRegular);
  font-size: 14px;
  margin-bottom: 8px;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .IOWwSiXh .EuoN67tI {
  height: 145px;
  background: var(--main-bg-color);
  padding: 28px 24px 27px;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .IOWwSiXh .EuoN67tI p {
  margin-bottom: 14px;
  display: flex;
  justify-content: space-between;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .IOWwSiXh .EuoN67tI .HPqqwmLM {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .IOWwSiXh .EuoN67tI .HPqqwmLM p {
  display: flex;
  justify-content: right;
  margin-bottom: 0px;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .fgbZp2nd {
  width: 45%;
  color: var(--text-color);
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .fgbZp2nd .c2c8g59h {
  font-family: var(--CommonRegular);
  font-size: 14px;
  margin-bottom: 8px;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .fgbZp2nd .Dk4o1M0_ {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .fgbZp2nd .Dk4o1M0_ .__7psYnyxH {
  width: 100%;
  height: 59px;
  line-height: 59px;
  font-size: 24px;
  font-weight: bold;
  background: var(--main-bg-color);
  text-align: center;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .fgbZp2nd .Dk4o1M0_ span {
  color: var(--main-color);
  position: absolute;
  font-size: 14px;
  right: 5%;
  cursor: pointer;
}
.BkZTmjDj .KJGQrpR3 .Ht4WVHzD .fgbZp2nd .yZ9DOJF5 {
  font-family: var(--CommonRegular);
  color: var(--tip-text-color);
}
.BkZTmjDj .tRmFLK97 {
  width: 100%;
  margin-bottom: 50px;
}
.BkZTmjDj .tRmFLK97 .S3WxqiNW {
  font-size: 16px;
  color: var(--white);
  margin-bottom: 21px;
}
.BkZTmjDj .tRmFLK97 .Ht4WVHzD {
  width: 100%;
  padding: 32px 24px 36px;
  background: var(--grey-level2);
  color: var(--text-color);
}
.BkZTmjDj .tRmFLK97 .Ht4WVHzD .vF9H2WqY {
  font-family: var(--CommonRegular);
  font-size: 14px;
  margin-bottom: 8px;
}
.BkZTmjDj .tRmFLK97 .Ht4WVHzD .u9WUQOM7 {
  background: var(--main-bg-color);
  padding: 24px;
  margin-bottom: 24px;
}
.BkZTmjDj .tRmFLK97 .Ht4WVHzD .u9WUQOM7 p,
.BkZTmjDj .tRmFLK97 .Ht4WVHzD .u9WUQOM7 li {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.BkZTmjDj .tRmFLK97 .Ht4WVHzD .u9WUQOM7 p:last-child,
.BkZTmjDj .tRmFLK97 .Ht4WVHzD .u9WUQOM7 li:last-child {
  margin-bottom: 0px;
}
.BkZTmjDj .tRmFLK97 .Ht4WVHzD .Qaq9216l {
  font-size: 14px;
  color: var(--white);
  margin-bottom: 15px;
}
.BkZTmjDj .tRmFLK97 .Ht4WVHzD .__0G_d6Fwi {
  font-size: 14px;
  color: var(--white);
}
.BkZTmjDj .AFM7kJPT .ant-picker {
  width: 100%;
  height: 40px;
  background: var(--main-bg-color);
}
.BkZTmjDj .AFM7kJPT .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.BkZTmjDj .AFM7kJPT .ant-select-single .ant-select-selector .ant-select-selection-item,
.BkZTmjDj .AFM7kJPT .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px;
  font-size: 14px;
}
.BkZTmjDj .AFM7kJPT .ant-select {
  background: var(--main-bg-color);
}
.BkZTmjDj .AFM7kJPT .S3WxqiNW {
  font-family: var(--CommonMedium);
  color: var(--white);
  font-size: 16px;
  margin-bottom: 16px;
}
.BkZTmjDj .AFM7kJPT .RalxC5HO {
  margin-bottom: 26px;
}
.BkZTmjDj .AFM7kJPT .RalxC5HO .q4q3OQBd {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}
.BkZTmjDj .AFM7kJPT .RalxC5HO .q4q3OQBd .uzM0kWjF {
  width: 48%;
}
.BkZTmjDj .AFM7kJPT .RalxC5HO .q4q3OQBd .uzM0kWjF .QDKr4KIo {
  margin-bottom: 8px;
  font-family: var(--CommonRegular);
  color: var(--text-color);
  font-size: 14px;
}
.BkZTmjDj .AFM7kJPT .RalxC5HO .q4q3OQBd .uzM0kWjF .diRjYR59 {
  width: 100%;
  height: 40px;
  background-color: var(--main-bg-color);
  padding: 9px 16px;
  color: var(--text-color);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.BkZTmjDj .AFM7kJPT .__90havvHd {
  width: 100%;
  display: flex;
  margin-bottom: 50px;
}
.BkZTmjDj .AFM7kJPT .__90havvHd .g_scqoT9 {
  width: 33%;
  height: 72px;
  margin-right: 36px;
  white-space: nowrap;
}
.BkZTmjDj .AFM7kJPT .__90havvHd .DOImB3aG {
  color: var(--white);
  font-size: 14px;
}
.IP_PHncR {
  margin-top: 184px;
  position: relative;
}
.IP_PHncR .__5E7ruV74 {
  margin-bottom: 16px;
  width: 100%;
  height: 120px;
  background-color: var(--module-bg);
  padding: 25px 0;
}
.IP_PHncR .__5E7ruV74 .jiQF3S51 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.IP_PHncR .__5E7ruV74 .jiQF3S51 .pHjI74jh {
  margin-bottom: 12px;
  font-size: 36px;
  font-weight: bold;
  color: var(--white);
}
.IP_PHncR .__5E7ruV74 .jiQF3S51 .tAZUak4Q {
  color: var(--text-color);
}
.IP_PHncR .__5E7ruV74 .nElxdjmG {
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
}
.IP_PHncR .cBcoNvaY {
  background-color: var(--module-bg);
  padding: 30px 24px 32px;
  min-height: 600px;
}
.IP_PHncR .cBcoNvaY .FZ3BLEmf {
  color: var(--white);
  font-family: var(--CommonBold);
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 24px;
}
.IP_PHncR .n4j4usRB {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.IP_PHncR .n4j4usRB:hover {
  color: var(--main-text-hover-color);
}
.IP_PHncR .__4amxc_cZ {
  margin-bottom: 30px;
}
.FHCH96j7 p {
  color: var(--white);
  margin-bottom: 20px;
}
.wJQPs5m9 .ant-modal-body .btn-actions {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
.wJQPs5m9 .ant-modal-body .btn-actions .ant-btn {
  height: 36px;
}
.anx0k1bP .ant-modal-body .ant-btn {
  width: 100%;
  height: 40px;
}
.UlVhAUT_ .i6bYtAQH .C1AJZZrB {
  border: none;
}
.UlVhAUT_ .i6bYtAQH .C1AJZZrB .Dk4o1M0_ span {
  right: 17%;
}
.UlVhAUT_ .i6bYtAQH .wxJ_Xxou {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px dashed var(--border-color);
}
.UlVhAUT_ .i6bYtAQH .S3WxqiNW {
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}
.UlVhAUT_ .i6bYtAQH .__6ESccFUE {
  color: var(--white);
}
.UlVhAUT_ .i6bYtAQH .r_H_ne1H {
  margin-top: 32px;
}
.UlVhAUT_ .i6bYtAQH .r_H_ne1H .S3WxqiNW {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}
.UlVhAUT_ .i6bYtAQH .ant-btn {
  height: 40px;
  padding: 6px 30px;
}
.UlVhAUT_ .i6bYtAQH .ant-input {
  max-width: 700px;
  height: 44px;
}
.UlVhAUT_ .i6bYtAQH .ant-input:disabled {
  background: var(--main-bg-color);
}
.UlVhAUT_ .i6bYtAQH .ant-input:focus {
  border: none;
}
.UlVhAUT_ .i6bYtAQH .ant-input-suffix {
  margin-left: 30px;
}
.UlVhAUT_ .i6bYtAQH .ant-input-affix-wrapper:focus,
.UlVhAUT_ .i6bYtAQH .ant-input-affix-wrapper-focused {
  box-shadow: none;
}
.UlVhAUT_ .i6bYtAQH .IouC26Ah {
  margin-bottom: 32px;
  max-width: 700px;
}
.UlVhAUT_ .i6bYtAQH .__7lRuLxpM {
  display: flex;
  align-items: center;
  max-width: 700px;
  height: 44px;
  background-color: var(--main-bg-color);
}
.UlVhAUT_ .i6bYtAQH .__7lRuLxpM input::-webkit-outer-spin-button,
.UlVhAUT_ .i6bYtAQH .__7lRuLxpM input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.UlVhAUT_ .i6bYtAQH .__7Sg9nf_n {
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.UlVhAUT_ .i6bYtAQH .BjaVuqc9 {
  margin-left: 20px;
  color: var(--tip-text-color);
  background-color: var(--module-bg);
  font-size: 14px;
}
.UlVhAUT_ .__9xec_hkj {
  display: flex;
  margin: 30px 0;
}
.UlVhAUT_ .__9xec_hkj .Wsn9kqQx {
  flex-shrink: 0;
}
.UlVhAUT_ .__9xec_hkj > .X3CCpJs0 {
  margin-left: 10px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--CommonMedium);
}
.fwzhvOOi .__43_gFreS {
  width: 100%;
  display: flex;
  height: 26px;
  color: var(--white);
}
.fwzhvOOi .__43_gFreS > div {
  text-align: center;
}
.fwzhvOOi .__43_gFreS div.gY0CMZE1 {
  width: 30%;
  background: var(--btn-green-bg);
}
.fwzhvOOi .__43_gFreS div.MFknbmUy {
  background: var(--btn-red-bg);
  flex: 1 1;
}
.fwzhvOOi .__43_gFreS div.wgW7J8Xv {
  width: 30%;
  background: var(--main-color);
}
.fwzhvOOi .H3A6WN03 {
  display: flex;
  justify-content: center;
  background: var(--tab-active-bg);
  padding: 16px 0;
  font-size: 12px;
}
.fwzhvOOi .H3A6WN03 .ISq4Ako0,
.fwzhvOOi .H3A6WN03 .__7bD_bTA9 {
  width: 31%;
}
.fwzhvOOi .H3A6WN03 .ISq4Ako0 {
  padding-left: 15px;
}
.fwzhvOOi .H3A6WN03 .__4krWHRaW {
  flex: 1 1;
  padding-left: 10px;
}
.fwzhvOOi .H3A6WN03 .__7bD_bTA9 {
  padding-left: 10px;
}
.fwzhvOOi .BwfNutNa .JbiZcZGw {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 16px;
}
.fwzhvOOi .BwfNutNa .JbiZcZGw .hGqN65ze {
  color: var(--text-color);
}
.fwzhvOOi .BwfNutNa .JbiZcZGw .wWiBjWPW {
  color: var(--white);
  text-align: right;
}
.fwzhvOOi .BwfNutNa .JbiZcZGw .I4yGOgVf {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.fwzhvOOi .BwfNutNa ._OTzCbp1 {
  margin-bottom: 20px;
}
.fwzhvOOi .BwfNutNa .__8iBlOHD7 {
  margin-top: 16px;
  margin-bottom: 26px;
}
.fwzhvOOi .BwfNutNa .RUWokH_G {
  margin: 22px 0;
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez {
  margin-top: 14px;
  font-size: 12px;
  display: flex;
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .__4mkjTw_n .ant-row {
  width: 130px;
  height: 36px;
  align-items: center;
  padding-left: 10px;
  border-right: 1px solid var(--main-bg-color);
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .__4mkjTw_n .ant-row:nth-child(odd) {
  background: var(--tab-active-bg);
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .GwsLB2sI {
  width: calc(100% - 130px);
  color: var(--white);
  overflow-x: scroll;
  /* 设置滚动条的样式 */
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .GwsLB2sI::-webkit-scrollbar {
  height: 3px;
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .GwsLB2sI .ant-row {
  flex-wrap: nowrap;
  height: 36px;
  align-items: center;
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .GwsLB2sI .ant-row > .ant-col {
  flex: 1 1;
  flex-basis: 102px;
  flex-shrink: 0;
  text-align: center;
  line-height: 36px;
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .GwsLB2sI .ant-row > .ant-col:not(:first-child) {
  border-left: 1px solid var(--main-bg-color);
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .GwsLB2sI .ant-row:nth-child(odd) > .ant-col {
  background: var(--tab-active-bg);
}
.fwzhvOOi .BwfNutNa .RUWokH_G .z6ey8zez .GwsLB2sI .ant-row:last-child > .ant-col {
  border-left: unset;
}
.fwzhvOOi .QpjkpfKV {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.fwzhvOOi .QpjkpfKV .__5qgO5mup {
  color: var(--white);
  margin-left: 16px;
}
.fwzhvOOi .QpjkpfKV .__5qgO5mup.dliDA3Ia {
  color: var(--text-color);
}
.__2AsZS76m span {
  color: var(--tip-text-color);
}
.ibDvPJ58 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.ibDvPJ58 .sXNlKwB3 {
  margin-left: 6px;
  margin-right: 12px;
}
.ibDvPJ58 .igG9M1GJ {
  text-decoration: line-through;
  color: var(--remark-text-color);
}
.n4j4usRB {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.n4j4usRB:hover {
  color: var(--main-text-hover-color);
}
.__3VSW43ng {
  display: flex;
  margin-top: 184px;
  position: relative;
}
.kE4nme5q {
  color: var(--main-color);
  cursor: pointer;
}
.SUZzzfBx .ant-modal-footer {
  border-top: none;
  margin-top: 10px;
  padding-bottom: 0px;
  display: flex;
  justify-content: flex-end;
}
.SUZzzfBx .ant-modal-footer button:first-child {
  display: inline-block;
  margin-left: 16px;
  order: 1;
}
.SUZzzfBx .ant-modal-footer button:first-child:hover {
  background: var(--main-color);
  border-color: var(--main-color);
  color: var(--white);
}
.SUZzzfBx .ant-modal-footer .ant-btn-primary {
  background: var(--main-color) !important;
}
.SUZzzfBx .ant-modal-footer .ant-btn-primary:hover {
  background: var(--main-btn-hover-color) !important;
}
.SUZzzfBx .ant-modal-footer .ant-btn {
  cursor: pointer;
  background: transparent;
  border: 1px solid var(--main-color);
  height: 40px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 4px;
  margin-top: 20px;
}
.SUZzzfBx .ant-modal-header > .ant-modal-title {
  color: var(--white);
}
.SUZzzfBx .ant-alert-info {
  background: var(--alert-blue-bg);
  border-radius: 2px;
  border: 1px solid var(--alert-blue-border);
}
.SUZzzfBx .ant-alert-info .ant-alert-icon {
  color: var(--alert-blue-border);
}
.SUZzzfBx .__4FmtoltF {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SUZzzfBx .Zt1DyxA3 {
  max-height: 500px;
  overflow: auto;
  margin-top: 15px;
}
.SUZzzfBx .Zt1DyxA3 .LdO1f3CA {
  margin-bottom: 16px;
  border-bottom: 1px solid var(--btn-type1-bg);
}
.SUZzzfBx .Zt1DyxA3 .LdO1f3CA .RnB7RhdK {
  display: flex;
  align-items: center;
}
.SUZzzfBx .Zt1DyxA3 .LdO1f3CA .RnB7RhdK .KqMCRNqv {
  font-size: 16px;
  font-family: CommonMedium;
  margin-right: 10px;
}
.SUZzzfBx .Zt1DyxA3 .LdO1f3CA .RnB7RhdK .YFtOyF_E {
  font-size: 12px;
  padding: 0 6px;
  background-color: var(--btn-type-bg);
  color: var(--main-color);
  border-radius: 2px;
  margin-right: 10px;
}
.SUZzzfBx .Zt1DyxA3 .LdO1f3CA .RnB7RhdK .TgXVn5Se {
  background-color: var(--btn-disabled-bg);
  color: var(--btn-disabled-text-color);
}
.SUZzzfBx .Zt1DyxA3 .LdO1f3CA .JEkUUWnV {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px 10px;
  cursor: default;
}
.SUZzzfBx .Zt1DyxA3 .LdO1f3CA .dliDA3Ia {
  color: var(--btn-disabled-text-color);
}
.X1Ib3D4l {
  max-width: 1200px;
  border-radius: 2px;
  margin: auto;
  margin-top: 34px;
}
.X1Ib3D4l .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.X1Ib3D4l .ant-btn-primary {
  background: var(--main-color);
  color: var(--white);
  margin-right: 30px;
}
.X1Ib3D4l .ant-btn:hover,
.X1Ib3D4l .ant-btn:active {
  background: var(--main-color);
  color: var(--white);
}
.X1Ib3D4l .ant-switch {
  background-color: var(--btn-grey1-bg);
  background-image: none;
}
.X1Ib3D4l .ant-switch-checked {
  background-color: var(--main-color);
}
.X1Ib3D4l .ant-switch-handle::before {
  background-color: var(--tab-active-bg);
}
.X1Ib3D4l .ant-tabs-tab {
  margin: 0;
  width: 180px;
}
.X1Ib3D4l .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent;
  pointer-events: none;
}
.X1Ib3D4l .ant-table-thead > tr > th,
.X1Ib3D4l .ant-table-sticky-holder {
  background-color: var(--table-heading-bg);
}
.X1Ib3D4l .ant-table-content {
  min-height: 235px;
}
.X1Ib3D4l .ant-tabs-tab-btn {
  padding-top: 5px;
  width: 100%;
  height: 64px;
  font-size: 18px;
  font-family: var(--CommonRegular);
  text-align: center;
  color: var(--text-color);
}
.X1Ib3D4l .ant-tabs-tab-btn:hover {
  color: var(--white);
}
.X1Ib3D4l .ant-tabs-tab-active {
  background-color: var(--tab-active-bg);
}
.X1Ib3D4l .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--white);
}
.X1Ib3D4l .FNKXr65C {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.X1Ib3D4l .FNKXr65C .EpvF6O3L .WK_hc_ez {
  color: var(--white);
  height: 48px;
  font-size: 14px;
  text-align: center;
  border: none;
  font-family: var(--CommonMedium);
}
.X1Ib3D4l .d9y_L47W {
  height: 64px;
  background-color: var(--module-bg);
}
.X1Ib3D4l .DK1RnKzi {
  padding: 24px;
  margin-bottom: 16px;
  height: 500px;
  background-color: var(--tab-active-bg);
  overflow-y: auto;
}
.X1Ib3D4l .__8iA46Twb {
  padding-top: 120px;
  margin-bottom: 16px;
  height: 500px;
  background-color: var(--tab-active-bg);
  text-align: center;
}
.X1Ib3D4l .__8iA46Twb p {
  margin-top: 40px;
  color: var(--text-color);
}
.X1Ib3D4l .__8iA46Twb .__8jefqmkL {
  margin-top: 10px;
  color: var(--main-color);
  text-decoration: underline;
  cursor: pointer;
}
.X1Ib3D4l .CE_T1W_e {
  height: 120px;
  background-color: var(--tab-active-bg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px;
}
.X1Ib3D4l .CE_T1W_e .Abkojx59 .cU6J4BgE {
  color: var(--white);
  font-family: var(--CommonMedium);
  font-size: 18px;
  margin-bottom: 16px;
}
.X1Ib3D4l .CE_T1W_e .Abkojx59 .B3mJBmsh {
  font-size: 16px;
  color: var(--white-op2);
}
.PKEI1qCI {
  color: var(--main-color);
}
.__1xv5avjw {
}
.__1xv5avjw .ant-select-selector {
  border-color: var(--main-color) !important;
  color: var(--main-color);
}
.__1xv5avjw .ant-select-arrow {
  color: var(--main-color);
}
.__1xv5avjw .S3WxqiNW {
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 30px;
  margin-bottom: 16px;
}
.__1xv5avjw .__6ESccFUE {
  color: var(--white-op2);
  margin-bottom: 8px;
}
.__1xv5avjw .mhQUfcGN {
  display: flex;
  margin: 40px 0px 24px;
}
.__1xv5avjw .mhQUfcGN .uXoZVhdn {
  margin-right: 60px;
}
.__1xv5avjw .u_68GTg3 {
  margin-bottom: 24px;
}
.__1xv5avjw .u_68GTg3 .oasNQFdZ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--main-bg-color);
  height: 40px;
  padding: 10px;
}
.__1xv5avjw .u_68GTg3 .oasNQFdZ .Wbn1v6UE {
  color: var(--text-color);
}
.__1xv5avjw .u_68GTg3 .oasNQFdZ .__1_HG_Z3x {
  color: var(--main-color);
  font-family: var(--CommonMedium);
  cursor: pointer;
}
.__1xv5avjw .u_68GTg3 .oasNQFdZ .MAWG2AMO {
  color: var(--disabled-text-color);
}
.__1xv5avjw .MZ__oXqe {
  color: var(--tip-text-color);
  background-color: var(--module-bg);
  font-size: 14px;
}
.__1xv5avjw .wnooxYI8 {
  display: flex;
  margin-bottom: 22px;
}
.__1xv5avjw .wnooxYI8 .__6IoTxMy7 {
  margin-left: 16px;
}
.__1xv5avjw .wnooxYI8 .__6IoTxMy7 .znrEyTov {
  font-family: var(--CommonMedium);
  margin-bottom: 8px;
}
.__1xv5avjw .wnooxYI8 .__6IoTxMy7 .A6GazZV6 {
  width: 530px;
  color: var(--white-op2);
}
.__1xv5avjw .q_5Z6mX5 {
  margin-bottom: 40px;
}
.__1xv5avjw .q_5Z6mX5 .fJiPAmFZ {
  color: var(--white);
  font-family: var(--CommonMedium);
  margin-bottom: 7px;
}
.__1xv5avjw .q_5Z6mX5 .r_H_ne1H {
  color: var(--white-op2);
  margin-bottom: 5px;
}
.ZrHp0ib4 {
  color: var(--white);
}
.ZrHp0ib4 .__6ESccFUE {
  color: var(--white-op2);
  margin-bottom: 13px;
}
.ZrHp0ib4 .ant-select-selector {
  border-color: transparent !important;
  background-color: var(--module-bg) !important;
}
.MBKwYvjM {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--module-bg);
  margin-bottom: 18px;
  padding: 15px 24px;
}
.MBKwYvjM .SxmsuYGA {
  height: 48px;
  width: 1px;
}
.MBKwYvjM .p6BPTWsN {
  font-family: var(--CommonRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: var(--white-op3);
  margin-bottom: 10px;
}
.MBKwYvjM .hsuiMDXh {
  font-family: var(--CommonBold);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: var(--white);
  margin-bottom: 3px;
}
.MBKwYvjM .RWghFbec {
  font-family: var(--CommonRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: var(--white-op1);
  margin-bottom: 3px;
}
.hhsuyk5D {
  max-width: 200px !important;
}
.__5h7LKqoi {
  font-size: 12px;
  line-height: 22px;
  color: var(--white-op1);
}
.dkg0cgU_ .ant-table-content {
  min-height: 0 !important;
}
.D_Gz861i {
  background: transparent;
}
.D_Gz861i .fvJTD5dL {
  background: var(--module-bg);
  margin: 24px auto 16px;
  padding: 50px 40px;
}
.D_Gz861i .fvJTD5dL .ant-input-group-wrapper {
  background: var(--main-bg-color);
}
.D_Gz861i .fvJTD5dL .ant-input {
  height: 44px;
}
.D_Gz861i .fvJTD5dL .ant-input-group-addon {
  font-size: 14px;
  font-family: var(--CommonBold);
}
.D_Gz861i .fvJTD5dL .S3WxqiNW {
  font-size: 24px;
  line-height: 30px;
  font-family: var(--CommonMedium);
  color: var(--white);
}
.D_Gz861i .fvJTD5dL .UCKF5sDe {
  font-size: 14px;
  line-height: 24px;
  color: var(--white-op2);
  margin-top: 14px;
}
.D_Gz861i .fvJTD5dL .Z7GZGcr5 {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.D_Gz861i .fvJTD5dL .Z7GZGcr5 .__89LxdE_j {
  width: 600px;
}
.D_Gz861i .fvJTD5dL .Z7GZGcr5 .__5neR9ksV {
  height: 44px;
  width: 171px;
  margin-left: 16px;
}
.D_Gz861i .fvJTD5dL .sMwObWv_ {
  font-size: 16px;
  line-height: 22px;
  font-family: var(--CommonMedium);
  color: var(--white);
  margin-top: 43px;
}
.D_Gz861i .fvJTD5dL .C6pUhewZ {
  display: flex;
  margin-top: 17px;
}
.D_Gz861i .fvJTD5dL .C6pUhewZ .qNLMPIup {
  flex: 1 1;
  height: 177px;
  background: var(--main-bg-color);
  border-radius: 4px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.D_Gz861i .fvJTD5dL .C6pUhewZ .qNLMPIup:nth-child(n + 2) {
  margin-left: 29px;
}
.D_Gz861i .fvJTD5dL .C6pUhewZ .qNLMPIup .__0SWnAQKA {
  width: 49px;
  height: 49px;
}
.D_Gz861i .fvJTD5dL .C6pUhewZ .qNLMPIup .S3WxqiNW {
  font-size: 13px;
  line-height: 26px;
  color: var(--white-op2);
  margin: 16px 0 0;
  padding: 0 20px;
}
.jIDVOTDJ {
  color: var(--white);
  font-family: var(--CommonBold);
  font-size: 20px;
  margin-top: 25px;
}
.Yn6_Xug5 {
  cursor: pointer;
  color: var(--main-color);
  display: flex;
  align-items: center;
}
.hc5flB3w {
  margin-left: 4px;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.__1gTHsBWk {
  max-width: 1200px;
  min-height: 500px;
  background: var(--module-bg);
  border-radius: 2px;
  padding: 32px 24px;
  margin: auto;
}
.__1gTHsBWk .khu7y2CU {
  display: flex;
  flex-wrap: wrap;
}
.__1gTHsBWk .dOFue4aU:nth-child(3n) {
  margin-right: 0px !important;
}
.__1gTHsBWk .dOFue4aU {
  width: 32%;
  margin-right: 22px;
}
.__7yQw4slL {
  max-width: 1200px;
  min-height: 100vh;
  margin: auto;
}
.__7yQw4slL .__54jNbhc3 {
  font-size: 22px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 30px;
  margin-top: 16px;
  margin-bottom: 15px;
}
.__7yQw4slL .At1KWwVd {
  display: inline-block;
  height: 28px;
  padding: 0 10px;
  background: var(--main-color);
  border-radius: 2px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 28px;
  text-align: center;
  margin-bottom: 24px;
}
.__7yQw4slL .AzzB3yAp {
  width: 100%;
  position: relative;
}
.__7yQw4slL .AzzB3yAp .KjxDNse_ {
  line-height: normal;
}
.__7yQw4slL .AzzB3yAp .UVPXCWzZ {
  width: 30%;
  height: 88px;
  position: absolute;
  right: 5.41%;
  top: 350px;
}
.__7yQw4slL .AzzB3yAp .UVPXCWzZ .FDVyHujV {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.__7yQw4slL .AzzB3yAp .VUrTfn_o {
  width: 100%;
  height: 40px;
  margin-bottom: 8px;
}
.__38H_H_fZ .ant-modal-footer button:first-child {
  display: block;
}
.__4rAjNNgM .DA39DEZ2 .qWYvk0hX {
  margin-bottom: 16px;
}
.__4rAjNNgM .DA39DEZ2 .qWYvk0hX .__18MvfbeX {
  color: var(--text-color-second);
}
.__4rAjNNgM .DA39DEZ2 .qWYvk0hX .QyBT_Orb {
  color: var(--white);
}
.__4rAjNNgM .DA39DEZ2 .q6hmrTUV .vUDeaLvZ {
  margin-bottom: 8px;
  color: var(--text-color-second);
}
.__4rAjNNgM .Dr_DFCZf {
  color: var(--text-color);
  margin-bottom: 32px;
}
.__4rAjNNgM .G1wkXKwo {
  color: var(--tip-text-color);
  margin: 8px 0;
}
.__4rAjNNgM .tQoX6R22 .__5YrsQito {
  margin-bottom: 16px;
  color: var(--main-color);
}
.__4rAjNNgM .tQoX6R22 .KSpwsYnb {
  margin-bottom: 16px;
}
.__4rAjNNgM .tQoX6R22 .KSpwsYnb .__54jNbhc3 {
  color: var(--text-color-second);
}
.__4rAjNNgM .tQoX6R22 .KSpwsYnb .zN81V_tj {
  color: var(--white);
}
.__4rAjNNgM .tQoX6R22 .q6hmrTUV .vUDeaLvZ {
  margin-bottom: 8px;
  color: var(--text-color-second);
}
.__4rAjNNgM .tQoX6R22 .q6hmrTUV .ybmexcbD {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}
.__4rAjNNgM .tQoX6R22 .q6hmrTUV .ybmexcbD .Tk2swpRI {
  width: 92px;
}
.Q01RSG0C .__7822nYLH {
  margin: 56px auto 20px;
  width: 100%;
  font-size: 80px;
  text-align: center;
}
.Q01RSG0C .__7822nYLH svg {
  color: var(--btn-green-bg);
}
.Q01RSG0C .BckBvq8L {
  margin: 56px auto 20px;
  width: 100%;
  font-size: 80px;
  text-align: center;
}
.Q01RSG0C .BckBvq8L svg {
  color: var(--btn-red-bg);
}
.Q01RSG0C .iaaPAENu .ccB8ImCI {
  font-size: 18px;
  font-weight: 500;
  color: var(--white);
  text-align: center;
  margin-bottom: 38px;
}
.Q01RSG0C .iaaPAENu .__3JA5p7wc {
  color: var(--text-color-second);
  margin-bottom: 24px;
}
.Q01RSG0C .iaaPAENu .__3JA5p7wc .CYi_01i_ {
  color: var(--tip-text-color);
}
.Q01RSG0C .iaaPAENu .__1zHHL0F0 {
  margin-top: 24px;
}
.Q01RSG0C .iaaPAENu .__1zHHL0F0 .Mq63qvdl {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}
.Q01RSG0C .iaaPAENu .__1zHHL0F0 .Mq63qvdl .__54jNbhc3 {
  color: var(--text-color-second);
}
.Q01RSG0C .iaaPAENu .__1zHHL0F0 .Mq63qvdl .zN81V_tj {
  color: var(--white);
}
.Q01RSG0C .Ts0wFjyV .gUO97f6U {
  font-size: 18px;
  font-weight: 500;
  color: var(--white);
  text-align: center;
  margin-bottom: 38px;
}
.Q01RSG0C .Ts0wFjyV .__2ruSGcfs {
  color: var(--text-color-second);
  margin-bottom: 24px;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.YBjmqbui {
  max-width: 1200px;
  margin: auto;
}
.YBjmqbui .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.YBjmqbui .ant-btn-primary {
  background: var(--main-color);
  color: var(--white);
  margin-right: 30px;
}
.YBjmqbui .ant-btn:hover {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.YBjmqbui .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: transparent;
  pointer-events: none;
}
.YBjmqbui .ant-table-thead > tr > th,
.YBjmqbui .ant-table-sticky-holder {
  background-color: var(--table-heading-bg);
}
.YBjmqbui .pJA4xEbL {
  padding: 0 24px 24px;
  background: var(--tab-active-bg);
  min-height: 600px;
}
.YBjmqbui .pJA4xEbL .__0Px4_HsW {
  width: 95px;
  height: 28px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  padding-top: 0;
  padding-bottom: 0;
}
.YBjmqbui .pJA4xEbL .Ure8ibSs {
  margin-right: 8px;
}
.YBjmqbui .pJA4xEbL .Zwo1ipNc {
  display: inline-block;
  width: 200px;
}
.YBjmqbui .rCwEmBJB {
  width: 160px;
  margin-right: 0px;
  color: var(--white);
  font-family: var(--CommonMedium);
}
.YBjmqbui .z9KgZIWH {
  display: flex;
  margin-top: 184px;
  position: relative;
}
.YBjmqbui .z9KgZIWH .ant-table-thead > tr > th {
  padding: 6px 16px;
  height: 57px;
}
.YBjmqbui .z9KgZIWH .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.YBjmqbui .z9KgZIWH .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 180px !important;
  height: 5px;
}
.YBjmqbui .z9KgZIWH .ant-tabs-top > .ant-tabs-nav,
.YBjmqbui .z9KgZIWH .ant-tabs-bottom > .ant-tabs-nav,
.YBjmqbui .z9KgZIWH .ant-tabs-top > div > .ant-tabs-nav,
.YBjmqbui .z9KgZIWH .ant-tabs-bottom > div > .ant-tabs-nav {
  margin-bottom: 0;
}
.YBjmqbui .z9KgZIWH .ant-tabs-top > .ant-tabs-nav::before,
.YBjmqbui .z9KgZIWH .ant-tabs-bottom > .ant-tabs-nav::before,
.YBjmqbui .z9KgZIWH .ant-tabs-top > div > .ant-tabs-nav::before,
.YBjmqbui .z9KgZIWH .ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  content: "";
}
.YBjmqbui .z9KgZIWH .iPBJ8FA2 {
  max-width: 1200px;
  background: var(--module-bg);
  border-radius: 2px;
  margin: auto;
  margin-top: 34px;
}
.YBjmqbui .z9KgZIWH .iPBJ8FA2 .cqpF7BhP {
  padding: 0 24px 32px;
  min-height: 600px;
}
.YBjmqbui .z9KgZIWH .iPBJ8FA2 .cqpF7BhP .UNcZP0Ik {
  margin-right: 16px;
  width: 95px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
.YBjmqbui .z9KgZIWH .iPBJ8FA2 .cqpF7BhP .t5PbLI3e {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.YBjmqbui .z9KgZIWH .iPBJ8FA2 .cqpF7BhP .t5PbLI3e:hover {
  color: var(--main-text-hover-color);
}
.YBjmqbui .z9KgZIWH .iPBJ8FA2 .cqpF7BhP .iQWkKsnA {
  min-height: 600px;
  margin-bottom: 30px;
  padding-top: 25px;
}
.YBjmqbui .z9KgZIWH .Wabba125 {
  max-width: 1200px;
  background: var(--module-bg);
  border-radius: 2px;
  margin: auto;
  margin-top: 34px;
}
.YBjmqbui .z9KgZIWH .Wabba125 .JoTidqoL {
  padding: 25px 24px 32px;
  min-height: 600px;
  font-family: var(--CommonMedium);
  color: var(--white);
}
.YBjmqbui .z9KgZIWH .Wabba125 .JoTidqoL .UNcZP0Ik {
  width: 95px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
.YBjmqbui .z9KgZIWH .Wabba125 .JoTidqoL .t5PbLI3e {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.YBjmqbui .z9KgZIWH .Wabba125 .JoTidqoL .t5PbLI3e:hover {
  color: var(--main-text-hover-color);
}
.YBjmqbui .z9KgZIWH .Wabba125 .JoTidqoL .iQWkKsnA {
  min-height: 600px;
  margin-bottom: 30px;
}
.YBjmqbui .__0z33Lr69 {
  background-color: var(--module-bg);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.YBjmqbui .qfhXkYpN {
  height: 64px;
  background-color: var(--module-bg);
}
.YBjmqbui .qfhXkYpN .ant-tabs-tab {
  margin: 0;
  width: 180px;
}
.YBjmqbui .qfhXkYpN .ant-tabs-tab-btn {
  padding-top: 5px;
  width: 100%;
  height: 64px;
  font-size: 18px;
  font-family: var(--CommonRegular);
  text-align: center;
  color: var(--text-color);
}
.YBjmqbui .qfhXkYpN .ant-tabs-tab-btn:hover {
  color: var(--white);
}
.YBjmqbui .qfhXkYpN .ant-tabs-tab-active {
  background-color: var(--tab-active-bg);
}
.YBjmqbui .qfhXkYpN .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--white);
}
.YBjmqbui .NawuxfSj {
  padding-top: 24px;
}
.YBjmqbui .NawuxfSj .Ure8ibSs {
  margin-right: 16px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
}
.YBjmqbui .NawuxfSj .jTRlEiWc {
  display: flex;
  align-items: center;
}
.YBjmqbui .NawuxfSj .IJk5p_UY {
  background: var(--btn-grey-bg);
  width: 75px;
  height: 22px;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.YBjmqbui .NawuxfSj .UNcZP0Ik {
  height: 28px;
  padding: 0 17px;
}
.YBjmqbui .NawuxfSj .PSc2eg_E {
  width: 100px;
  height: 28px;
  background-color: var(--btn-grey-bg);
  border-radius: 2px;
}
.YBjmqbui .NawuxfSj .PSc2eg_E .dXx7H2wp .ant-statistic-content {
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.YBjmqbui .NawuxfSj .PSc2eg_E .dXx7H2wp .ant-statistic-content-value {
  color: var(--main-color);
  font-size: 14px;
}
.YBjmqbui .NawuxfSj .iQWkKsnA {
  min-height: unset;
  margin-bottom: 30px;
}
.YBjmqbui .NawuxfSj .iQWkKsnA .ant-table-content {
  min-height: unset;
}
.YBjmqbui .NawuxfSj .t5PbLI3e {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.YBjmqbui .NawuxfSj .t5PbLI3e:hover {
  color: var(--main-text-hover-color);
}
.YBjmqbui .NawuxfSj .B30vWBx_ {
  position: absolute;
  top: 0;
  left: 0;
}
.YBjmqbui .NawuxfSj .__6jg74aTR {
  position: absolute;
  top: -3px;
  left: 0;
}
.SLdhin5O {
  font-family: var(--CommonMedium);
  color: var(--white);
}
.SLdhin5O .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.SLdhin5O .ant-btn-primary {
  color: var(--white);
  margin-right: 30px;
}
.SLdhin5O .ant-btn:hover,
.SLdhin5O .ant-btn:active {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.SLdhin5O .__8FEZYofc {
  margin-top: 19px;
  padding: 40px 80px;
  background-color: var(--module-bg);
}
.SLdhin5O .__8FEZYofc .mD6V9dd_ {
  padding-bottom: 31px;
  font-size: 22px;
}
.SLdhin5O .__8FEZYofc .__0Lic9IXu {
  padding-bottom: 24px;
  font-size: 16px;
  color: var(--white);
}
.SLdhin5O .__8FEZYofc .H2ggnByL {
  padding: 0 20px;
}
.SLdhin5O .__8FEZYofc .H2ggnByL .h9dzrHt3 {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
}
.SLdhin5O .__8FEZYofc .H2ggnByL .h9dzrHt3 .ZtdzZ3D_ {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.SLdhin5O .__8FEZYofc .H2ggnByL .h9dzrHt3 .ZtdzZ3D_ .Ure8ibSs {
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.SLdhin5O .__8FEZYofc .H2ggnByL .h9dzrHt3 .ZtdzZ3D_ .KcGLEqoC {
  color: var(--text-color);
}
.SLdhin5O .__8FEZYofc .H2ggnByL .h9dzrHt3 .ZtdzZ3D_ .jTRlEiWc {
  color: var(--white);
  font-weight: bold;
}
.SLdhin5O .__8FEZYofc .H2ggnByL .h9dzrHt3 .Rr2vZsAm {
  height: 44px;
  line-height: 44px;
  color: var(--white);
  font-size: 16px;
}
.SLdhin5O .__8FEZYofc .sMi4Iq6D {
  font-size: 14px;
  color: var(--text-color);
  padding-bottom: 13px;
}
.SLdhin5O .__8FEZYofc .dlf_rhLZ {
  width: 45%;
  font-size: 14px;
  color: var(--text-color) !important;
  background-color: var(--main-bg-color);
}
.SLdhin5O .__8FEZYofc .u4gAWsHY {
  margin-top: 18px;
  font-size: 16px;
  padding-bottom: 8px;
  font-family: var(--CommonRegular);
  color: var(--white);
}
.SLdhin5O .__8FEZYofc .h_FnMYX6 {
  padding-bottom: 24px;
  font-size: 24px;
  color: var(--white);
}
.SLdhin5O .__8FEZYofc .Vi1OX_lb {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}
.SLdhin5O .__8FEZYofc .Vi1OX_lb p {
  font-family: var(--CommonRegular);
  color: var(--text-color);
  margin-bottom: 9px;
}
.SLdhin5O .__8FEZYofc .Vi1OX_lb .eYAOUeKd {
  width: 45%;
}
.SLdhin5O .__8FEZYofc .Vi1OX_lb .eYAOUeKd .sqOaUj7B {
  height: 10px;
}
.SLdhin5O .__8FEZYofc .Vi1OX_lb .eYAOUeKd .sqOaUj7B p {
  color: var(--tip-text-color);
}
.SLdhin5O .__8FEZYofc .Vi1OX_lb .qYQL3I8z {
  width: 45%;
}
.SLdhin5O .__8FEZYofc .Vi1OX_lb .qYQL3I8z .dlf_rhLZ {
  width: 100%;
  font-size: 14px;
  color: var(--text-color) !important;
  background-color: var(--main-bg-color);
}
.SLdhin5O .__8FEZYofc .FFOKD3vX {
  margin-top: 48px;
}
.q_Cpqm_J {
  font-family: var(--CommonMedium);
  color: var(--white);
}
.q_Cpqm_J .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.q_Cpqm_J .ant-btn-primary {
  background: var(--main-color);
  color: var(--white);
  margin-right: 30px;
}
.q_Cpqm_J .ant-btn:hover,
.q_Cpqm_J .ant-btn:active {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.q_Cpqm_J .__8FEZYofc {
  margin-top: 25px;
  padding: 40px;
  background-color: var(--module-bg);
}
.q_Cpqm_J .__8FEZYofc .mD6V9dd_ {
  font-size: 22px;
  color: var(--white);
  margin-bottom: 30px;
}
.q_Cpqm_J .__8FEZYofc .UNcZP0Ik {
  width: 95px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
}
.q_Cpqm_J .__8FEZYofc .t5PbLI3e {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.q_Cpqm_J .__8FEZYofc .t5PbLI3e:hover {
  color: var(--main-text-hover-color);
}
.q_Cpqm_J .__8FEZYofc .iQWkKsnA {
  min-height: 600px;
  margin-bottom: 30px;
}
.aqip_EX4 .rRKPSwHY {
  font-size: 24px;
  color: var(--white);
  font-weight: bold;
}
.aqip_EX4 .lJ6hjJ4Z .vG05vdPy {
  font-family: var(--CommonRegular);
  counter-reset: var(--text-color);
  margin-bottom: 16px;
}
.aqip_EX4 .lJ6hjJ4Z .X8AOAciQ {
  margin-bottom: 16px;
  font-family: var(--CommonMedium);
  color: var(--white);
}
.aqip_EX4 .EA0pw4VD p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.aqip_EX4 .EA0pw4VD p .FcS_LZcF {
  color: var(--text-color);
}
.aqip_EX4 .EA0pw4VD p .E3PvYQTb {
  color: var(--white);
}
.XbiU27Ty .ant-input-suffix:after {
  content: "";
  width: 1px;
  height: 14px;
  margin-left: 12px;
  background: var(--white);
  opacity: 0.1;
}
.XbiU27Ty .ant-modal-content .ant-input-affix-wrapper {
  padding-right: 0;
}
.XbiU27Ty .ant-input-group-addon {
  padding-left: 0;
  padding-right: 0;
}
.XbiU27Ty .ant-modal-title {
  font-size: 16px;
  color: var(--white);
}
.XbiU27Ty .quO5An1w {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  margin-right: 10px;
  max-width: 200px;
}
.XbiU27Ty .oB7jYLRs {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
  max-width: 200px;
}
.XbiU27Ty .b5VwNgX3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.XbiU27Ty .PxZM11VU {
  display: flex;
  justify-content: space-between;
  flex: 0 1 auto;
  margin-bottom: 26px;
  margin-top: 10px;
}
.XbiU27Ty .wNsiLFxm {
  height: 24px;
  border-radius: 4px;
  border: 1px solid var(--border-color1);
  margin-right: 8px;
  padding: 0 10px;
  flex-grow: 1;
}
.XbiU27Ty ._cUukzOA {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.XbiU27Ty .SePn3Tvz {
  font-size: 18px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 21px;
  margin-top: 18px;
  margin-bottom: 86px;
}
.XbiU27Ty .Cn2dHLuS {
  font-size: 12px;
  color: #adaeb4;
}
.khgBTj9w {
  padding-top: 24px;
}
.khgBTj9w .UNcZP0Ik {
  width: 95px;
  height: 28px;
  padding-top: 0;
  padding-bottom: 0;
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
.khgBTj9w .UNcZP0Ik:focus {
  color: var(--main-color);
  border: 1px solid var(--main-color);
}
.khgBTj9w .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.khgBTj9w .ant-btn-primary {
  background: var(--main-color);
  color: var(--white);
  margin-right: 30px;
}
.khgBTj9w .ant-btn:hover,
.khgBTj9w .ant-btn:active {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.khgBTj9w .ant-switch {
  background-color: var(--btn-grey1-bg);
  background-image: none;
}
.khgBTj9w .ant-switch-checked {
  background-color: var(--main-color);
}
.khgBTj9w .ant-switch-handle::before {
  background-color: var(--tab-active-bg);
}
.khgBTj9w .ant-table-content {
  min-height: 235px;
}
.khgBTj9w .Ure8ibSs {
  margin-right: 16px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.khgBTj9w .jTRlEiWc {
  display: flex;
  align-items: center;
}
.khgBTj9w .Tzs8IqEA {
  padding: 3px 16px;
  height: 28px;
  text-align: center;
  display: inline-block;
  background: var(--btn-grey-bg);
  border-radius: 14px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 22px;
}
.khgBTj9w .iQWkKsnA {
  min-height: 600px;
  margin-bottom: 30px;
}
.EkRCdrXv .ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  border-top: none;
  padding: 0;
}
.EkRCdrXv .ant-modal-footer button:first-child {
  display: inline-block !important;
  margin-left: 16px;
  order: 1;
}
.EkRCdrXv .ant-modal-footer .ant-btn {
  cursor: pointer;
  width: 108px;
  background: var(--main-bg-color);
  height: 40px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  border: 1px solid var(--btn-border);
  border-radius: 4px;
  margin-top: 20px;
}
.EkRCdrXv .ant-modal-footer .ant-btn-primary {
  background: var(--main-color) !important;
  color: var(--white);
}
.EkRCdrXv .ant-modal-footer .ant-btn-primary:hover {
  background: var(--main-btn-hover-color) !important;
}
.EkRCdrXv .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  width: 108px;
}
.aqip_EX4 .vG05vdPy {
  font-family: var(--CommonRegular);
  counter-reset: var(--text-color);
  margin-bottom: 16px;
}
.aqip_EX4 .X8AOAciQ {
  margin-bottom: 16px;
  font-family: var(--CommonMedium);
  color: var(--white);
}
.aqip_EX4 .CkYfRM32 p {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.aqip_EX4 .CkYfRM32 p .D089DBNo {
  font-family: var(--CommonRegular);
  color: var(--text-color);
}
.aqip_EX4 .CkYfRM32 p .VrZdp0fx {
  color: var(--white);
  font-family: var(--CommonMedium);
}
.aqip_EX4 .l9aNQygn {
  display: flex;
  flex-direction: row;
}
.aqip_EX4 .l9aNQygn .VU0BD_Gb {
  color: var(--white);
  margin-right: 8px;
}
.TyCjOosl {
  font-family: var(--CommonMedium);
  color: var(--white);
}
.TyCjOosl .ant-btn {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border-radius: 2px;
  border: 1px solid var(--main-color);
}
.TyCjOosl .ant-btn-primary {
  color: var(--white);
  margin-right: 30px;
}
.TyCjOosl .ant-btn:hover,
.TyCjOosl .ant-btn:active {
  background: var(--main-color);
  color: var(--white);
  transition: none;
}
.TyCjOosl .__8FEZYofc {
  margin-top: 19px;
}
.TyCjOosl .__8FEZYofc .eQ9UksQQ {
  position: absolute;
  top: -5px;
  left: -5px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n {
  padding: 41px 103px;
  background-color: var(--module-bg);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .mD6V9dd_ {
  font-size: 22px;
  color: var(--white);
  margin-bottom: 36px;
  display: flex;
  align-items: center;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .mD6V9dd_ .Ure8ibSs {
  margin-right: 16px;
  width: 40px;
  height: 40px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof {
  display: flex;
  justify-content: space-between;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .NYmUAumm {
  font-size: 16px;
  color: var(--white);
  margin-bottom: 20px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .M1UQPb_C {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .M1UQPb_C .__0A2r0ftE {
  color: var(--text-color);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .M1UQPb_C .azeRYjmq {
  color: var(--white);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt {
  width: 42%;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .oRM9Tb8C {
  font-size: 16px;
  color: var(--white);
  margin-bottom: 8px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .__42_3p0EQ {
  font-size: 24px;
  color: var(--white);
  font-family: var(--CommonBold);
  margin-bottom: 24px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .PHdlcTtm {
  display: flex;
  position: relative;
  background-color: var(--main-bg-color);
  padding-right: 19px;
  border-radius: 2px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .PHdlcTtm .__50p13ZtW {
  width: 100%;
  background-color: var(--main-bg-color);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .PHdlcTtm .__50p13ZtW input::-webkit-outer-spin-button,
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .PHdlcTtm .__50p13ZtW input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .PHdlcTtm .AGMHMpM5 {
  position: absolute;
  right: 19px;
  height: 40px;
  line-height: 40px;
  color: var(--main-color);
  cursor: pointer;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .M3fJAT0W {
  color: var(--tip-text-color);
  margin-bottom: 14px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .l9aNQygn .VU0BD_Gb {
  margin: 0 9px 0 16px;
  color: var(--white);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .o0JFTFMt .yju5mPm9 {
  margin-top: 56px;
  display: flex;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL {
  width: 50%;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 {
  font-size: 14px;
  display: flex;
  margin-bottom: 20px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .eZ4Oj0Lt {
  width: 29%;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .eZ4Oj0Lt .BT9_lgnY {
  margin-bottom: 24px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: var(--btn-green-bg);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .eZ4Oj0Lt .BT9_lgnY .pDxTDo1n {
  text-align: start;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .gVJvvDcu {
  width: 39%;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .gVJvvDcu .c_J8MuuZ {
  margin-bottom: 24px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: var(--btn-red-bg);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .gVJvvDcu .r8I3__l5 {
  text-align: center;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .HGS7tdXc {
  width: 32%;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .HGS7tdXc .oRM9Tb8C {
  margin-bottom: 24px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: var(--main-color);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .LTZ6FHz5 .HGS7tdXc .__2BKBgL_S {
  text-align: end;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z {
  margin: 22px 0;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr {
  margin-top: 14px;
  font-size: 12px;
  display: flex;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr .Nz1ExWbc .ant-row {
  width: 130px;
  height: 32px;
  align-items: center;
  padding-left: 10px;
  border-right: 1px solid var(--module-bg);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr .Nz1ExWbc .ant-row:nth-child(odd) {
  background: var(--main-bg-color);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr .cS_72amM {
  width: calc(100% - 130px);
  color: var(--white);
  overflow-x: scroll;
  /* 设置滚动条的样式 */
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr .cS_72amM::-webkit-scrollbar {
  height: 3px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr .cS_72amM .ant-row {
  flex-wrap: nowrap;
  height: 32px;
  align-items: center;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr .cS_72amM .ant-row > .ant-col {
  flex: 1 1;
  flex-basis: 105px;
  flex-shrink: 0;
  text-align: center;
  line-height: 32px;
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr .cS_72amM .ant-row > .ant-col:not(:first-child) {
  border-left: 1px solid var(--module-bg);
}
.TyCjOosl .__8FEZYofc .wIRkNY8n .nWzcomof .jkdUwXaL .__8ogtlp9z .HH02v1Zr .cS_72amM .ant-row:nth-child(odd) > .ant-col {
  background: var(--main-bg-color);
}
.K4pDZ1WO .uOhykmGE ._cUukzOA {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.K4pDZ1WO .uOhykmGE ._cUukzOA .quO5An1w {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  margin-right: 10px;
}
.K4pDZ1WO .uOhykmGE ._cUukzOA .nFA7KnW3 {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
}
.GT4tb0mf {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.GT4tb0mf .yogQrEpB {
  margin-left: 6px;
  margin-right: 12px;
}
.GT4tb0mf .k38zliHX {
  text-decoration: line-through;
  color: var(--remark-text-color);
}
.cWobm_gF .__1M_Qzw {
  width: 100%;
  display: flex;
  height: 26px;
  color: var(--white);
}
.cWobm_gF .__1M_Qzw > div {
  flex: 1 1;
  text-align: center;
}
.cWobm_gF .__1M_Qzw div.__0FhLHlFV {
  background: var(--btn-green-bg);
}
.cWobm_gF .__1M_Qzw div.luyi_Nv9 {
  background: var(--btn-red-bg);
}
.cWobm_gF .__1M_Qzw div.auWnhAVN {
  background: var(--main-color);
}
.cWobm_gF .UaSyWRpU {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  font-size: 12px;
  margin-bottom: 10px;
}
.cWobm_gF .UaSyWRpU > div {
  flex: 1 1;
}
.cWobm_gF .UaSyWRpU > div > div.nWzcomof {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.cWobm_gF .lgPV1V8B {
  margin-top: 14px;
  padding: 24px 22px;
  background-color: var(--module-bg);
  color: var(--remark-text-color);
}
.cWobm_gF .lgPV1V8B > p:not(:first-child) {
  margin-top: 30px;
}
.cWobm_gF .l9aNQygn {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.cWobm_gF .l9aNQygn .VU0BD_Gb {
  color: var(--white);
  margin-left: 16px;
}
.cWobm_gF .l9aNQygn .VU0BD_Gb.uofWnVs2 {
  color: var(--text-color);
}
.LlxnB8Xl {
  display: flex;
}
.LlxnB8Xl .k1d_7yai {
  width: 500px;
  height: 238px;
}
.LlxnB8Xl .pcN_rhdP {
  cursor: pointer;
}
.LlxnB8Xl .__2ddPUux_ {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}
.LlxnB8Xl .PIegL1cI {
  display: flex;
  height: 74px;
  margin-top: 8px;
  padding: 14px 24px;
  background: var(--module-bg);
}
.LlxnB8Xl .PIegL1cI:first-child {
  margin-top: 0px;
}
.LlxnB8Xl .__52Tb2SIw {
  font-family: var(--CommonBold);
  font-size: 16px;
  color: var(--text-color);
}
.LlxnB8Xl .D7y72Pv_ {
  margin-top: 4px;
  font-size: 12px;
  color: var(--remark-text-color);
}
.LlxnB8Xl .UNcZP0Ik {
  align-self: center;
  padding: 0;
  font-size: 14px;
  width: 119px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid var(--main-color);
  color: var(--white);
  background: var(--main-color);
}
.LlxnB8Xl .UNcZP0Ik:hover {
  background: var(--main-btn-hover-color);
}
.uM8Fsg58 {
  color: var(--white);
  font-size: 16px;
}
.uM8Fsg58 .ant-input-group-addon {
  padding: 0 11px 0 0;
  font-size: 14px;
  font-family: var(--CommonMedium);
  color: var(--main-color);
  background: var(--main-bg-color);
  cursor: pointer;
}
.uM8Fsg58 .ant-input-group-wrapper {
  background: var(--main-bg-color);
  border-radius: 4px;
}
.uM8Fsg58 .ant-input-affix-wrapper {
  padding: 0 11px;
  background: var(--main-bg-color);
}
.uM8Fsg58 .ant-input-affix-wrapper:focus,
.uM8Fsg58 .ant-input-affix-wrapper-focused {
  border-width: 0;
  box-shadow: none;
}
.uM8Fsg58 .ant-input-suffix {
  font-family: var(--CommonMedium);
  color: var(--white);
}
.uM8Fsg58 .DFvXWPo1 {
  width: 1px;
  height: 14px;
  margin-right: 11px;
  background: var(--white);
  align-self: center;
  opacity: 0.1;
}
.uM8Fsg58 .__8FEZYofc {
  margin-top: 19px;
  padding: 40px 80px;
  background-color: var(--module-bg);
}
.uM8Fsg58 .__8FEZYofc .__95yaqrxa {
  display: flex;
}
.uM8Fsg58 .__8FEZYofc .pmM8f_am {
  flex: 1 1;
}
.uM8Fsg58 .__8FEZYofc .eWh0AoEJ {
  flex: 1 1;
  padding-left: 80px;
}
.uM8Fsg58 .mD6V9dd_ {
  padding-bottom: 31px;
  font-size: 22px;
  font-family: var(--CommonMedium);
}
.uM8Fsg58 .lfVddJnR {
  width: 480px;
  height: 60px;
  margin-top: 24px;
  font-size: 18px;
  color: var(--main-color);
  border: 1px dashed var(--main-color);
  border-radius: 4px;
  padding: 18px 180px;
  cursor: pointer;
}
.uM8Fsg58 .Biu5t9U_ {
  width: 480px;
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.uM8Fsg58 .Biu5t9U_ .LQlKIMhv {
  width: 431px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  background: var(--main-bg-color);
  font-family: var(--CommonBold);
  font-size: 18px;
}
.uM8Fsg58 .Biu5t9U_ .LQlKIMhv .PB7RxPxD {
  width: 80px;
  margin-left: 12px;
  cursor: pointer;
}
.uM8Fsg58 .Biu5t9U_ .LQlKIMhv .bhS_y5d9 {
  font-size: 10px;
  cursor: pointer;
}
.uM8Fsg58 .Biu5t9U_ .LQlKIMhv .HL21J0Yz {
  margin-left: 40px;
  color: var(--border-color2);
}
.uM8Fsg58 .Biu5t9U_ .LQlKIMhv .f_wVjUmQ {
  width: 170px;
  margin-left: 20px;
  margin-right: 20px;
}
.uM8Fsg58 .Biu5t9U_ .LQlKIMhv .lRQv_XiF {
  font-family: var(--CommonBold);
  font-size: 18px;
  text-align: center;
}
.uM8Fsg58 .Biu5t9U_ .LQlKIMhv .fGyGQdu7 {
  font-size: 22px;
}
.uM8Fsg58 .Biu5t9U_ .h4BOLJBR {
  background: var(--main-bg-color);
  font-family: var(--CommonBold);
  font-size: 18px;
  margin-left: 14px;
  padding: 6px 10px;
  border-radius: 19px;
  cursor: pointer;
}
.uM8Fsg58 .SJzLTIwS {
  color: var(--red-text-color);
  font-size: 14px;
}
.uM8Fsg58 .Fk0L4Dhd {
  position: relative;
  font-size: 16px;
  height: 89px;
  margin-top: 16px;
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid var(--main-color);
  background: var(--main-bg-color);
  cursor: pointer;
}
.uM8Fsg58 .Fk0L4Dhd .CiYV623K {
  font-family: var(--CommonBold);
}
.uM8Fsg58 .Fk0L4Dhd .ZPHk_6sy {
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
  color: var(--remark-text-color);
}
.uM8Fsg58 .Fk0L4Dhd .XpbhjMGE {
  position: absolute;
  top: 0;
  right: 0;
}
.uM8Fsg58 .dlf_rhLZ {
  width: 100%;
  margin-top: 8px;
  font-size: 14px;
  color: var(--text-color) !important;
  background-color: var(--main-bg-color);
}
.uM8Fsg58 .u4gAWsHY {
  margin: 24px 0 18px 0;
}
.uM8Fsg58 .oZlmtVgX {
  font-family: var(--CommonMedium);
  font-size: 32px;
}
.uM8Fsg58 .D76nyfEL {
  color: var(--main-color);
  margin-top: 8px;
}
.uM8Fsg58 .R6f0HfNX {
  margin: 40px 0 8px 0;
}
.uM8Fsg58 .b_5JL8Ky {
  margin: 24px 0 8px 0;
}
.uM8Fsg58 .U7BTFzDy {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.uM8Fsg58 ._LV7tcvi {
  font-family: var(--CommonRegular);
  font-size: 12px;
  color: var(--remark-text-color);
}
.uM8Fsg58 .__95yaqrxa {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 4px 0;
}
.uM8Fsg58 .__95yaqrxa .tp65MYGR {
  display: flex;
  align-items: center;
  color: var(--remark-text-color);
}
.__2aaLcN8g {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.__2aaLcN8g .Biu5t9U_ {
  align-items: center;
  width: 46%;
  padding: 10px 17px;
  margin-bottom: 16px;
  display: flex;
  background: var(--module-bg);
  cursor: pointer;
}
.__2aaLcN8g .Biu5t9U_ .jTRlEiWc {
  flex: 1 1;
  font-family: var(--CommonBold);
  font-size: 18px;
  margin-left: 15px;
}
.__2aaLcN8g .Biu5t9U_ .J2XeY5FF {
  font-size: 18px;
}
.__8aJ9_iXu .__95yaqrxa {
  display: flex;
}
.__8aJ9_iXu .__9TMFRrXN {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 8px 0;
}
.__8aJ9_iXu .__9TMFRrXN .tp65MYGR {
  color: var(--remark-text-color);
}
.__8aJ9_iXu .__9TMFRrXN .QJDKb0_I {
  color: var(--white);
  font-family: var(--CommonMedium);
}
.mbmMnT_w {
  padding-top: 24px;
  width: 100%;
}
.mbmMnT_w button.UNcZP0Ik {
  width: 100% !important;
  padding: 16px 24px !important;
  background: var(--module-bg) !important;
  border-radius: 4px !important;
  border: none !important;
  height: auto !important;
}
.mbmMnT_w button.UNcZP0Ik:not(:last-child) {
  margin-bottom: 16px !important;
}
.mbmMnT_w .__9DzqPR27 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mbmMnT_w .__9DzqPR27 .HJk51QS2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.mbmMnT_w .__9DzqPR27 .__52Tb2SIw {
  font-family: var(--CommonBold);
  font-size: 16px;
  color: var(--text-color);
}
.mbmMnT_w .__9DzqPR27 .D7y72Pv_ {
  margin-top: 8px;
  font-size: 12px;
  color: var(--remark-text-color);
  word-break: break-word;
  white-space: normal;
  text-align: left;
}
.KOEaOGDB {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  margin-bottom: 15px;
}
.__9Y5sEkTA {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.__9Y5sEkTA:nth-child(even) {
  background-color: var(--tab-active-bg);
}
.d5mubJVh {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.__2CH4kUzO {
  margin-left: 17px;
  display: flex;
  flex-direction: column;
}
.tfdHVoWN {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 19px;
}
.tfdHVoWN .qEql4GmE {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
}
.hsLGTL5N {
  width: 1px;
  background-color: var(--divider-color);
}
.lziS27MI {
  flex: 1 1;
  padding: 7px 0;
  text-align: center;
  font-size: 10px;
  font-family: var(--CommonRegular);
  font-weight: 500;
  color: var(--text-color);
  line-height: 22px;
  margin-right: 1px;
}
.arlm_Dmz {
  flex: 1 1;
  padding: 7px 0;
  text-align: center;
  font-size: 10px;
  font-family: var(--CommonRegular);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
}
.WCmbQNgA {
  display: flex;
  align-items: center;
}
.__6AWxLrKR {
  margin-top: 16px;
  font-family: var(--CommonMedium);
  font-size: 14px;
  line-height: 22px;
  color: var(--white);
}
.t5PbLI3e {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
  padding-top: 24px;
}
.t5PbLI3e:hover {
  color: var(--main-text-hover-color);
}
.ysq0yUtr {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 600px;
}
.ysq0yUtr .Sh3GbXee {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 50px;
  margin-top: 28px;
}
.ysq0yUtr .ant-btn {
  border-radius: 20px;
  margin-right: 0;
}
.CVkhmya5 .vP9Id4pS {
  background-color: var(--main-bg-color);
  margin: -33px -24px 0;
  height: 16px;
}
.CVkhmya5 .nWzcomof {
  padding-top: 32px;
  padding-bottom: 60px;
  display: flex;
}
.CVkhmya5 .nWzcomof .pmM8f_am {
  flex: 418 1;
  padding: 0 22px;
}
.CVkhmya5 .nWzcomof .EWzv6S19 {
  width: 1px;
  height: 249px;
  background-color: var(--btn-type1-bg);
  flex: 0 0 auto;
  align-self: flex-end;
}
.CVkhmya5 .nWzcomof .eWh0AoEJ {
  flex: 782 1;
  padding: 0 22px;
  margin-bottom: -45px;
}
.CVkhmya5 .nWzcomof .eWh0AoEJ .q5vS1mUO {
  color: var(--grey-text-color2);
  font-family: var(--CommonMedium);
  font-size: 18px;
  line-height: 18.495px;
  margin-bottom: 38px;
}
.CVkhmya5 .nWzcomof .eWh0AoEJ .h9p9HCSC {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CVkhmya5 .nWzcomof .eWh0AoEJ .h9p9HCSC .VU0STaBg {
  display: flex;
  align-items: center;
}
.CVkhmya5 .nWzcomof .eWh0AoEJ .h9p9HCSC .VU0STaBg:nth-child(n + 2) {
  margin-left: 47px;
}
.CVkhmya5 .nWzcomof .eWh0AoEJ .h9p9HCSC .VU0STaBg .__1ToLI4W_ {
  flex: 0 0 auto;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #f17e00;
}
.CVkhmya5 .nWzcomof .eWh0AoEJ .h9p9HCSC .VU0STaBg .VCZyv34S {
  color: var(--white);
  font-size: 12px;
  line-height: 18px;
  margin-left: 12px;
}
.DkmGxcL_ .mD6V9dd_ {
  font-family: var(--CommonBold);
  font-size: 24px;
  color: var(--grey-text-color2);
}
.DkmGxcL_ .__7m5JfpI4 {
  display: flex;
  align-items: center;
  margin-top: 38px;
}
.DkmGxcL_ .__7m5JfpI4 .Qsi5H_Oh {
  margin-left: 8.7px;
}
.DkmGxcL_ .__7m5JfpI4 .Qsi5H_Oh .PB7RxPxD {
  font-family: var(--CommonBold);
  font-size: 18px;
  line-height: 22px;
  color: var(--white-op3);
}
.DkmGxcL_ .__7m5JfpI4 .Qsi5H_Oh .jTRlEiWc {
  font-size: 14px;
  color: var(--white-op3);
  margin-top: 4px;
}
.DkmGxcL_ .__7m5JfpI4 ._Ss5OXtR {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.DkmGxcL_ .__7m5JfpI4 ._Ss5OXtR .vxWvXRol {
  font-family: var(--CommonBold);
  font-size: 24px;
  color: var(--green-text-color);
}
.DkmGxcL_ .__7m5JfpI4 ._Ss5OXtR .Wwls8Z5S {
  font-family: var(--CommonBold);
  font-size: 24px;
  color: var(--red-text-color);
}
.DkmGxcL_ .__7m5JfpI4 ._Ss5OXtR .__87NEPOgK {
  display: flex;
  align-items: center;
}
.DkmGxcL_ .__7m5JfpI4 ._Ss5OXtR .__87NEPOgK .da_8jVe9 {
  font-size: 14px;
  margin-top: 4px;
  color: var(--white-op1);
}
.DkmGxcL_ .e_VtD9tM {
  font-family: var(--CommonMedium);
  font-size: 18px;
  color: var(--white);
  margin-top: 71px;
}
.DkmGxcL_ .tr3rWJZJ {
  font-size: 15px;
  color: var(--white-op3);
  margin-top: 24px;
}
.DkmGxcL_ .axTF2kUT {
  font-size: 15px;
  color: var(--white-op3);
  margin-top: 16px;
}
.DkmGxcL_ .stAhlkKu {
  display: flex;
  margin-top: 40px;
}
.DkmGxcL_ .stAhlkKu .vPRVnCk9 {
  width: 128px;
  height: 40px;
}
.DkmGxcL_ .stAhlkKu .vPRVnCk9:nth-child(n + 2) {
  margin-left: 14px;
}
.DkmGxcL_ .GjKs0U_d {
  font-size: 12px;
  line-height: 18px;
  color: var(--white-op1);
  margin-top: 32px;
}
.ZenHhqoI {
  background: #000000;
  height: 250px;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.lw8xddkQ {
  display: flex;
  align-items: center;
}
.lw8xddkQ .O2o4cj72 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: unset !important;
}
.lw8xddkQ .O2o4cj72 .QkO18f5M {
  font-weight: bold;
  padding-right: 24px;
  display: flex;
  flex: 1 1;
  width: 0;
}
.lw8xddkQ .O2o4cj72 .QkO18f5M .urpKp4Mf {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.lw8xddkQ p {
  color: var(--white);
}
.lw8xddkQ .anticon svg {
  color: var(--white);
}
.HasagWHj {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 98px !important;
}
.HasagWHj .__69j9bUsh {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.HasagWHj .__69j9bUsh .d4qRfTBT {
  font-size: 12px;
  line-height: 24px;
  margin-top: 8px;
  color: var(--white-op5);
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.__3z3sMBJe {
  width: 100%;
  background-color: var(--white);
  color: var(--black);
}
.uPNR_0PS {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.uPNR_0PS .T7M3kErc {
  display: flex;
}
.uPNR_0PS .Qx_Xb8Ko {
  align-items: center;
  font-size: 14px;
  color: var(--white);
}
.uPNR_0PS .Qx_Xb8Ko .__8_P84y1T {
  cursor: pointer;
}
.uPNR_0PS .Qx_Xb8Ko .__8_P84y1T .logout-dropdown {
  top: 55px !important;
}
.uPNR_0PS .Qx_Xb8Ko .__8_P84y1T .logout-dropdown .ant-dropdown-menu-item {
  min-width: 112px;
  height: 46px;
  display: flex;
  align-items: center;
  font-family: var(--CommonMedium);
  color: var(--white);
}
.uPNR_0PS .Qx_Xb8Ko .__8_P84y1T .logout-dropdown .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
}
.uPNR_0PS .mdSYxHiR {
  margin-right: 22px;
  cursor: pointer;
}
.__7k0tP5xg {
  margin-top: 23px;
}
.BcstMrF6 {
  margin-top: 17px;
}
.HE_MR6vf {
  margin-left: 34px;
}
.RpoQ5c54 {
  margin-left: 51px;
}
.nEFp0ohr {
  margin-bottom: 19px;
}
.MIQepVud {
  margin-bottom: 36px;
}
.jCZ281if {
  font-family: var(--CommonBold);
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 64px;
  text-align: center;
  color: var(--black);
}
.eMiSZgoM {
  font-family: var(--CommonRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  color: var(--black);
}
.VUprvvwa {
  font-family: var(--CommonRegular);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  color: var(--white);
}
.K4IxUlI3 {
  font-family: var(--CommonBold);
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 50px;
  color: var(--black);
  margin-left: 24px;
}
.__544Db4go {
  font-family: var(--CommonRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  color: var(--black);
}
.sYEg8g3A {
  font-family: var(--CommonBold);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 36px;
  color: var(--black);
}
.FHspiXXd {
  font-family: var(--CommonRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--black);
}
.t5bmp9UU {
  width: 100%;
  min-height: 695px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/images/download_web_bg@2x.webp");
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.t5bmp9UU .uDhyEgOe {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.t5bmp9UU .WGwIhYF4 {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ShpH_H_j {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
}
.ShpH_H_j > div {
  width: 100%;
}
.ShpH_H_j .hjGOdaqS {
  width: 100%;
  background-color: var(--main-bg-color);
  display: flex;
  align-items: center;
  padding: 17px 67px;
  justify-content: center;
}
.ShpH_H_j .hjGOdaqS .__7nH_a3pv {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ShpH_H_j .hjGOdaqS .__53D9z5Kg {
  flex: 0 1;
}
.ShpH_H_j .hjGOdaqS .__53D9z5Kg .d_7JObHl {
  font-size: 28px;
  color: var(--white);
}
.u0tgnqCI {
  flex: 1 1;
}
.__0FVYyess {
  padding: 94px 0;
}
.__0FVYyess .fFkQUbtg {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding: 0 94px;
}
.__0FVYyess .qv5amYCk {
  padding: 0 80px 0 107px;
}
.__0FVYyess .IoIjiAZe {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.__0FVYyess .IoIjiAZe .sBom1N6f {
  display: flex;
  flex-direction: row;
  padding: 70px 0 81px 116px;
}
.__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 3),
.__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 4) {
  background: var(--module-bg);
}
.__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 3) .sYEg8g3A,
.__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 4) .sYEg8g3A,
.__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 3) .FHspiXXd,
.__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 4) .FHspiXXd {
  color: var(--white);
}
.__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(even) {
  padding-right: 94px;
}
.__0FVYyess .IoIjiAZe .sBom1N6f .gewYvRXv {
  display: flex;
  align-items: flex-start;
}
.__0FVYyess .IoIjiAZe .sBom1N6f .d2PwE2YV {
  margin-top: 12px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--main-color);
  margin-right: 14px;
}
.__0FVYyess .IoIjiAZe .sBom1N6f .K0J3Eaw_ {
  flex: 0 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.__0FVYyess .IoIjiAZe .sBom1N6f .__4xv2iWiB {
  flex: 1 1;
  display: flex;
}
@media (max-width: 1200px) {
  .ant-layout-header {
    min-width: 0;
  }
  .jCZ281if {
    font-size: 24px;
    line-height: 31px;
  }
  .eMiSZgoM {
    font-size: 16px;
    line-height: 21px;
  }
  .VUprvvwa {
    font-size: 14px;
    line-height: 18px;
  }
  .K4IxUlI3 {
    font-size: 18px;
    line-height: 20px;
    margin-left: 8px;
  }
  .__544Db4go {
    font-size: 14px;
    line-height: 21px;
  }
  .sYEg8g3A {
    font-size: 16px;
    line-height: 18px;
  }
  .FHspiXXd {
    font-size: 14px;
    line-height: 21px;
  }
  .HE_MR6vf {
    margin-left: 12px;
  }
  .RpoQ5c54 {
    margin-left: 48px;
  }
  .MIQepVud {
    margin-bottom: 10px;
  }
  .t5bmp9UU {
    min-height: 448px;
    background-image: url("/images/download_app_bg@3x.webp");
  }
  .t5bmp9UU .uDhyEgOe {
    margin-top: 38px;
    padding: 0 37px;
  }
  .t5bmp9UU .WGwIhYF4 {
    margin: 49px 0 21px 0;
  }
  .ShpH_H_j .hjGOdaqS {
    padding: 10px 20px;
  }
  .ShpH_H_j .hjGOdaqS .__53D9z5Kg .d_7JObHl {
    font-size: 16px;
  }
  .__0FVYyess {
    padding: 24px 0 0 0;
  }
  .__0FVYyess .fFkQUbtg {
    margin-bottom: 18px;
    padding: 0 24px;
  }
  .__0FVYyess .qv5amYCk {
    padding: 0 24px;
  }
  .__0FVYyess .IoIjiAZe {
    margin-top: 29px;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f {
    flex-direction: column;
    padding: 24px;
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 1) {
    background: var(--module-bg);
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 1) .sYEg8g3A,
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 1) .FHspiXXd {
    color: var(--white);
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 3) {
    background: var(--main-color);
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 3) .d2PwE2YV {
    background-color: var(--white);
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 3) .sYEg8g3A,
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 3) .FHspiXXd {
    color: var(--white);
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 2),
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 4) {
    background: var(--white);
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 2) .sYEg8g3A,
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 4) .sYEg8g3A,
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 2) .FHspiXXd,
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(4n + 4) .FHspiXXd {
    color: var(--black);
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f:nth-child(even) {
    padding-right: 24px;
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f .d2PwE2YV {
    margin-top: 8px;
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f .K0J3Eaw_ {
    min-width: 0;
    margin-top: 26px;
  }
  .__0FVYyess .IoIjiAZe .sBom1N6f .K0J3Eaw_ img {
    width: 168px !important;
    height: 343px !important;
    min-width: 0 !important;
    min-height: 0 !important;
  }
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.Ujbj_gAB {
  width: 100%;
  min-height: 670px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/images/image_bg@2x.webp");
}
.Ujbj_gAB label {
  z-index: 0;
  position: relative;
  padding-left: 11px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
}
.Ujbj_gAB label span {
  color: var(--input-red-bg);
}
.Ujbj_gAB .ant-form {
  display: table;
  width: 100%;
}
.Ujbj_gAB .ant-form-vertical .ant-form-item {
  margin-bottom: 24px;
  position: relative;
}
.Ujbj_gAB .ant-form-item-control {
  height: 60px;
  background: var(--main-bg-color);
  position: relative;
}
.Ujbj_gAB .ant-form-item-control-input {
  min-height: 60px;
}
.Ujbj_gAB .ant-form-item {
  line-height: 60px;
}
.Ujbj_gAB .ant-input {
  position: absolute;
  height: 60px;
  left: 0;
  top: 0;
  border-radius: 0;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  padding-top: 35px;
  padding-bottom: 13px;
  background: transparent;
}
.Ujbj_gAB .ant-input:hover {
  opacity: 0.35;
}
.Ujbj_gAB .ant-input[disabled] {
  color: var(--white);
}
.Ujbj_gAB .ant-form-item-explain-error {
  font-size: 12px;
  padding-top: 3px;
}
.Ujbj_gAB .ant-form-item-margin-offset {
  margin-bottom: 0 !important;
}
.Ujbj_gAB .__8WVyJ2dt {
  height: calc(100vh - 52px);
  min-height: 670px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx {
  position: relative;
  max-width: 960px;
  width: 100%;
  height: 670px;
  margin: auto;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 50% 50%;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .onNeB8Fl {
  height: 670px;
  background: var(--white);
  border-radius: 4px 0px 0px 4px;
  padding: 48px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .onNeB8Fl .hYDhtDtF {
  width: 158px;
  height: 73px;
  margin-bottom: 24px;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .onNeB8Fl .Jkw8DGv7 {
  font-size: 18px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--black-text-color);
  line-height: 21px;
  margin-bottom: 41px;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .cmHH4KFN {
  height: 670px;
  background: var(--black);
  border-radius: 4px 0px 0px 4px;
  padding: 48px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .cmHH4KFN .hYDhtDtF {
  width: 158px;
  height: 73px;
  margin-bottom: 24px;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .cmHH4KFN .Jkw8DGv7 {
  font-size: 18px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 21px;
  margin-bottom: 41px;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .__1WAXm7Rp {
  height: 670px;
  background: var(--blue-level1);
  border-radius: 4px 0px 0px 4px;
  padding: 48px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .__1WAXm7Rp .hYDhtDtF {
  width: 158px;
  height: 73px;
  margin-bottom: 24px;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .__1WAXm7Rp .Jkw8DGv7 {
  font-size: 18px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  line-height: 21px;
  margin-bottom: 41px;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .JVgWgtJX {
  position: absolute;
  right: 9.7%;
  /*136px*/
  position: relative;
  right: 0;
  border-radius: 0 4px 4px 0;
  background: var(--grey-level1);
  height: 670px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .JVgWgtJX .__9RzuCWBd {
  width: 80%;
  margin: 0 auto;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .JVgWgtJX .__9RzuCWBd .vNDlEprF {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .JVgWgtJX .__9RzuCWBd .vNDlEprF .UZNzOC6h {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  cursor: pointer;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .JVgWgtJX .__9RzuCWBd .vNDlEprF .UZNzOC6h:hover {
  color: var(--main-color);
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .JVgWgtJX .__9RzuCWBd .wjZA_weF {
  color: var(--main-color);
  padding: 0 5px;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .JVgWgtJX .__9RzuCWBd .dcptNIj6 {
  opacity: 1;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .dtc7vltz {
  position: absolute;
  right: 9.7%;
  /*136px*/
  position: relative;
  right: 0;
  border-radius: 0 4px 4px 0;
  color: var(--black);
  background: var(--white);
  height: 670px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .dtc7vltz .__9RzuCWBd {
  width: 80%;
  background: var(--white);
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .dtc7vltz .__9RzuCWBd .Jkw8DGv7 {
  color: var(--black);
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .dtc7vltz .__9RzuCWBd label {
  position: relative;
  padding-left: 11px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--black-text-color) !important;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .dtc7vltz .__9RzuCWBd .dcptNIj6 {
  opacity: 1;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .dtc7vltz .__9RzuCWBd .ant-form {
  display: table;
  width: 100%;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .dtc7vltz .__9RzuCWBd .ant-btn-primary {
  border: none !important;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .dtc7vltz .__9RzuCWBd .ant-form-vertical .ant-form-item {
  margin-bottom: 32px;
  position: relative;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .dtc7vltz .__9RzuCWBd .ant-btn-primary[disabled] {
  color: var(--white) !important;
  background-color: var(--input-white-disabled-bg) !important;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .dtc7vltz .__9RzuCWBd .ant-btn-primary[disabled]:hover {
  color: var(--white) !important;
  background-color: var(--input-white-disabled-bg) !important;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .dtc7vltz .__9RzuCWBd .ant-form-item-control {
  height: 60px;
  background: var(--input-white-bg);
  position: relative;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .dtc7vltz .__9RzuCWBd .ant-form-item-control-input {
  min-height: 60px;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .dtc7vltz .__9RzuCWBd .ant-form-item-control-input-content {
  background: var(--input-white-bg);
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .dtc7vltz .__9RzuCWBd .ant-form-item {
  line-height: 60px;
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .dtc7vltz .__9RzuCWBd .ant-input {
  position: absolute;
  height: 60px;
  left: 0;
  top: 0;
  border-radius: 2px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--black) !important;
  padding-top: 35px;
  padding-bottom: 13px;
  background: transparent;
  -webkit-text-fill-color: var(--black);
  caret-color: var(--black);
}
.Ujbj_gAB .__8WVyJ2dt .CbXwUlFx .dtc7vltz .__9RzuCWBd .ant-form-item-explain-error {
  font-size: 12px;
  padding-top: 3px;
}
.Ujbj_gAB .YzEsQWDF {
  height: 64px;
  background: var(--module-bg);
  display: flex;
  align-items: center;
  padding: 0 24px 0;
}
.Ujbj_gAB .DZWaTMog {
  position: absolute;
  height: 60px;
  left: 12px;
  top: 10px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--white);
  background: transparent;
}
.Ujbj_gAB .hodn8EKl .BxMH9dXd {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  margin-bottom: 32px;
}
.Ujbj_gAB .hodn8EKl .vNDlEprF {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Ujbj_gAB .hodn8EKl .vNDlEprF .UZNzOC6h {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  margin-top: 40px;
  margin-bottom: 16px;
  cursor: pointer;
}
.Ujbj_gAB .hodn8EKl .vNDlEprF .UZNzOC6h:hover {
  color: var(--main-color);
}
.Ujbj_gAB .Jkw8DGv7 {
  font-family: var(--CommonBold);
  color: var(--white);
  margin-bottom: 24px;
  font-size: 22px;
  font-weight: 500;
  line-height: 36px;
}
.Ujbj_gAB .Y7McgIX_ {
  width: 100%;
  height: 56px;
  border-radius: 2px;
  font-size: 18px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
}
.Ujbj_gAB .ant-btn[disabled] {
  background: var(--login-btn-disabled-bg) !important;
}
.Ujbj_gAB .ant-btn[disabled]:hover {
  background: var(--login-btn-disabled-bg) !important;
}
.Ujbj_gAB .ant-btn {
  width: 100%;
  height: 56px;
  border-radius: 2px;
  font-size: 18px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 24px;
}
.Ujbj_gAB .IdniSFiw {
  width: 100%;
  background-color: var(--input-bg);
  height: 60px;
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  border: none;
}
.Ujbj_gAB .__821Kqm0M {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
}
.Ujbj_gAB .__609kJSGH {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 22px;
  cursor: pointer;
}
.Ujbj_gAB .__609kJSGH:hover {
  color: var(--main-color);
}
.Ujbj_gAB .bcna_1wA,
.Ujbj_gAB .k2VyTdub {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
  cursor: pointer;
}
.Ujbj_gAB .bcna_1wA:hover,
.Ujbj_gAB .k2VyTdub:hover {
  color: var(--main-color);
}
.Ujbj_gAB .k2VyTdub {
  color: var(--text-color);
}
.Ujbj_gAB .k2VyTdub:hover {
  color: var(--main-color);
}
.Ujbj_gAB .dO0vlOCl {
  display: grid;
  grid-template-columns: auto 116px;
  grid-gap: 16px;
}
.Ujbj_gAB .huUZ2GdO {
  display: flex;
  font-family: var(--CommonRegular);
  color: var(--text-color);
  font-size: 12px;
  margin-top: 16px;
}
.Ujbj_gAB .__2Cgb8FaO {
  display: flex;
  align-items: center;
  background-color: var(--error-text-bg);
  padding: 7px 16px;
  margin-bottom: 20px;
  border: 1px solid var(--error-text-border);
  border-radius: 2px;
}
.Ujbj_gAB .SBuSXPod {
  font-family: var(--CommonRegular);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--error-text);
  margin-left: 10px;
}
.Ujbj_gAB .xzdxhHZF {
  border: 1px solid var(--error-border);
}
._cPpA8lH {
  margin-bottom: 0 !important;
}
.__3rr294oT .ant-form-item {
  margin-bottom: 24px;
}
.__3rr294oT .ant-form-item-explain-error {
  margin-top: -2px;
  font-size: 12px;
}
.IiTNL8lN {
  padding: 60px 100px;
  background: var(--main-bg-color);
}
.IiTNL8lN .Jkw8DGv7 {
  font-family: var(--CommonBold);
  color: var(--white);
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}
.IiTNL8lN .nXxJX98y {
  font-family: var(--CommonRegular);
  color: var(--white);
  font-size: 14px;
}
.IiTNL8lN .nXxJX98y .sbiP6wN9 {
  font-family: var(--CommonBold);
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  text-decoration: underline;
}
.IiTNL8lN .nXxJX98y .LIAIJ_dw {
  text-indent: 22px;
}
.IiTNL8lN .nXxJX98y .zuu2X75O {
  text-indent: 42px;
}
@media (max-width: 768px) {
  .IiTNL8lN {
    padding: 30px;
    background: var(--black);
  }
}
.HnPxo7Rd ul.ant-dropdown-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}
.HnPxo7Rd .ant-dropdown-menu-item {
  min-width: 112px;
  height: 46px;
  display: flex;
  align-items: center;
  font-family: var(--CommonMedium);
  color: var(--white);
}
.HnPxo7Rd .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
  display: flex;
  align-items: center;
}
._a9QNUZc .hBxSSXXk {
  width: 24px;
  height: 2px;
  margin-top: 4px;
  background-color: transparent;
}
._a9QNUZc .hBxSSXXk.__198R8yuT {
  background-color: var(--main-color);
}
._a9QNUZc .ant-tabs-nav {
  height: 58px;
  margin: 0;
}
._a9QNUZc .ant-tabs-nav .ant-tabs-tab {
  font-family: var(--CommonMedium);
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.N4tTX9Hh {
  width: 262px !important;
  background-color: var(--input-bg) !important;
}
.__08K9ToX1 {
  width: 110px !important;
  background-color: var(--input-bg) !important;
  height: 60px !important;
  font-size: 14px !important;
  font-family: var(--CommonRegular) !important;
  color: var(--main-color) !important;
  line-height: 22px !important;
  border: none !important;
}
.vJtes15A {
  top: -15px !important;
  z-index: 1 !important;
  color: var(--black-text-color) !important;
}
.ciCuIHdl input:-webkit-autofill,
.ciCuIHdl input:-webkit-autofill:hover,
.ciCuIHdl input:-webkit-autofill:focus,
.ciCuIHdl input:-webkit-autofill:active,
.ciCuIHdl select:-webkit-autofill,
.ciCuIHdl textarea:-webkit-autofill,
.ciCuIHdl input:-internal-autofill-previewed,
.ciCuIHdl input:-internal-autofill-selected {
  box-shadow: inset 0 0 0 1000px var(--input-bg) !important;
}

[data-theme="mode-dark"] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #f17e00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #ff6500;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #2f3342;
  --module-bg: #1f212f;
  --card-bg: #26283a;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --table-expanded-bg: #202131;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #d4d4d8;
  --grey-text-color3: #9799a0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --border-color4: rgba(255, 255, 255, 0.1);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --black-opt1: rgba(0, 0, 0, 0.6);
  --black-opt2: rgba(0, 0, 0, 0.35);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595a65;
  --cms-table-bg: #0b101b;
  --cms-table-even-bg: rgba(255, 255, 255, 0.05);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme='mode-light'] {
  --menu-bg: transparent;
  --menu-highlight-color: #fff;
  --menu-item-color: #fff;
  /*--------------------------------- active -------------------------------------*/
  --main-color: #00ff00;
  --main-text-hover-color: #a45907;
  --main-btn-hover-color: #00ff00;
  --main-content-hover-color: rgba(241, 126, 0, 0.2);
  --main-content-hover-color1: rgba(241, 126, 0, 0.3);
  --main-content-hover-color2: rgba(241, 126, 0, 0.8);
  /*--------------------------------- 背景色 -------------------------------------*/
  --main-bg-color: #fff;
  --module-bg: #fff;
  --card-hover-bg: #363955;
  --table-heading-bg: #303343;
  --table-hover-bg: #2b2e43;
  --tab-active-bg: #26283a;
  --client-wallet-bg: #181a25;
  --app-bg: #141414;
  --app-kyc-label: #202020;
  --app-kyc-value: #4d4e57;
  --compliance-bg: rgba(38, 40, 58, 0.5);
  --button-bg: #3c3c3c;
  /*--------------------------------- message color -------------------------------------*/
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  /*--------------------------------- text color -------------------------------------*/
  --white: #fff;
  --text-color: #c7c7c7;
  --text-color-second: #c1c2c6;
  --text-color-third: #d5d6d9;
  --remark-text-color: #9799a0;
  --grey-text-color: #bebfc4;
  --grey-text-color1: #808080;
  --grey-text-color2: #D4D4D8;
  --grey-text-color3: #9799A0;
  --highlight-color1: #f99e3b;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --disabled-text-color: #6a6c75;
  --compliance-text: #82858e;
  --black: #000000;
  --black-text-color: #666666;
  --tip-text-color: #f76260;
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --error-border: #cb1719;
  --error-text: rgba(235, 21, 21, 0.8);
  --error-text-border: rgba(235, 21, 21, 0.6);
  --error-text-bg: rgba(235, 21, 21, 0.2);
  /*----------------------------------------- button -----------------------------------------*/
  --btn-border: #424555;
  --btn-disabled-text-color: #737580;
  --btn-disabled-border: #444342;
  --btn-disabled-bg: #383949;
  --login-btn-disabled-bg: #35373e;
  --btn-type-bg: rgba(241, 126, 0, 0.2);
  --btn-type1-bg: rgba(255, 255, 255, 0.1);
  --btn-grey-bg: #3d3e4f;
  --btn-grey1-bg: #606578;
  --btn-green-bg: #21bf73;
  --btn-red-bg: #fa5151;
  --news-banner-bg: rgba(31, 33, 47, 0.9);
  --btn-profile-bg: rgba(47, 51, 66, 0.5);
  /*--------------------------------------------- 分割线border ---------------------------------------*/
  --border-color: #2b2d3a;
  --border-color1: #3a3e4c;
  --border-color2: #505460;
  --border-color3: rgba(255, 255, 255, 0.2);
  --table-border-color: #313344;
  --list-border-color: #4d4e57;
  --list-border-color1: #323644;
  /*------------------------------------- 中间状态 ---------------------------------------*/
  --blue: #10aeff;
  --blue-level1: #314196;
  --blue-level2: #484c6c;
  --blue-level3: #0060f1;
  --purple: #74219b;
  /*----------------------------------------- alert -------------------------------------*/
  --alert-blue-border: rgba(0, 142, 255, 0.3);
  --alert-blue-bg: rgba(0, 142, 255, 0.2);
  --alert-red-border: rgba(250, 81, 81, 0.3);
  --alert-red-bg: rgba(250, 81, 81, 0.2);
  --alert-green-border: rgba(33, 191, 115, 0.3);
  --alert-green-bg: rgba(33, 191, 115, 0.2);
  /*--------------------------------------------- input -----------------------------------*/
  --input-bg: #353945;
  --modal-input-bg: --module-bg;
  --trade-input-bg: --main-bg-color;
  --input-error-border: --main-color;
  --input-white-bg: #f2f3f5;
  --input-white-disabled-bg: #ebc799;
  --input-red-bg: #a91b1b;
  --input-white-bg1: rgba(255, 255, 255, 0.04);
  /*-------------------------------- other color (1次) -------------------------------------*/
  --small-card-bg: #2c3040;
  --small-card-hover-bg: --blue-level2;
  --alicloud-grey-bg: #41444e;
  --alicloud-black-bg: #1b2131;
  --orderbook-red-bg: rgba(218, 72, 49, 0.05);
  --orderbook-green-bg: rgba(45, 170, 64, 0.05);
  --box-shadow: rgba(0, 0, 0, 0.5);
  --box-shadow-app: rgba(0, 0, 0, 0.03);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --input-box-shadow: rgba(23, 125, 220, 0.2);
  --scrollbar-thumb-color: rgba(144, 147, 153, 0.3);
  --loading-circle-color: rgba(241, 126, 0, 0.26);
  --loadMore-bg1-color: rgba(13, 16, 36, 0);
  --loadMore-bg2-color: rgba(13, 16, 36, 0.6);
  --grey-level1: #23262f;
  --grey-level2: #252734;
  --grey-level3: #2c2c2c;
  --mask-bg: rgba(47, 51, 66, 0.7);
  --divider-color: #323644;
  --vip-progress-bg: linear-gradient(270deg, #ffffff 12.02%, #a2bdcb 48.38%, #454e52 97.96%, #131515 131.33%);
  /*---------- intelligence ----------*/
  --avator-border: --remark-text-color;
  --expert-btn-view-bg: #4d506d;
  --expert-btn-view-bg-hover: --blue-level2;
  /*---------- accumulator timeline colors ----------*/
  --timeline-color1: #07c160;
  --timeline-color1-bg: rgba(7, 193, 96, 0.3);
  --timeline-color2: --btn-red-bg;
  --timeline-color2-bg: rgba(250, 81, 81, 0.3);
  --timeline-color3: #0060f1;
  --timeline-color3-bg: rgba(0, 96, 241, 0.3);
  --timeline-color4: --main-color;
  --timeline-color4-bg: rgba(241, 126, 0, 0.3);
  /*---------- cms colors ----------*/
  --cms-select-bg: #242634;
  --cms-select-border: #595A65;
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
  --max-width: 1200px;
}
[data-theme="saas-mode-dark"] {
  /*----------  白标 colors  ----------*/
  --main-bg-color: #000;
  --main-color: #7A52F4;
  --btn-disabled-text-color: #737580;
  --saas-border-color: rgba(207, 219, 213, 0.15);
  --saas-border-color1: rgba(207, 219, 213, 0.6);
  --saas-card-bg: rgba(235, 240, 240, 0.08);
  --saas-value-color: #F8FAFC;
  --saas-shadow: rgba(0, 0, 0, 0.18);
  --saas-modal-bg1: #06030E;
  --saas-modal-bg2: rgba(9, 4, 24, 0.5);
  --saas-disable-btn-bg: #4A4A4A;
  --saas-table-btn-border1: #BC20FE;
  --saas-table-btn-bg1: rgba(222, 35, 255, 0.2);
  --saas-table-btn-border2: #4ED3EE;
  --saas-table-btn-bg2: rgba(78, 211, 238, 0.2);
  --saas-product-label-bg1: #7a52f4;
  --saas-product-label-bg2: #37e9d1;
  --saas-investor-label-bg: rgba(122, 82, 244, 0.3);
  --saas-favorite-bg2: #4BBEDC;
  --saas-download-color: #A833CC;
  --saas-interest-btn-bg: rgba(78, 211, 238, 0.2);
  --saas-interest-btn-bg-active: #31B6D1;
  --saas-investor-not-read-color: #CFBFFF;
  --saas-investor-summary-bg: rgba(255, 255, 255, 0.1);
  --saas-investor-divider-color: #AAA8AA;
  /*--------------------------------- saas引用web项目的颜色，需要定义 -------------------------------------*/
  --black: #000;
  --module-bg: #1f212f;
  --white: #fff;
  --white-op1: rgba(255, 255, 255, 0.6);
  --white-op2: rgba(255, 255, 255, 0.7);
  --white-op3: rgba(255, 255, 255, 0.8);
  --white-op4: rgba(255, 255, 255, 0.5);
  --white-op5: rgba(255, 255, 255, 0.4);
  --text-color: #c7c7c7;
  --box-shadow: rgba(0, 0, 0, 0.5);
  --closeTag-box-shadow: rgba(40, 47, 73, 0.5);
  --red-text-color: #da4931;
  --green-text-color: #09d687;
  --message-success-bg: #042716;
  --message-success-border: #0c844a;
  --message-error-bg: #2f0404;
  --message-error-border: #a00e0e;
  --message-info-bg: #091733;
  --message-info-border: #1e4dad;
  --border-color3: rgba(255, 255, 255, 0.2);
  --CommonRegular: Common-Regular;
  --CommonMedium: Common-Medium;
  --CommonLight: Common-light;
  --CommonBold: Common-Bold;
}
.jDhYso09 {
  word-wrap: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.wL1ZLoP1 {
  background: var(--module-bg);
}
.wL1ZLoP1 .I5gUbpxE {
  display: flex;
  justify-content: space-between;
  height: 56px;
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
}
.wL1ZLoP1 .I5gUbpxE .VSWs_IOz {
  font-size: 22px;
  font-weight: 700;
}
.wL1ZLoP1 .xTUDjPxT {
  padding: 15px;
}
.wL1ZLoP1 .xTUDjPxT .RIRsN8nI:last-of-type .__4M91D0Ph,
.wL1ZLoP1 .xTUDjPxT ._gVlx_HK:last-of-type .__4M91D0Ph {
  display: none;
}
.wL1ZLoP1 .xTUDjPxT .pweRQnUS:last-of-type .C7mLmc4o {
  margin: 0;
}
.U8fb8jza,
.MTL8_yJw {
  display: flex;
}
.U8fb8jza .__3wR0j2fU,
.MTL8_yJw .__3wR0j2fU {
  max-width: 110px;
  height: 18px;
  line-height: 15px;
  background: var(--btn-type-bg);
  color: var(--main-color);
  border-radius: 2px;
  padding: 2px 8px;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  margin-right: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.MTL8_yJw .__3wR0j2fU {
  padding: 2px 5px;
  background: var(--btn-grey-bg) !important;
  color: var(--white);
}
.MTL8_yJw .__3wR0j2fU:last-child {
  margin-right: 0;
}
.__4M91D0Ph {
  border-bottom: 1px dashed var(--border-color);
  margin: 24px 0;
}
.__2B9Hs4eE {
  min-height: 760px;
}
.__2B9Hs4eE ._FtTUDm6 {
  flex-flow: row nowrap ;
}
.__2B9Hs4eE .LiUMsdBz {
  width: 200px;
  height: 100%;
  background: var(--module-bg);
  margin-right: 16px;
}
.__2B9Hs4eE .LiUMsdBz .ant-menu {
  background: transparent;
}
.__2B9Hs4eE .LiUMsdBz .ant-menu .ant-menu-item {
  padding-left: 32px;
  height: 90px;
  line-height: 90px;
  margin: 0;
  border-bottom: 1px solid var(--border-color);
  border-left: 2px solid transparent;
  color: var(--remark-text-color);
  font-size: 18px;
  font-weight: 500;
  font-family: "iconfont" !important;
}
.__2B9Hs4eE .LiUMsdBz .ant-menu .ant-menu-item:active,
.__2B9Hs4eE .LiUMsdBz .ant-menu .ant-menu-item:hover {
  color: var(--main-color);
  background-color: transparent !important;
}
.__2B9Hs4eE .LiUMsdBz .ant-menu .ant-menu-item.ant-menu-item-selected {
  background-color: transparent !important;
  border-left: 2px solid var(--main-color) !important;
  color: var(--main-color);
}
.__2B9Hs4eE .LiUMsdBz .EFOs3xRP {
  display: flex;
  align-items: center;
}
.__2B9Hs4eE.g062QM4f {
  overflow: hidden;
}
.__2B9Hs4eE.g062QM4f .cWw67_in {
  max-height: 940px;
  height: -moz-fit-content;
  height: fit-content;
  overflow-y: scroll;
  margin-bottom: -230px;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .xTUDjPxT {
  height: 142px;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .xTUDjPxT .__1bHu_Eka {
  margin-top: 20px;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 {
  min-height: 100px;
  margin-bottom: 24px;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 .wL1ZLoP1.sWkAXyRW .xTUDjPxT {
  padding: 0;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 .tWN369Ya {
  padding: 24px;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 .tWN369Ya .c16xv7IQ {
  display: flex;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 .tWN369Ya .c16xv7IQ .ToveD5id {
  width: 153px;
  height: 96px;
  position: relative;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 .tWN369Ya .c16xv7IQ .ToveD5id .BNtUScQ1 {
  width: 71%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
  font-family: var(--CommonBold);
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 .tWN369Ya .c16xv7IQ .WBNZW7IH {
  border-radius: 2px;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 .tWN369Ya .c16xv7IQ .pf8_Deh9 {
  margin-left: 15px;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .K3WIw8m6 .tWN369Ya .c16xv7IQ .__4M91D0Ph {
  border-left: 1px solid var(--border-color2);
}
.__2B9Hs4eE.g062QM4f .cWw67_in .ZSKO2bSj {
  min-height: 826px;
  background: var(--module-bg);
}
.__2B9Hs4eE.g062QM4f .cWw67_in .ZSKO2bSj .__0JyePpSh {
  height: 56px;
  padding: 16px 32px;
  border-bottom: 1px solid var(--border-color);
  font-size: 24px;
  font-weight: 700;
  background: var(--module-bg);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}
.__2B9Hs4eE.g062QM4f .cWw67_in .ZSKO2bSj .pf8_Deh9 {
  min-height: 150px;
}
.__2B9Hs4eE .GNFvTvrz {
  border-bottom: 1px solid var(--border-color);
}
.__2B9Hs4eE .GNFvTvrz .ant-tabs-nav {
  height: 58px;
  margin: 0;
  padding: 0 32px;
  font-weight: 700;
}
.__2B9Hs4eE .GNFvTvrz .ant-tabs-nav .ant-tabs-tab {
  font-size: 22px;
  margin-right: 30px;
  border-bottom: 3px solid transparent;
}
.__2B9Hs4eE .GNFvTvrz .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: 3px solid var(--main-color);
}
.__2B9Hs4eE.__1tdHNHfa .VEMcP48L {
  overflow: hidden;
  min-height: 826px;
  background: var(--module-bg);
}
.__2B9Hs4eE.__1tdHNHfa .VEMcP48L .__6TaoxYWZ {
  min-height: 826px;
  max-height: 1000px;
  background: var(--module-bg);
  overflow-y: scroll;
  margin-bottom: -230px;
}
.__2B9Hs4eE.DMPU_uJo .wNK2gADi {
  overflow: hidden;
  min-height: 826px;
  background: var(--module-bg);
}
.__2B9Hs4eE.DMPU_uJo .wNK2gADi .VPxHjsFa {
  height: 58px;
  padding: 16px 32px;
  border-bottom: 1px solid var(--border-color);
  font-size: 24px;
  font-weight: 700;
}
.__2B9Hs4eE.DMPU_uJo .wNK2gADi .COQAK9FQ {
  display: flex;
  flex-wrap: wrap;
  min-height: 826px;
  max-height: 2000px;
  padding: 24px 21px 0;
  overflow-y: scroll;
  margin-bottom: -430px;
}
.__2B9Hs4eE .tPVL6Wnv {
  width: 276px;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 16px;
  top: calc(100% - var(--aside-height) - 24px);
  position: -webkit-sticky;
  position: sticky;
}
.__2B9Hs4eE .freIvJzT {
  flex: 1 1;
  width: calc(100% - 276px - 200px - 32px);
  background: var(--module-bg);
}
.__2B9Hs4eE.HcVotRm_ .M_tC2yUh {
  width: 276px;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 16px;
  position: -webkit-sticky;
  position: sticky;
  top: calc(100% - var(--aside-height) - 24px);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm {
  flex: 1 1;
  width: calc(100% - 276px - 200px - 32px);
  background: var(--module-bg);
  padding-top: 24px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby {
  width: 689px;
  padding: 0 25px;
  margin-bottom: 40px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .ant-carousel .slick-dots.slick-dots-bottom {
  bottom: 15px;
  margin-left: 8%;
  justify-content: flex-start;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .ant-carousel .slick-dots.slick-dots-bottom > li {
  width: 13px;
  height: 15px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .ant-carousel .slick-dots.slick-dots-bottom > li > button {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--btn-grey1-bg);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .ant-carousel .slick-dots.slick-dots-bottom li.slick-active {
  width: 13px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .ant-carousel .slick-dots.slick-dots-bottom li.slick-active > button {
  background: var(--main-color);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .o5zO3iXI {
  position: relative;
  cursor: pointer;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .o5zO3iXI .aXzDBPg3 {
  width: 405px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 55px;
  z-index: 99;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .o5zO3iXI .aXzDBPg3 .yTrfp0xQ {
  line-height: 30px;
  margin-top: 13px;
  font-size: 20px;
  font-weight: 700;
  color: var(--white);
  font-family: var(--CommonBold);
  -webkit-line-clamp: 2;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .o5zO3iXI .aXzDBPg3 .__8yPr2S11 {
  display: flex;
  align-items: center;
  margin-top: 16px;
  float: right;
  font-weight: 700;
  color: var(--white);
  font-family: var(--CommonBold);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .ViukGcby .o5zO3iXI .aXzDBPg3 .__8yPr2S11 .zuWVB0HX {
  display: inline-block;
  width: 51px;
  margin-right: 10px;
  border-bottom: 1px solid var(--white);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev {
  margin-bottom: 32px;
  padding: 0 25px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-prev,
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-next,
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-prev:hover,
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-next:hover {
  width: 36px;
  height: 36px;
  font-size: inherit;
  color: currentColor;
  z-index: 99999;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-next {
  transform: rotate(180deg);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-prev {
  left: -13px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-next {
  right: -13px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-next::before,
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .ant-carousel .slick-prev::before {
  content: "";
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .I5gUbpxE {
  display: flex;
  justify-content: space-between;
  height: 56px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .I5gUbpxE .VSWs_IOz {
  font-size: 22px;
  font-weight: 700;
  font-family: var(--CommonBold);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl {
  width: 642px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI {
  width: 214px;
  height: 290px;
  padding-top: 23px;
  position: relative;
  cursor: pointer;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .PFLx9fzy {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 0;
  left: 88px;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .PFLx9fzy .WBNZW7IH {
  border: 1px solid var(--avator-border) !important;
  border-radius: 50%;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW {
  height: 266px;
  margin: 0 8px;
  padding: 16px;
  padding-top: 40px;
  background: var(--main-bg-color);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW:hover {
  background: var(--table-hover-bg);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW .__8yPr2S11 {
  color: var(--white);
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  font-family: var(--CommonMedium);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW .MTL8_yJw {
  justify-content: center;
  margin-top: 8px;
  overflow: hidden;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW .tc87JYPV {
  margin-top: 16px;
  font-size: 12px;
  color: var(--text-color);
  font-family: var(--CommonRegular);
  -webkit-line-clamp: 5;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW .ant-btn {
  width: 100px;
  height: 24px;
  line-height: 1;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW .lgIZlq2d {
  position: absolute;
  bottom: 16px;
  width: 171px;
  color: var(--main-color);
  border-color: var(--main-color);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW .lgIZlq2d:hover {
  background: var(--main-color);
  color: var(--white);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW .__9Zqo_E1M {
  position: absolute;
  bottom: 16px;
  width: 171px;
  background: var(--btn-disabled-bg);
  border-color: var(--btn-disabled-bg);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .wLuosNev .__2BZnQ_nl .o5zO3iXI .rmpHvrlW .ant-btn-primary:hover {
  background: var(--btn-disabled-bg) !important;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .NCsbjFDt {
  overflow: hidden;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .NCsbjFDt .VSWs_IOz {
  padding: 0 25px;
  height: 32px;
  font-size: 22px;
  font-weight: 700;
  font-family: var(--CommonBold);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .NCsbjFDt .__8L724Zv3 {
  padding: 24px 25px 0;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .NCsbjFDt .__8L724Zv3:hover {
  background: var(--table-hover-bg);
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .NCsbjFDt .__8L724Zv3 .yTrfp0xQ:hover {
  text-decoration: underline;
}
.__2B9Hs4eE.HcVotRm_ .sOkeNMpm .NCsbjFDt .__8L724Zv3 .__4M91D0Ph {
  margin-top: 24px;
  margin-bottom: 0;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW {
  width: calc(100% - 340px);
  background: var(--module-bg);
  overflow: hidden;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ {
  padding: 23px 24px 0;
  min-height: 826px;
  max-height: 2000px;
  overflow-y: scroll;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ ._gVlx_HK {
  padding: 24px 0;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ ._gVlx_HK .Xr6ISUGV {
  padding: 0 16px;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ ._gVlx_HK .tc87JYPV {
  padding: 0 16px;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ ._gVlx_HK .__8L724Zv3 {
  margin-bottom: 0;
  padding: 8px 16px;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ ._gVlx_HK .__8L724Zv3:hover {
  background: var(--table-hover-bg);
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ ._AO_7UOr {
  height: 220px;
  bottom: 100px;
  left: 20px;
  margin-left: -36px;
  margin-bottom: -100px;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ ._AO_7UOr > .CsPTRZa3 {
  margin-top: 130px;
}
.__2B9Hs4eE.bwcwIG1J .XBHqksiW .__6TaoxYWZ .__9AyLqG0O {
  margin-top: 0;
}
.__2B9Hs4eE.bwcwIG1J .UkhcEGjT {
  width: 340px;
  margin-left: 16px;
}
.__2B9Hs4eE.bwcwIG1J .UkhcEGjT .xTUDjPxT {
  padding-bottom: 0;
  min-height: 826px;
  max-height: 2000px;
  overflow-y: scroll;
}
.__2B9Hs4eE.bwcwIG1J .UkhcEGjT ._gVlx_HK {
  height: unset;
  background: transparent;
  padding: 0;
  margin: 0;
}
.__2B9Hs4eE.bwcwIG1J .UkhcEGjT ._gVlx_HK .Xr6ISUGV {
  margin-bottom: 24px;
}
.__2B9Hs4eE.bwcwIG1J .UkhcEGjT .CsPTRZa3 {
  display: flex;
  justify-content: center;
  color: var(--main-color);
  cursor: pointer;
}
.__2B9Hs4eE.bwcwIG1J .UkhcEGjT .CsPTRZa3:hover {
  color: var(--main-text-hover-color);
}
.__2B9Hs4eE.bwcwIG1J .UkhcEGjT .__9AyLqG0O {
  margin-top: 0;
}
.__2B9Hs4eE .__6TaoxYWZ {
  background: var(--module-bg);
}
.__2B9Hs4eE .__6TaoxYWZ ._AO_7UOr {
  height: 230px;
  bottom: 230px;
}
.__2B9Hs4eE .__6TaoxYWZ ._AO_7UOr > .CsPTRZa3 {
  margin-top: 150px;
}
.__2B9Hs4eE .__1DkRjV_r {
  width: 340px;
  background: var(--module-bg);
  min-height: 826px;
}
.__2B9Hs4eE .__1DkRjV_r .X2kOKGcM {
  margin: 0 0 20px;
  padding: 0 24px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color);
}
.__2B9Hs4eE .__1DkRjV_r .X2kOKGcM .VSWs_IOz {
  font-size: 20px;
  font-weight: 700;
}
.__2B9Hs4eE .__1DkRjV_r .X2kOKGcM .__2mu72Sbe {
  font-size: 20px;
  cursor: pointer;
}
.__2B9Hs4eE .__1DkRjV_r .X2kOKGcM .__2mu72Sbe :hover {
  color: var(--main-color);
}
.__2B9Hs4eE .__1DkRjV_r .mT0VVhYO {
  max-height: 750px;
  overflow: auto;
}
.__2B9Hs4eE .__1DkRjV_r .mT0VVhYO .XFlBSv_K {
  padding: 0 24px 16px;
}
.__2B9Hs4eE .__1DkRjV_r .mT0VVhYO .infinite-scroll-component {
  padding-top: 10px;
}
.__2B9Hs4eE .__1DkRjV_r .uaZlN7IZ {
  display: inline-block;
  padding: 1px 6px;
  margin-left: 0;
  background: var(--main-bg-color);
  border-radius: 2px;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  margin-bottom: 13px;
}
.__2B9Hs4eE .__1DkRjV_r .RJizd0Uf {
  font-size: 14px;
  font-family: var(--CommonMedium);
  font-weight: 500;
  color: var(--white);
  line-height: 22px;
  margin-bottom: 6px;
  max-width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.__2B9Hs4eE .__1DkRjV_r .ZkK8Xkvh {
  font-size: 13px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  -webkit-line-clamp: 2;
}
.__2B9Hs4eE .__1DkRjV_r .s0rJbJxY {
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--main-color);
  line-height: 22px;
}
.__2B9Hs4eE .__1DkRjV_r .shgGV08Q .ant-timeline-item-head {
  background-color: var(--btn-red-bg);
}
.__2B9Hs4eE .__1DkRjV_r .ant-timeline-item {
  cursor: pointer;
}
.__2B9Hs4eE .__1DkRjV_r .ant-timeline-item-head {
  width: 7px;
  height: 7px;
  border: none;
  background-color: var(--btn-grey1-bg);
}
.__2B9Hs4eE .__1DkRjV_r .ant-timeline-item-tail {
  border: 1px dashed var(--border-color);
  background: transparent;
  left: 3px;
}
.__2B9Hs4eE .__1DkRjV_r .ant-timeline-item-last > .ant-timeline-item-tail {
  display: block;
}
.__2B9Hs4eE .__1DkRjV_r .ant-timeline-item-last .ant-timeline-item-tail {
  height: 90px;
}
.__2B9Hs4eE .__1DkRjV_r .ant-skeleton .ant-skeleton-header {
  padding-left: 24px;
}
.__2B9Hs4eE .__1DkRjV_r .ant-skeleton .ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 10px;
  height: 10px;
  line-height: 10px;
}
.__2B9Hs4eE .__1DkRjV_r .ant-skeleton .ant-skeleton-content {
  padding-right: 24px;
}
.__2B9Hs4eE .__1DkRjV_r .ant-skeleton .ant-skeleton-content .ant-skeleton-title {
  margin: -2px;
}
.__2B9Hs4eE .__1DkRjV_r .ant-skeleton .ant-skeleton-content .ant-skeleton-paragraph {
  margin-top: 20px;
  margin-bottom: 10px;
}
.yTrfp0xQ,
.wkoEmufU,
.ZkK8Xkvh,
.FB19Yg9N,
.tc87JYPV {
  /* Compose 组合样式 */
}
._FtTUDm6 {
  flex-flow: row nowrap !important;
}
.__7mnpHnJj {
  padding: 24px 32px 0;
  cursor: pointer;
}
.__7mnpHnJj:hover {
  background: var(--table-hover-bg);
}
.__7mnpHnJj .L8UALdWL {
  margin-left: 24px;
}
.__7mnpHnJj .L8UALdWL span {
  width: 152px !important;
  height: 114px !important;
  margin: auto !important;
}
.__7mnpHnJj .L8UALdWL .WBNZW7IH {
  border-radius: 2px;
}
.__7mnpHnJj .pf8_Deh9 {
  flex: 1 1;
  overflow: hidden;
  max-height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.__7mnpHnJj .pf8_Deh9 .yTrfp0xQ {
  font-size: 16px;
  font-weight: 700;
  color: var(--white);
  font-family: var(--CommonRegular);
  -webkit-line-clamp: 1;
}
.__7mnpHnJj .pf8_Deh9 .wkoEmufU {
  font-family: var(--CommonMedium);
  font-weight: 400;
  color: var(--text-color) !important;
  -webkit-line-clamp: 2;
}
.__7mnpHnJj .pf8_Deh9 .wkoEmufU span {
  color: var(--text-color) !important;
  background-color: var(--module-bg) !important;
  font-size: 14px !important;
}
.__7mnpHnJj .pf8_Deh9 .wkoEmufU img {
  display: none !important;
}
.__7mnpHnJj .pf8_Deh9 .gOfyAIag {
  line-height: 24px;
  display: flex;
  align-items: center;
}
.__7mnpHnJj .pf8_Deh9 .gOfyAIag .__3wR0j2fU {
  max-width: 110px;
  height: 18px;
  background: var(--btn-type-bg);
  color: var(--main-color);
  border-radius: 2px;
  padding: 1px 8px;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  line-height: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.__7mnpHnJj .pf8_Deh9 .gOfyAIag .__4M91D0Ph {
  height: 12px;
  border-left: 1px solid var(--border-color2);
  margin: 0 16px;
}
.__7mnpHnJj .pf8_Deh9 .gOfyAIag .bHfUg5sl {
  color: var(--text-color);
  margin-right: 6px;
}
.__7mnpHnJj .pf8_Deh9 .gOfyAIag .__7noh7e74 {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.__7mnpHnJj .pf8_Deh9 .gOfyAIag .C7mLmc4o {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
}
.__7mnpHnJj .JwBZPoEB {
  width: 100%;
  margin-top: 24px;
  border-bottom: 1px dashed var(--border-color);
}
.__7EmYL6MW .gOfyAIag {
  margin-top: 16px;
}
.__7EmYL6MW .gOfyAIag .__3wR0j2fU {
  margin-right: 10px;
}
.__7EmYL6MW .gOfyAIag .__3wR0j2fU:last-of-type {
  margin-right: 0;
}
.__5wFMP9Ln {
  width: 100%;
  height: 380px;
  background: var(--main-bg-color);
  margin-bottom: 25px;
  position: relative;
  cursor: pointer;
  border-radius: 2px;
}
.__5wFMP9Ln:hover {
  background: var(--table-hover-bg);
}
.__5wFMP9Ln .WBNZW7IH {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.__5wFMP9Ln:nth-child(3n) {
  margin: 0;
}
.__5wFMP9Ln .pf8_Deh9 {
  height: 210px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.__5wFMP9Ln .pf8_Deh9 .yTrfp0xQ {
  height: 66px;
  vertical-align: middle;
  font-size: 18px;
  font-weight: 700;
  color: var(--white);
  font-family: var(--CommonRegular);
  -webkit-line-clamp: 3 !important;
}
.__5wFMP9Ln .pf8_Deh9 .FB19Yg9N {
  height: 80px;
  margin-top: 10px;
  font-size: 12px;
  line-height: 20px;
  font-family: var(--CommonMedium);
  color: var(--text-color) !important;
  -webkit-line-clamp: 4 !important;
}
.__5wFMP9Ln .pf8_Deh9 .t5hGvpRZ {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  margin-top: 13px;
}
.__5wFMP9Ln .U8fb8jza {
  position: absolute;
  top: 8px;
  left: 11px;
  background: var(--main-color);
  border: none;
  color: var(--white);
  padding: 4px 9px;
  height: 22px;
  font-size: 12px;
  line-height: 15px;
}
._gVlx_HK {
  position: relative;
  height: 540px;
  background: var(--main-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 16px;
  margin-bottom: 16px;
}
._gVlx_HK .Xr6ISUGV {
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
}
._gVlx_HK .Xr6ISUGV .WBNZW7IH {
  border: 1px solid var(--avator-border) !important;
  border-radius: 50%;
}
._gVlx_HK .Xr6ISUGV .__8oY6qpnJ {
  width: calc(100% - 48px - 16px);
  margin-left: 16px;
  overflow: hidden;
}
._gVlx_HK .Xr6ISUGV .__8oY6qpnJ .__8yPr2S11 {
  font-size: 14px;
  font-weight: 700;
  font-family: var(--CommonMedium);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
._gVlx_HK .Xr6ISUGV .__8oY6qpnJ .MTL8_yJw {
  margin-top: 10px;
}
._gVlx_HK .tc87JYPV {
  height: 88px;
  margin-top: 16px;
  font-size: 12px;
  color: var(--text-color);
  -webkit-line-clamp: 4 !important;
}
._gVlx_HK .__4M91D0Ph {
  margin: 16px 0;
  border-bottom: 1px solid var(--border-color1);
}
._gVlx_HK .__8L724Zv3 {
  margin-bottom: 16px;
  cursor: pointer;
}
._gVlx_HK .__8L724Zv3 .C7mLmc4o {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
}
._gVlx_HK .__8L724Zv3 .yTrfp0xQ {
  margin-top: 8px;
  font-size: 13px;
  font-weight: 500;
  color: var(--white);
  font-family: var(--CommonMedium);
  -webkit-line-clamp: 2;
}
._gVlx_HK .__8L724Zv3 .yTrfp0xQ:hover {
  text-decoration: underline;
}
._gVlx_HK .UEtYhhIU {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  padding: 0 16px;
}
._gVlx_HK .UEtYhhIU .ant-btn-primary {
  width: 100px;
  height: 24px;
  line-height: 1;
}
._gVlx_HK .UEtYhhIU .__9Zqo_E1M {
  background: var(--btn-grey-bg);
  border-color: var(--btn-grey-bg);
}
._gVlx_HK .UEtYhhIU .__9Zqo_E1M:hover {
  background: var(--btn-disabled-bg) !important;
}
._gVlx_HK .UEtYhhIU .yKYyyep2 {
  background: var(--expert-btn-view-bg);
  border-color: var(--expert-btn-view-bg);
}
._gVlx_HK .UEtYhhIU .yKYyyep2:hover {
  background: var(--expert-btn-view-bg-hover) !important;
}
.__6yhojelr {
  min-height: 826px;
  max-height: 1500px;
  overflow-y: scroll;
  margin-bottom: -200px;
}
.__6yhojelr .__8L724Zv3 .Obe0pLXe {
  display: flex;
  cursor: pointer;
}
.__6yhojelr .__8L724Zv3 .Obe0pLXe .WBNZW7IH {
  border: 1px solid var(--avator-border) !important;
  border-radius: 50%;
}
.__6yhojelr .__8L724Zv3 .Obe0pLXe .__8oY6qpnJ {
  width: calc(100% - 48px - 16px);
  margin-left: 16px;
  overflow: hidden;
}
.__6yhojelr .__8L724Zv3 .Obe0pLXe .__8oY6qpnJ .__8yPr2S11 {
  font-weight: 700;
  font-family: var(--CommonBold);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.__6yhojelr .__8L724Zv3 .Obe0pLXe .__8oY6qpnJ .C7mLmc4o {
  margin-top: 8px;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
}
.__6yhojelr .__8L724Zv3 .U8fb8jza {
  margin-top: 16px;
}
.__6yhojelr .__8L724Zv3 .yTrfp0xQ {
  line-height: 26px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
  font-family: var(--CommonMedium);
  -webkit-line-clamp: 1 !important;
}
.__6yhojelr .__8L724Zv3 .FB19Yg9N {
  margin-top: 16px;
  font-size: 13px;
  color: var(--text-color);
  -webkit-line-clamp: 2 !important;
}
.__6yhojelr ._AO_7UOr {
  height: 230px;
  bottom: 230px;
}
.__6yhojelr ._AO_7UOr > .CsPTRZa3 {
  margin-top: 180px;
}
.LgWdJOMO .wL1ZLoP1 {
  margin-top: 16px;
}
.LgWdJOMO .wL1ZLoP1 .xTUDjPxT {
  padding: 0;
}
.LgWdJOMO .__7mnpHnJj .pf8_Deh9 {
  min-height: 150px;
}
._mSWeuNJ .wL1ZLoP1,
.yo3iS3fS .wL1ZLoP1 {
  margin-top: 16px;
}
._mSWeuNJ .wL1ZLoP1 .I5gUbpxE,
.yo3iS3fS .wL1ZLoP1 .I5gUbpxE {
  padding: 16px 40px;
}
._mSWeuNJ .wL1ZLoP1 .xTUDjPxT,
.yo3iS3fS .wL1ZLoP1 .xTUDjPxT {
  padding: 0;
}
._mSWeuNJ .wL1ZLoP1 .xTUDjPxT .GGmNZ23a,
.yo3iS3fS .wL1ZLoP1 .xTUDjPxT .GGmNZ23a {
  display: flex;
  flex-wrap: wrap;
  min-height: 558px;
  padding: 18px 40px 0;
}
.I9DePsyy .idsHz4Kn,
.LgWdJOMO .idsHz4Kn,
._mSWeuNJ .idsHz4Kn,
.yo3iS3fS .idsHz4Kn {
  width: 136px;
  height: 200px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.I9DePsyy .idsHz4Kn .gs8DD1s5,
.LgWdJOMO .idsHz4Kn .gs8DD1s5,
._mSWeuNJ .idsHz4Kn .gs8DD1s5,
.yo3iS3fS .idsHz4Kn .gs8DD1s5 {
  font-size: 14px;
  margin-top: 16px;
  color: var(--text-color);
}
.__1bHu_Eka {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  color: var(--remark-text-color);
}
.OCbS3MgV {
  background: var(--module-bg);
  padding: 16px 32px 40px;
}
.OCbS3MgV h1,
.OCbS3MgV h2,
.OCbS3MgV h3,
.OCbS3MgV h4 {
  line-height: 40px !important;
}
.OCbS3MgV ul,
.OCbS3MgV ol {
  list-style: disc inside;
  text-indent: 2em;
}
.OCbS3MgV .ZEhNDfpR {
  margin-bottom: 30px;
}
.OCbS3MgV img {
  height: unset !important;
  border-radius: 2px;
}
.OCbS3MgV .VSWs_IOz {
  font-size: 36px;
  font-weight: 700;
  font-family: var(--CommonMedium);
  margin-bottom: 20px;
  line-height: normal;
}
.OCbS3MgV .faYarH0C {
  background: var(--main-bg-color);
  padding: 16px 24px;
  margin: 30px 0;
}
.OCbS3MgV .faYarH0C .__5HB3qqQT {
  margin-bottom: 8px;
  font-size: 16px;
  color: var(--main-color);
  font-family: var(--CommonBold);
}
.OCbS3MgV .faYarH0C ._k_CvG34 {
  font-size: 16px;
  font-family: var(--CommonRegular);
  line-height: normal;
  color: var(--text-color);
}
.OCbS3MgV p,
.OCbS3MgV div {
  word-wrap: break-word;
}
.OCbS3MgV .gOfyAIag {
  margin-bottom: 30px;
  line-height: 24px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.OCbS3MgV .gOfyAIag .ontNQkzj {
  display: flex;
  align-items: center;
}
.OCbS3MgV .gOfyAIag .ontNQkzj .__3wR0j2fU:last-of-type {
  margin-right: 0;
}
.OCbS3MgV .gOfyAIag .ontNQkzj .__4M91D0Ph {
  height: 12px;
  border-left: 1px solid var(--border-color2);
  margin: 0 16px;
}
.OCbS3MgV .gOfyAIag .ontNQkzj .bHfUg5sl {
  color: var(--text-color);
}
.OCbS3MgV .gOfyAIag .ontNQkzj .__7noh7e74 {
  margin-left: 6px;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.OCbS3MgV .gOfyAIag .ontNQkzj .C7mLmc4o {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
}
.OCbS3MgV .gOfyAIag .dpNMXs5V {
  display: flex;
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .bJ9lB1NF {
  color: var(--text-color);
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .OZbiuCZ6 {
  max-width: 300px;
  padding: 3px 12px;
  color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .OZbiuCZ6:hover {
  background: var(--main-color);
  color: var(--white);
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .sEXxpj__ {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 16px;
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .sEXxpj__ .WBNZW7IH {
  border: 1px solid var(--avator-border) !important;
  border-radius: 50%;
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .sEXxpj__ .p2XjUDjG {
  margin-left: 9px;
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .UEtYhhIU .ant-btn-primary {
  padding: 4px 27px;
  height: 24px;
  line-height: 1;
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .UEtYhhIU .__9Zqo_E1M {
  background: var(--btn-grey-bg);
  border-color: var(--btn-grey-bg);
}
.OCbS3MgV .gOfyAIag .dpNMXs5V .UEtYhhIU .__9Zqo_E1M:hover {
  background: var(--btn-disabled-bg) !important;
}
.OCbS3MgV .JwBZPoEB {
  width: 100%;
  margin: 24px 0;
  border-bottom: 1px solid var(--border-color);
}
.OCbS3MgV .__7soLLh6M {
  color: var(--text-color-third);
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 30px;
}
.OCbS3MgV .__7soLLh6M p {
  text-indent: 0;
}
.OCbS3MgV .rDiWXRQg {
  display: flex;
  align-items: center;
  color: var(--white);
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.OCbS3MgV .rDiWXRQg:hover {
  color: var(--main-color);
}
.OCbS3MgV .rDiWXRQg .__0izole0m {
  width: 100%;
  margin-left: 9px;
  white-space: nowrap;
  overflow: hidden;
}
.OCbS3MgV .Nqsk4DeY {
  font-size: 12px;
  color: var(--remark-text-color);
}
.OCbS3MgV .EAwpUvjr {
  width: 100%;
  text-align: center;
}
.OCbS3MgV .EAwpUvjr .BnUFfyTh {
  font-size: 18px;
  color: var(--main-color);
}
.OCbS3MgV .EAwpUvjr .BnUFfyTh .QoZZs6sv {
  text-decoration: underline;
}
.pweRQnUS {
  margin-bottom: 24px;
  border-bottom: 1px dashed var(--border-color);
}
.pweRQnUS:last-of-type {
  border: none;
  margin-bottom: 0;
}
.pweRQnUS .Xr6ISUGV {
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  cursor: pointer;
}
.pweRQnUS .Xr6ISUGV .WBNZW7IH {
  border: 1px solid var(--avator-border) !important;
  border-radius: 50%;
}
.pweRQnUS .Xr6ISUGV .__8oY6qpnJ {
  width: calc(100% - 48px - 21px);
  margin-left: 21px;
  overflow: hidden;
}
.pweRQnUS .Xr6ISUGV .__8oY6qpnJ .__8yPr2S11 {
  font-size: 14px;
  font-weight: 700;
  font-family: var(--CommonMedium);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.pweRQnUS .Xr6ISUGV .__8oY6qpnJ .MTL8_yJw {
  margin-top: 12px;
}
.pweRQnUS .yTrfp0xQ {
  cursor: pointer;
  margin: 16px 0;
  color: var(--white);
  font-size: 14px;
  font-family: var(--CommonRegular);
  font-weight: 500;
  -webkit-line-clamp: 2;
}
.pweRQnUS .yTrfp0xQ:hover {
  text-decoration: underline;
}
.pweRQnUS .C7mLmc4o {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
  margin-bottom: 24px;
}
.Y1tIxDgV .__8yPr2S11 {
  margin-left: 21px;
  font-size: 16px;
  font-weight: 700;
  width: calc(100% - 48px - 21px);
  font-family: var(--CommonMedium);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Y1tIxDgV .yTrfp0xQ:hover {
  text-decoration: underline;
}
.HxN_0FRR {
  display: flex;
  margin-bottom: 32px;
  cursor: pointer;
}
.HxN_0FRR:last-of-type {
  margin-bottom: 0;
}
.HxN_0FRR .tHthWjSi {
  color: var(--main-color);
  font-size: 20px;
  font-weight: 700;
  font-family: var(--CommonBold);
  font-style: italic;
}
.HxN_0FRR .yTrfp0xQ {
  font-weight: 500;
  font-size: 14px;
  font-family: var(--CommonRegular);
  color: var(--white);
  margin-left: 17px;
  -webkit-line-clamp: 2;
}
.HxN_0FRR .yTrfp0xQ:hover {
  text-decoration: underline;
}
.RIRsN8nI {
  cursor: pointer;
}
.RIRsN8nI .rmpHvrlW {
  display: flex;
  flex-shrink: 0;
}
.RIRsN8nI .rmpHvrlW .wJyQONGn {
  flex: 1 1;
}
.RIRsN8nI .rmpHvrlW .wJyQONGn .yTrfp0xQ {
  font-size: 14px;
  font-weight: 700;
  color: var(--white);
  font-family: var(--CommonRegular);
  -webkit-line-clamp: 2;
}
.RIRsN8nI .rmpHvrlW .wJyQONGn .yTrfp0xQ:hover {
  text-decoration: underline;
}
.RIRsN8nI .rmpHvrlW .wJyQONGn .t5hGvpRZ {
  margin-top: 8px;
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
}
.RIRsN8nI .rmpHvrlW .WBNZW7IH {
  border-radius: 4px;
}
.RIRsN8nI .__4M91D0Ph {
  margin: 16px 0;
  border-bottom: 1px dashed var(--border-color);
}
.EkymRSSd {
  padding: 24px 20px 27px;
  width: 340px;
  height: 210px;
  background: var(--module-bg);
  color: var(--white);
}
.EkymRSSd .z_eFSJSa {
  font-size: 22px;
  font-weight: 700;
  font-family: var(--CommonMedium);
}
.EkymRSSd .__1FSx5zqP {
  display: flex;
  margin-top: 31px;
}
.EkymRSSd .__1FSx5zqP .FfaRKtJi {
  font-size: 12px;
}
.EkymRSSd .__1FSx5zqP .__9PunJNYl {
  position: relative;
  margin-left: 24px;
}
.EkymRSSd .__1FSx5zqP .__9PunJNYl .zffvMtwI {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 40px;
  left: 40px;
}
.ojZoDH_Z {
  width: 340px;
  height: -moz-fit-content;
  height: fit-content;
  position: -webkit-sticky;
  position: sticky;
  top: calc(100% - var(--aside-height) - 24px);
}
.ojZoDH_Z ._G4hqGAG ._gVlx_HK {
  padding: 24px 24px 10px;
}
.ojZoDH_Z ._gVlx_HK {
  height: auto;
  background: var(--module-bg);
  padding: 0;
  margin: 0;
}
.ojZoDH_Z ._gVlx_HK .Xr6ISUGV {
  margin-bottom: 24px;
}
.ojZoDH_Z ._gVlx_HK .tc87JYPV {
  height: auto;
}
.ojZoDH_Z ._gVlx_HK .__4M91D0Ph {
  border-bottom: 1px dashed var(--border-color);
}
.pK14a3b8 {
  max-width: 1200px;
  margin: auto;
}
.pK14a3b8 .ADoVvyle {
  width: 100%;
  height: 515px;
  padding-top: 55px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.pK14a3b8 .__2_3L_cTo {
  margin-top: 55px;
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 {
  text-align: center;
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .PFLx9fzy {
  border-radius: 50%;
  border: 3px solid var(--white) !important;
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .__8yPr2S11 {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  font-family: var(--CommonBold);
  margin: 17px 0;
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .MTL8_yJw {
  justify-content: center;
  margin-bottom: 26px;
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .MTL8_yJw .__3wR0j2fU {
  height: 28px;
  padding: 5px 12px;
  line-height: normal;
  background: var(--btn-type1-bg) !important;
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .ant-btn-primary {
  height: 45px;
  padding: 9px 40px;
  font-size: 24px;
  font-family: var(--CommonMedium);
  line-height: 20px;
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .__9Zqo_E1M {
  background: var(--btn-grey-bg);
  border-color: var(--btn-grey-bg);
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .__9Zqo_E1M:hover {
  background: var(--btn-disabled-bg) !important;
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .yKYyyep2 {
  background: var(--expert-btn-view-bg);
  border-color: var(--expert-btn-view-bg);
}
.pK14a3b8 .__2_3L_cTo .zoMHIuA5 .yKYyyep2:hover {
  background: var(--expert-btn-view-bg-hover) !important;
}
.pK14a3b8 .aa1p9ryV {
  height: 100%;
  display: flex;
  margin-top: 40px;
  position: relative;
}
.pK14a3b8 .aa1p9ryV .h_KjxH1z {
  margin-bottom: 16px;
  width: 100%;
  padding: 40px;
  background: var(--module-bg) !important;
  z-index: 99999;
}
.pK14a3b8 .aa1p9ryV .h_KjxH1z .VSWs_IOz {
  font-size: 22px;
  font-weight: 700;
  font-family: var(--CommonBold);
}
.pK14a3b8 .aa1p9ryV .h_KjxH1z .IQYhi_Qi {
  display: flex;
}
.pK14a3b8 .aa1p9ryV .h_KjxH1z .IQYhi_Qi .tc87JYPV {
  padding-top: 28px;
  font-size: 14px;
  color: var(--text-color);
  text-align: justify;
  font-family: var(--CommonRegular);
  -webkit-line-clamp: 10;
  position: relative;
}
.pK14a3b8 .aa1p9ryV .h_KjxH1z .IQYhi_Qi .tc87JYPV.xxSo45dU {
  -webkit-line-clamp: 9999;
  max-height: none;
}
.pK14a3b8 .aa1p9ryV .h_KjxH1z .IQYhi_Qi .tc87JYPV::before {
  content: "";
  height: calc(100% - 22px);
  float: right;
}
.pK14a3b8 .aa1p9ryV .h_KjxH1z .IQYhi_Qi .tc87JYPV .nx6NM8aT {
  float: right;
  clear: both;
}
.pK14a3b8 .RnoGpdBL {
  min-height: var(--author-detail-aside-height);
}
.pK14a3b8 .RnoGpdBL .I5gUbpxE {
  padding-left: 40px;
}
.pK14a3b8 .RnoGpdBL .xTUDjPxT {
  padding: 24px 0;
  overflow: hidden;
}
.pK14a3b8 .RnoGpdBL .xTUDjPxT .__6yhojelr {
  padding: 0 24px 0 40px;
}
.pK14a3b8 .RnoGpdBL .xTUDjPxT .__6yhojelr .__8L724Zv3 .C41daqCC {
  display: flex;
  align-items: center;
}
.pK14a3b8 .RnoGpdBL .xTUDjPxT .__6yhojelr .__8L724Zv3 .C41daqCC .U8fb8jza {
  margin-top: 0;
}
.pK14a3b8 .RnoGpdBL .xTUDjPxT .__6yhojelr .__8L724Zv3 .C41daqCC .C7mLmc4o {
  font-size: 12px;
  font-family: var(--CommonRegular);
  font-weight: 400;
  color: var(--text-color);
  line-height: 20px;
}
.pK14a3b8 .b_e1QHNi {
  width: 340px;
  background: var(--module-bg);
  min-height: 826px;
  max-height: 1500px;
  margin-left: 16px;
}
.pK14a3b8 .b_e1QHNi .ThthsEmT ._G4hqGAG ._gVlx_HK {
  padding: 24px 24px 10px;
}
.pK14a3b8 .b_e1QHNi .ThthsEmT ._gVlx_HK {
  height: auto;
  background: var(--module-bg);
  padding: 0;
  margin: 0;
}
.pK14a3b8 .b_e1QHNi .ThthsEmT ._gVlx_HK .Xr6ISUGV {
  margin-bottom: 24px;
}
.pK14a3b8 .b_e1QHNi .ThthsEmT ._gVlx_HK .tc87JYPV {
  height: auto;
  -webkit-line-clamp: 6 !important;
}
.pK14a3b8 .b_e1QHNi .ThthsEmT ._gVlx_HK .__4M91D0Ph {
  border-bottom: 1px dashed var(--border-color);
}
._AO_7UOr {
  margin-left: -32px;
  width: calc(100% + 64px);
  height: 430px;
  text-align: center;
  position: relative;
  bottom: 430px;
  left: 0;
  overflow-y: scroll;
  background: -webkit-linear-gradient(var(--loadMore-bg1-color) 0%, var(--loadMore-bg2-color) 32.29%, var(--module-bg) 70%, var(--module-bg));
}
._AO_7UOr .CsPTRZa3 {
  margin-top: 330px;
  color: var(--main-color);
  border-color: var(--main-color);
}
._AO_7UOr .CsPTRZa3:hover {
  background: var(--main-color);
  color: var(--white);
}
.__9AyLqG0O {
  width: 100%;
  margin: 10px auto;
  font-size: 12px;
  text-align: center;
  color: var(--remark-text-color);
}
.__0cN_W_Lv {
  height: 251px;
}
.__0cN_W_Lv .Md0KFpmG {
  width: 100%;
  height: 251px;
}
.__0cN_W_Lv .Md0KFpmG .cBNS3YGv {
  height: 84px;
  bottom: 0;
  background: linear-gradient(270deg, rgba(31, 33, 47, 0.9) 46.94%, rgba(31, 33, 47, 0.3) 100%);
}
@media only screen and (max-width: 600px) {
  .LgWdJOMO.__6bOtwHIt,
  .yo3iS3fS.__6bOtwHIt,
  ._mSWeuNJ.__6bOtwHIt,
  .I9DePsyy.__6bOtwHIt {
    width: 100% !important;
    min-height: 100vh !important;
    background: var(--black);
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV {
    background: var(--black);
    width: 100% !important;
    margin: 0 !important;
    padding: 32px 24px 60px;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .VSWs_IOz,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .VSWs_IOz,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .VSWs_IOz,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .VSWs_IOz {
    font-size: 18px;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .C41daqCC,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .C41daqCC,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .C41daqCC,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .C41daqCC {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
    font-size: 10px;
    color: var(--text-color);
    margin-bottom: 16px;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .C41daqCC .U8fb8jza,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .C41daqCC .U8fb8jza,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .C41daqCC .U8fb8jza,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .C41daqCC .U8fb8jza {
    display: flex;
    flex-wrap: wrap;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .C41daqCC .U8fb8jza .__3wR0j2fU,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .C41daqCC .U8fb8jza .__3wR0j2fU,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .C41daqCC .U8fb8jza .__3wR0j2fU,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .C41daqCC .U8fb8jza .__3wR0j2fU {
    max-width: 80px;
    background: var(--btn-type-bg);
    padding: 1px 4px;
    color: var(--main-color);
    border-radius: 2px;
    margin-right: 4px;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 3px;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .C41daqCC .__4M91D0Ph,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .C41daqCC .__4M91D0Ph,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .C41daqCC .__4M91D0Ph,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .C41daqCC .__4M91D0Ph {
    margin: 0 5px;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .KFULlSq7,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .KFULlSq7,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .KFULlSq7,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .KFULlSq7 {
    min-width: 110px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .__7soLLh6M p,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .__7soLLh6M p,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .__7soLLh6M p,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .__7soLLh6M p {
    font-size: 14px;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .Xr6ISUGV,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .Xr6ISUGV,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .Xr6ISUGV,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .Xr6ISUGV {
    width: 100%;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin: 30px 0;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .WBNZW7IH,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .WBNZW7IH,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .WBNZW7IH,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .WBNZW7IH {
    border: 1px solid var(--avator-border) !important;
    border-radius: 50%;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ {
    width: calc(100% - 48px - 12px);
    margin-left: 12px;
    overflow: hidden;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ .__8yPr2S11,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ .__8yPr2S11,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ .__8yPr2S11,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ .__8yPr2S11 {
    font-size: 16px;
    font-weight: 700;
    font-family: var(--CommonMedium);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ .MTL8_yJw,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ .MTL8_yJw,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ .MTL8_yJw,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .Xr6ISUGV .__8oY6qpnJ .MTL8_yJw {
    margin-top: 10px;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .faYarH0C,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .faYarH0C,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .faYarH0C,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .faYarH0C {
    background: var(--grey-level3);
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .faYarH0C .__5HB3qqQT,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .faYarH0C .__5HB3qqQT,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .faYarH0C .__5HB3qqQT,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .faYarH0C .__5HB3qqQT {
    font-size: 14px;
  }
  .LgWdJOMO.__6bOtwHIt .OCbS3MgV .faYarH0C ._k_CvG34,
  .yo3iS3fS.__6bOtwHIt .OCbS3MgV .faYarH0C ._k_CvG34,
  ._mSWeuNJ.__6bOtwHIt .OCbS3MgV .faYarH0C ._k_CvG34,
  .I9DePsyy.__6bOtwHIt .OCbS3MgV .faYarH0C ._k_CvG34 {
    font-size: 14px;
  }
  .LgWdJOMO.__6bOtwHIt .iYPybYUK,
  .yo3iS3fS.__6bOtwHIt .iYPybYUK,
  ._mSWeuNJ.__6bOtwHIt .iYPybYUK,
  .I9DePsyy.__6bOtwHIt .iYPybYUK {
    width: 100%;
    height: 100px;
    cursor: pointer;
  }
  .LgWdJOMO.__6bOtwHIt .iYPybYUK .Tu6D2kfM,
  .yo3iS3fS.__6bOtwHIt .iYPybYUK .Tu6D2kfM,
  ._mSWeuNJ.__6bOtwHIt .iYPybYUK .Tu6D2kfM,
  .I9DePsyy.__6bOtwHIt .iYPybYUK .Tu6D2kfM {
    position: fixed;
    bottom: 28px;
    left: 24px;
    width: calc(100% - 58px);
    height: 85px;
    background: var(--grey-level3);
    border-radius: 6px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    font-size: 12px;
    color: var(--white);
  }
  .LgWdJOMO.__6bOtwHIt .iYPybYUK .Tu6D2kfM .gs8DD1s5,
  .yo3iS3fS.__6bOtwHIt .iYPybYUK .Tu6D2kfM .gs8DD1s5,
  ._mSWeuNJ.__6bOtwHIt .iYPybYUK .Tu6D2kfM .gs8DD1s5,
  .I9DePsyy.__6bOtwHIt .iYPybYUK .Tu6D2kfM .gs8DD1s5 {
    width: 170px;
    margin-left: 17px;
    margin-right: 25px;
  }
  .LgWdJOMO.__6bOtwHIt .iYPybYUK .Tu6D2kfM .L0js0ZDm,
  .yo3iS3fS.__6bOtwHIt .iYPybYUK .Tu6D2kfM .L0js0ZDm,
  ._mSWeuNJ.__6bOtwHIt .iYPybYUK .Tu6D2kfM .L0js0ZDm,
  .I9DePsyy.__6bOtwHIt .iYPybYUK .Tu6D2kfM .L0js0ZDm {
    width: 56px;
    height: 26px;
    background: var(--main-color);
    text-align: center;
    line-height: 24px;
    border-radius: 43px;
  }
  .LgWdJOMO.__6bOtwHIt .iYPybYUK .Tu6D2kfM .L0js0ZDm > a,
  .yo3iS3fS.__6bOtwHIt .iYPybYUK .Tu6D2kfM .L0js0ZDm > a,
  ._mSWeuNJ.__6bOtwHIt .iYPybYUK .Tu6D2kfM .L0js0ZDm > a,
  .I9DePsyy.__6bOtwHIt .iYPybYUK .Tu6D2kfM .L0js0ZDm > a {
    color: var(--white);
  }
}
/*竖屏 css*/
@media screen and (orientation: portrait) {
  .__2B9Hs4eE.HcVotRm_ .M_tC2yUh {
    position: static;
  }
  .tPVL6Wnv {
    position: static !important;
  }
  .ojZoDH_Z {
    position: static;
  }
}

